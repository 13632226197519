import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';
import {
  CardType,
  LinkedAccountType,
  SettlementType,
} from 'components/creditCards/components/CreateCardModal/constants';
import useFeatureToggle from 'hooks/useFeatureToggle';
import useIsContactPermission from 'hooks/useIsContactPermission';
import { mapLinkedAccountTypeOptions } from '../CardSetup.utils';
import { useGetWallets } from 'hooks';
import { Wallet } from 'types/wallet';

const useCardSetup = () => {
  const { onNextStep, currentStep, steps, createCardFormDataRef, isMember, hasNoCards, setIsPhysicalCardFlow } =
    useContext(CreateCardContext);

  const { createVirtualCard, createPhysicalCard, isOwner } = useIsContactPermission();

  const { wallets } = useGetWallets();

  const { isExperimentEnabled: isAccountLinkedEnabled, loading: isAccountLinkedEnabledLoading } = useFeatureToggle(
    false,
    'account_linked_cards'
  );

  const cardTypeValue = createCardFormDataRef.current?.virtual ? CardType.Virtual : CardType.Physical;

  const form = useForm({
    defaultValues: {
      cardType: createCardFormDataRef.current?.virtual != null && cardTypeValue,
      linkedAccount: createCardFormDataRef.current?.linkedAccount || null,
      settlementType: createCardFormDataRef.current?.settlementType || null,
      selectedWalletId: createCardFormDataRef.current?.selectedWalletId || null,
      atlasCard: createCardFormDataRef.current?.atlasCard || null,
    },
  });

  const { register, watch, handleSubmit, setValue } = form;

  const selectedLinkedAccount = watch('linkedAccount');
  const selectedCardType = watch('cardType');
  const selectedAtlasCard: boolean = watch('atlasCardCheck');
  const selectedSettlementType = watch('settlementType');
  const selectedWalletId = watch('selectedWalletId');

  const getLinkAccountOptions = () => {
    return mapLinkedAccountTypeOptions.filter(
      (option) => isAccountLinkedEnabled || option.value !== LinkedAccountType.LoopAccount
    );
  };

  const selectedCardImage = hasNoCards ? CardType.Virtual : selectedCardType;

  const getCardTypeOptions = () => {
    return Object.values(CardType)
      .map((val) => ({ label: val, value: val }))
      .filter((option) => {
        if (option.value === CardType.Virtual) {
          return createVirtualCard;
        }
        if (option.value === CardType.Physical) {
          return createPhysicalCard && !hasNoCards;
        }
      });
  };

  const shouldDisplayAtlasCard = isOwner && selectedCardType === CardType.Physical;

  const getSelectableAccountOptions = () => {
    return wallets.map((wallet: Wallet) => ({
      value: wallet.id,
      name: wallet.name,
    }));
  };

  const getSettlementOptions = () => {
    return Object.values(SettlementType).map((val) => ({ label: val, value: val }));
  };

  const shouldDisplaySettlementOptions = selectedLinkedAccount === LinkedAccountType.LoopAccount;

  const linkAccountOptions = useMemo(() => getLinkAccountOptions(), [isAccountLinkedEnabled]);
  const cardTypeOptions = useMemo(() => getCardTypeOptions(), [createVirtualCard, createPhysicalCard]);
  const settlementOptions = getSettlementOptions();
  const selectableAccountOptions = useMemo(() => getSelectableAccountOptions(), [selectedSettlementType]);

  const handleOnNextStep = (data: {
    cardType: string;
    linkedAccount: string;
    settlementType: SettlementType;
    selectedWalletId: string;
    atlasCard: boolean;
  }) => {
    createCardFormDataRef.current = {
      virtual: data.cardType === CardType.Virtual,
      linkedAccount: data.linkedAccount,
      settlementType: data.settlementType,
      selectedWalletId: data.selectedWalletId,
      atlasCard: data.atlasCard,
    };
    onNextStep();
  };

  const handleCardTypeChange = (value: CardType) => {
    setValue('cardType', value);
  };

  useEffect(() => {
    const isPhysical = selectedCardType === CardType.Physical;
    setIsPhysicalCardFlow(isPhysical);
  }, [selectedCardType, setIsPhysicalCardFlow]);

  return {
    form,
    register,
    wallets,
    linkAccountOptions,
    cardTypeOptions,
    selectedWalletId,
    selectableAccountOptions,
    settlementOptions,
    selectedLinkedAccount,
    shouldDisplaySettlementOptions,
    selectedAtlasCard,
    selectedSettlementType,
    selectedCardType,
    selectedCardImage,
    handleCardTypeChange,
    handleSubmit,
    handleOnNextStep,
    setValue,
    isAccountLinkedEnabledLoading,
    shouldDisplayAtlasCard,
    currentStep,
    steps,
    hasNoCards,
    isMember,
  };
};

export default useCardSetup;
