import React, { useState, useContext, useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { SUBMIT_SOLID_ACCOUNT } from 'graphql/wallets';
import { Progress } from 'components/UI';
import { Loaders } from 'components/cards/Loader';
import { AddUSBankAccountContext, AddUSBankAccountContextProvider } from './AddUSBankAccountContext';
import {
  USBankAccountVerificationContext,
  USBankAccountVerificationProvider,
} from 'components/Accounts/components/USBankAccountVerificationModal/contexts/USBankAccountVerificationContext';
import Details from './Details';
import Identification from './Identification';
import { IndustryCode } from 'components/Accounts/components/USBankAccountVerificationModal/components/Steps/components';
import TransactionsVolume from './TransactionsVolume';
import PatriotAct from './PatriotAct';
import Agreements from './Agreements';
import Complete from './Complete';
import { OwnershipDisclosure } from './components';

const STEPS = {
  Details: [0],
  Ownership: [1],
  Industry: [2],
  Volume: [3],
  Agreements: [4, 5],
  Confirmation: [6],
  Complete: [7],
};

const AddUSBankAccount = (props) => {
  const { loading, ...otherProps } = props;
  const [step, setStep] = useState(0);

  if (loading) return <Loading />;

  return (
    <AddUSBankAccountContextProvider>
      <USBankAccountVerificationProvider>
        <div>
          <Progress currentStepIndex={step} stepLabelsAndIndexes={STEPS} />
        </div>
        <Steps step={step} setStep={setStep} {...otherProps} />
      </USBankAccountVerificationProvider>
    </AddUSBankAccountContextProvider>
  );
};

const Loading = () => (
  <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
    <Loaders.Small />
  </div>
);

const Steps = (props) => {
  const { step, setStep, enableFullScreen, disableFullScreen, onFinish } = props;
  const { setWalletExternalAccount, bankAccountInfo: applicationDetails } = useContext(AddUSBankAccountContext);
  const { industryData } = useContext(USBankAccountVerificationContext);
  const [onSubmitSolidAccount, { loading: isSubmitting, error }] = useMutation(SUBMIT_SOLID_ACCOUNT);
  const [silentRequestError, setSilentRequestError] = useState();

  const onValidateAccount = async (bankAccountInfo) => {
    setSilentRequestError(undefined);

    try {
      const response = await onSubmitSolidAccount({ variables: { ...bankAccountInfo, validate: true } });

      if (response) {
        setStep(2);
      } else {
        setSilentRequestError({ type: 'manual', message: 'Failed to create account' });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmitAccount = async () => {
    setSilentRequestError(undefined);
    try {
      const response = await onSubmitSolidAccount({
        variables: { ...applicationDetails, naicsCode: industryData?.naicsCode || '', validate: false },
      });

      if (response && response.data.createSolidAccount) {
        setWalletExternalAccount(response.data.createSolidAccount);
        setStep(6);
      } else {
        setSilentRequestError({ type: 'manual', message: 'Failed to create account' });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onNextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const onPreviousStep = useCallback(() => {
    if (step === 0) return;

    setStep(step - 1);
  }, [step]);

  switch (step) {
    case 0:
      return <Details onNextStep={onNextStep} />;
    case 1:
      return (
        <Identification
          onPreviousStep={onPreviousStep}
          onNextStep={onValidateAccount}
          loading={isSubmitting}
          error={error || silentRequestError}
          {...props}
        />
      );
    case 2:
      return <IndustryCode onPrevStep={onPreviousStep} onNextStep={onNextStep} />;
    case 3:
      return <TransactionsVolume onPreviousStep={onPreviousStep} onNextStep={onNextStep} error={error} />;
    case 4:
      return <PatriotAct onPreviousStep={onPreviousStep} onNextStep={onNextStep} />;
    case 5:
      return (
        <Agreements
          onPreviousStep={onPreviousStep}
          onNextStep={onSubmitAccount}
          loading={isSubmitting}
          error={error || silentRequestError}
        />
      );
    case 6:
      return (
        <OwnershipDisclosure
          onPreviousStep={onFinish}
          onNextStep={onNextStep}
          enableFullScreen={enableFullScreen}
          disableFullScreen={disableFullScreen}
        />
      );
    case 7:
      return <Complete onFinish={onFinish} />;
    default:
      return null;
  }
};

export default AddUSBankAccount;
