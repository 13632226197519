import React, { useEffect, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { solidTransactionsVolumeOptions, solidTransactionsAmountOptions } from 'constants/index';
import { Select, SubmitButton } from 'components/FormFields/v2';
import { AddUSBankAccountContext } from './AddUSBankAccountContext';
import Button from 'components/Button';

type TransactionsVolumeProps = {
  onPreviousStep: () => void;
  onNextStep: () => void;
};

const TransactionsVolume = ({ onPreviousStep, onNextStep }: TransactionsVolumeProps) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const { bankAccountInfo, setBankAccountInfo } = useContext(AddUSBankAccountContext) as any;

  const form = useForm();
  const { handleSubmit, register, setValue } = form;

  useEffect(() => {
    const fillFormWithPreviousValues = () => {
      const { transactionsSentAmount, transactionsSentVolume, transactionsReceivedAmount, transactionsReceivedVolume } =
        bankAccountInfo;

      setValue('transactionsSentAmount', transactionsSentAmount);
      setValue('transactionsSentVolume', transactionsSentVolume);
      setValue('transactionsReceivedAmount', transactionsReceivedAmount);
      setValue('transactionsReceivedVolume', transactionsReceivedVolume);
    };

    if (bankAccountInfo) {
      fillFormWithPreviousValues();
    }
  }, [bankAccountInfo]);

  const onSubmit = (data: any) => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const { transactionsSentAmount, transactionsSentVolume, transactionsReceivedAmount, transactionsReceivedVolume } =
      data;

    setBankAccountInfo({
      ...bankAccountInfo,
      transactionsSentAmount: transactionsSentAmount,
      transactionsSentVolume: transactionsSentVolume,
      transactionsReceivedAmount: transactionsReceivedAmount,
      transactionsReceivedVolume: transactionsReceivedVolume,
    });
    onNextStep();
  };

  return (
    <div className="tw-mt-10">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-px-8 tw-mb-10">
            <div className="tw-flex tw-flex-col tw-gap-y-8">
              <div className="tw-text-sm">Please provide your average Annual Transaction volumes in USD.</div>
              <div>
                <Select
                  name="transactionsSentAmount"
                  label="Send Transaction Volume (ACH)"
                  placeholder="Select Option"
                  required
                  ref={register({ required: true })}
                  options={solidTransactionsAmountOptions}
                />
                <div className="tw-text-xs tw-mt-2 tw-text-neutral-grey-1">
                  Your average annual outgoing ACH transaction volume
                </div>
              </div>
              <div>
                <Select
                  name="transactionsSentVolume"
                  label="Send Transaction Frequency (ACH)"
                  placeholder="Select Option"
                  required
                  ref={register({ required: true })}
                  options={solidTransactionsVolumeOptions}
                />
                <div className="tw-text-xs tw-mt-2 tw-text-neutral-grey-1">
                  Your average annual number of outgoing ACH transactions
                </div>
              </div>
              <div>
                <Select
                  name="transactionsReceivedAmount"
                  label="Receive Transaction Volume (ACH)"
                  placeholder="Select Option"
                  required
                  ref={register({ required: true })}
                  options={solidTransactionsAmountOptions}
                />
                <div className="tw-text-xs tw-mt-2 tw-text-neutral-grey-1">
                  Your average annual incoming ACH transaction volume
                </div>
              </div>
              <div>
                <Select
                  name="transactionsReceivedVolume"
                  label="Receive Transaction Frequency (ACH)"
                  placeholder="Select Option"
                  required
                  ref={register({ required: true })}
                  options={solidTransactionsVolumeOptions}
                />
                <div className="tw-text-xs tw-mt-2 tw-text-neutral-grey-1">
                  Your average annual number of incoming ACH transactions
                </div>
              </div>
            </div>
          </div>
          <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between">
            <Button
              onClick={onPreviousStep}
              className="tw-bg-neutral-light tw-border tw-border-neutral-grey-3 tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-mb-4 lg:tw-mb-0 tw-w-full lg:tw-w-32"
            >
              Back
            </Button>
            <SubmitButton className="tw-w-full lg:tw-w-32">Next</SubmitButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default TransactionsVolume;
