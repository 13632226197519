import React, { useEffect } from 'react';
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import { captureMessage } from '@sentry/react';

const ErrorBoundary = (props) => {
  const onHandleError = (error, errorInfo) => {
    console.error('Error Boundary:', error, errorInfo);
    captureMessage(`Error Boundary: ${error} ${errorInfo}`, 'warning');
  };

  useEffect(() => {
    return () => {
      window.location.reload(true);
    };
  }, []);

  return (
    <_ErrorBoundary FallbackComponent={ErrorFallback} onError={onHandleError}>
      {props.children}
    </_ErrorBoundary>
  );
};

export default ErrorBoundary;
