const theme = {
  colors: {
    transparent: 'transparent',
    secondary: {
      'pastel-green': {
        100: 'var(--colors-secondary-pastel-green-100)',
        80: 'var(--colors-secondary-pastel-green-80)',
      },
      'pastel-yellow': {
        100: 'var(--colors-secondary-pastel-yellow-100)',
        50: 'var(--colors-secondary-pastel-yellow-50)',
        80: 'var(--colors-secondary-pastel-yellow-80)',
      },
      'pastel-red': {
        50: 'var(--colors-secondary-pastel-red-50)',
      },
      'light-blue': 'var(--colors-secondary-light-blue)',
      'light-yellow': 'var(--colors-secondary-light-yellow)',
      'light-lilac': 'var(--colors-secondary-light-lilac)',
      'light-green': 'var(--colors-secondary-light-green)',
      'bright-green': {
        50: 'var(--colors-secondary-bright-green-50)',
        100: 'var(--colors-secondary-bright-green-100)',
      },
    },
    primary: {
      'dark-green': 'var(--colors-primary-dark-green)',
      'light-green': 'var(--colors-primary-light-green)',
      'dark-yellow': 'var(--colors-primary-dark-yellow)',
      blue: 'var(--colors-primary-blue)',
      'light-blue': 'var(--colors-primary-light-blue)',
      yellow: 'var(--colors-primary-yellow)',
      lilac: 'var(--colors-primary-lilac)',
    },
    neutral: {
      black: 'var(--colors-natural-black)',
      dark: 'var(--colors-natural-dark)',
      light: 'var(--colors-natural-light)',
      grey: {
        1: 'var(--colors-natural-grey-1)',
        2: 'var(--colors-natural-grey-2)',
        3: 'var(--colors-natural-grey-3)',
        3.5: 'var(--colors-natural-grey-35)',
        4: 'var(--colors-natural-grey-4)',
        4.5: 'var(--colors-natural-grey-45)',
        5: 'var(--colors-natural-grey-5)',
      },
    },
    semantic: {
      error: 'var(--colors-semantic-error)',
      'error-background': 'var(--colors-semantic-error-background)',
      success: 'var(--colors-semantic-success)',
      warning: 'var(--colors-semantic-warning)',
    },
    social: {
      twitter: 'var(--colors-social-twitter)',
      linkedin: 'var(--colors-social-linkedin)',
    },
  },
  boxShadow: {
    xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    'xs-2': '0 4px 15px 0 rgba(0, 0, 0, 0.05)',
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    input: '0 0 0 3px rgba(220, 236, 236, 1)',
    notification: '0px 4px 30px rgba(26, 53, 52, 0.12)',
    none: 'none',
  },
  extend: {
    fontFamily: {
      'family-regular': ['Articulat CF', 'Helvetica', 'Roboto', 'sans-serif'],
      'family-medium': ['Articulat CF', 'Helvetica', 'Roboto', 'sans-serif'],
    },
    screens: {
      xs: '200px',
    },
    backgroundImage: {
      'primary-checkmark': `url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='%23045B3F' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3E%3C/svg%3E")`,
    },
  },
};

module.exports = {
  theme,
};
