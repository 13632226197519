import { Currencies } from 'constants/currencies';

export type BankAccount = {
  id: string;
  displayName: string;
  accountNumber: string;
  maskedNumber: string;
  institutionName: string;
  currency: Currencies;
  preDepositAuthorization: boolean;
  defaultPreDepositAuthorization?: boolean;
  institutionNumber?: string;
  transitNumber?: string;
  routingNumber?: string;
  verified: boolean;
  country?: string;
  __typename: string;
};

export enum BankAccountCurrency {
  USD = Currencies.USD,
  CAD = Currencies.CAD,
}

export enum BankAccountCountry {
  US = 'US',
  CA = 'CA',
}

export enum PADAgreementCAVersion {
  core = 'core',
}
