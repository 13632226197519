import React, { useContext, useEffect, useState } from 'react';
import { Popover, Transition, RadioGroup } from '@headlessui/react';
import { IoRadioButtonOn, IoRadioButtonOff, IoDownloadOutline, IoInformationCircleOutline } from 'react-icons/io5';
import { useLazyQuery, useQuery } from '@apollo/client';
import { get } from 'lodash';
import { useToggle } from 'hooks';

import { GET_TRANSACTIONS_PAGE_EXPORT } from 'graphql/transactions';
import Button from 'components/Button';
import { CAUSES_EXPORT_FAILS, MAX_EXPORT_TRANSACTIONS } from 'constants/index';
import { TransactionsContext } from 'context/Transactions';
import { FilterContext } from 'components/Transactions/context/FilterContext';
import { CSVLink, XLSLink } from 'components/Transactions/Transactions.utils';

const ExportsDropdown = ({ accountNames, onShowModal, mobileOrientation, setFileType }) => {
  const { isOpen, toggle: toggleOpen, close: closeDropdown } = useToggle();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      const exportEmailModal = document.getElementsByClassName('exportEmailModal');

      if (isOpen && !exportEmailModal[0].contains(e.target)) {
        toggleOpen();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen, toggleOpen]);

  return (
    <Popover>
      {() => (
        <div className={`${!mobileOrientation && 'tw-relative'} tw-py-2`}>
          <Popover.Button onClick={toggleOpen}>
            <div className="tw-flex tw-items-center tw-cursor-pointer tw-px-4">
              <IoDownloadOutline size={24} className="tw-mr-2 tw-text-primary-dark-green" />
              <div className="tw-text-sm tw-text-primary-dark-green">Export Transactions</div>
            </div>
          </Popover.Button>
          <Transition
            unmount={false}
            show={isOpen}
            leave="tw-transition tw-ease-in tw-duration-100"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            {mobileOrientation && (
              <Popover.Overlay className="tw-fixed tw-inset-0 tw-bg-neutral-dark tw-opacity-50 tw-transition-opacity" />
            )}
            <Popover.Panel
              className={`${
                mobileOrientation ? 'tw-bottom-0 tw-fixed tw-w-full' : 'tw-absolute'
              } tw-z-40 tw-right-0 tw-pt-3 tw-pb-2 tw-px-3 tw-w-60 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification exportEmailModal`}
            >
              <ExportTransactionTypes
                accountNames={accountNames}
                mobileOrientation={mobileOrientation}
                onShowModal={onShowModal}
                setFileType={setFileType}
                closeExportsDropdown={closeDropdown}
              />
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
};

const ExportTransactionTypes = ({
  accountNames,
  mobileOrientation,
  onShowModal,
  setFileType,
  closeExportsDropdown,
}) => {
  const options = ['CSV', 'XLS'];
  const [selectedOption, setSelectedOption] = useState();

  return (
    <>
      <RadioGroup
        value={selectedOption}
        onChange={(val) => {
          setSelectedOption(val);
          setFileType(val);
        }}
      >
        <RadioGroup.Label>
          <div className="tw-text-sm">Choose an export type</div>
        </RadioGroup.Label>
        <div
          className={`tw-flex ${
            mobileOrientation ? 'tw-flex-col tw-gap-y-4' : 'tw-justify-between tw-gap-x-6'
          } tw-justify-between tw-items-center tw-w-full tw-mt-2`}
        >
          {options.map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ checked }) => `
              ${checked && 'tw-bg-primary-light-green'}
                tw-w-full
              `}
            >
              {({ checked }) => (
                <div className="tw-flex tw-justify-center tw-items-center tw-p-2 tw-border tw-rounded-md tw-border-neutral-grey-3 tw-cursor-pointer">
                  {checked ? (
                    <IoRadioButtonOn size={18} className="tw-text-primary-dark-green tw-ml-1 tw-mr-2" />
                  ) : (
                    <IoRadioButtonOff size={18} className="tw-text-neutral-grey-2 tw-ml-1 tw-mr-2" />
                  )}
                  <RadioGroup.Label as="small" className="tw-mr-2">
                    {option}
                  </RadioGroup.Label>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <div className="tw-flex tw-items-center tw-justify-center tw-bg-neutral-grey-4 tw-rounded-md tw-my-4 tw-p-1">
        <IoInformationCircleOutline size={24} className="tw-mr-2" />
        <span className="tw-text-xs">Your transactions will be exported with the filters applied.</span>
      </div>
      {selectedOption && (
        <Wrapper
          accountNames={accountNames}
          selectedOption={selectedOption}
          onShowModal={onShowModal}
          closeExportsDropdown={closeExportsDropdown}
        >
          <Button primary className="tw-w-full tw-mb-1">
            Export
          </Button>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = ({ children, accountNames, selectedOption, onShowModal, closeExportsDropdown }) => {
  const { productType } = useContext(TransactionsContext);
  const { period, categories, currencies, loopProducts, search } = useContext(FilterContext);
  const [transactions, setTransactions] = useState([]);

  const filter = {
    startDate: period?.from,
    endDate: period?.to,
    currencies,
    search,
    categories: categories?.map((cat) => cat.transactionCategoryId),
    loopProducts,
    products: [productType],
  };

  const {
    data: metaData,
    loading: loadingMetadata,
    error: errorLoadingMetadata,
  } = useQuery(GET_TRANSACTIONS_PAGE_EXPORT, {
    variables: {
      ...filter,
      page: '0',
      numPerPage: '1',
    },
  });

  const totalCount = get(metaData, 'transactionsPage.pageData.totalCount');

  const [getTransactions, { loading, error }] = useLazyQuery(GET_TRANSACTIONS_PAGE_EXPORT, {
    variables: {
      ...filter,
      page: '0',
      numPerPage: `${MAX_EXPORT_TRANSACTIONS}`,
    },
    onCompleted: (data) => {
      setTransactions(data?.transactionsPage?.items || []);
    },
  });

  useEffect(() => {
    if (totalCount > 0 && totalCount <= MAX_EXPORT_TRANSACTIONS) {
      getTransactions();
    }
  }, [totalCount, getTransactions]);

  const props = { children, accountNames, transactions, productType };

  if (loadingMetadata || loading) return 'Calculating Data...';

  if (errorLoadingMetadata) {
    console.error(errorLoadingMetadata);
    onShowModal(CAUSES_EXPORT_FAILS.retrieving);
    return '';
  }
  if (error) {
    console.error(error);
    onShowModal(CAUSES_EXPORT_FAILS.retrieving);
    return '';
  }

  if (totalCount <= MAX_EXPORT_TRANSACTIONS && transactions?.length > 0)
    return (
      <>
        {`${transactions?.length} selected rows`}
        {selectedOption === 'CSV' ? (
          <div className="tw tw-space-x-1">
            <CSVLink {...props} />
          </div>
        ) : (
          <XLSLink {...props} />
        )}
      </>
    );

  if (totalCount > MAX_EXPORT_TRANSACTIONS) {
    onShowModal(CAUSES_EXPORT_FAILS.tooMany);
    closeExportsDropdown();
    return '';
  } else return 'no data';
};

export default ExportsDropdown;
