import React from 'react';
import { useQuery } from '@apollo/client';

import { buildApolloGlobalId } from 'utility/apollo';
import { GET_CREDIT_CARD_DETAILS_BY_ID } from 'graphql/cards';
import { CreditCardDetails } from 'types/creditCard';
import { getCreditCardLabel } from 'utility/creditCards';
import { CardDetailsContextType, CardDetailsContextProviderProps } from './CardDetailsPage.types';

export const CardDetailsContext = React.createContext<CardDetailsContextType>({} as CardDetailsContextType);

export const CardDetailsContextProvider = ({ children, creditCardId }: CardDetailsContextProviderProps) => {
  const creditCardGlobalId = buildApolloGlobalId({ type: 'CreditCard', id: creditCardId });

  const { data, loading, error } = useQuery<{ creditCard: CreditCardDetails }>(GET_CREDIT_CARD_DETAILS_BY_ID, {
    variables: { creditCardId: creditCardGlobalId },
  });

  const creditCardDetails = data?.creditCard;
  const { displayName, lastFourDigits } = creditCardDetails || {};
  const creditCardLabel = getCreditCardLabel({ displayName, lastFourDigits });

  return (
    <CardDetailsContext.Provider
      value={{
        isLoading: loading,
        error,
        creditCardLabel,
        creditCardDetails,
      }}
    >
      {children}
    </CardDetailsContext.Provider>
  );
};
