import React, { useEffect } from 'react';

import Button from 'components/Button';
import DocuSignIframe from 'components/DocuSign/Content';
import { AgreementDocumentType } from 'types/coreExternalAccount';
import { CATaxDeclarationProps } from './CATaxDeclaration.types';
import { useTaxDeclaration } from './hooks';

const CATaxDeclaration = ({ onNextStep, enableFullScreen, disableFullScreen }: CATaxDeclarationProps) => {
  const { signingUrl, isError, isLoading, isCompleted, skipCATaxDeclaration, setSkipCATaxDeclaration } =
    useTaxDeclaration({
      enableFullScreen,
      disableFullScreen,
      documentType: AgreementDocumentType.tax_residence_declaration_agreement,
    });

  const handleSkipTaxDeclaration = () => {
    setSkipCATaxDeclaration(!skipCATaxDeclaration);
  };

  useEffect(() => {
    if (isCompleted || skipCATaxDeclaration) {
      onNextStep();
    }
  }, [isCompleted, skipCATaxDeclaration, onNextStep]);

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-8 tw-p-8">
        <div className="tw-font-bold tw-mb-2">RC519 Declaration of Tax Residence for Entities</div>
        <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-4 tw-flex tw-justify-center">
          <div className="tw-text-sm">
            Please complete and sign the document below required for account opening. If any of the information appears
            incorrect, click on the Skip button below and a member of our team will reach out to you to finalize your
            account opening.
          </div>
        </div>

        <DocuSignIframe iframeUrl={signingUrl} isLoading={isLoading} isError={isError} isCompleted={isCompleted} />
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex lg:tw-justify-end">
        <Button className="tw-w-full lg:tw-w-max" onClick={handleSkipTaxDeclaration} primary>
          Skip
        </Button>
      </div>
    </div>
  );
};

export default CATaxDeclaration;
