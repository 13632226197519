import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { OwnerInformationSectionProps } from '../OwnerInformationSection.types';

const useOwnerInformationSection = ({ index }: Pick<OwnerInformationSectionProps, 'index'>) => {
  const { register, watch, setValue } = useFormContext();

  const watchTaxResidenceCountry = watch(`members[${index}].taxResidenceCountry`);

  const isRequiredDocumentNumber = watchTaxResidenceCountry === 'US';

  useEffect(() => {
    if (!isRequiredDocumentNumber) {
      setValue(`members[${index}].taxDocumentNumber`, '');
    }
  }, [isRequiredDocumentNumber, index]);

  return { register, isRequiredDocumentNumber };
};

export default useOwnerInformationSection;
