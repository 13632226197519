import React from 'react';

import LoopLogo from '../svg/LoopLogo';
import config from '../../config';
import BrokenClock from '../svg/BrokenClock';

import './ErrorFallback.scss';

const ErrorFallback = () => {
  return (
    <div className="ErrorFallback">
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

const Header = () => (
  <div className="ErrorFallback__Header">
    <a href="/">
      <LoopLogo />
    </a>
  </div>
);

const Content = () => (
  <div className="ErrorFallback__Content">
    <BrokenClock />

    <div className="tw-text-4xl tw-font-semibold tw-mt-4">Oops! Our app is more loopy than it should be</div>

    <div className="tw-text-base tw-text-neutral-grey-1">
      We're surprised by this issue too. Our team will look into this as soon as we can.
    </div>
  </div>
);

const Footer = () => (
  <div className="ErrorFallback__Footer">
    <div className="tw-flex tw-flex-grow-0 tw-items-center">
      <small className="tw-text-neutral-grey-2">{config.year} © Loop. ALL Rights Reserved. </small>
      <a className="tw-hidden lg:tw-block tw-ml-8" href={config.privacyPolicyUrl} target="_blank" rel="noreferrer">
        <small className="tw-underline tw-text-neutral-grey-2">Legal </small>
      </a>
    </div>
  </div>
);

export default ErrorFallback;
