import React from 'react';
import { FormProvider } from 'react-hook-form';
import cx from 'classnames';

import config from 'config';
import Button from 'components/Button';
import { TextField, Select } from 'components/FormFields/v2';
import { mainIndustries } from 'constants/index';
import { useIndustryCode } from './hooks';
import { IndustryCodeProps } from './IndustryCode.types';

const IndustryCode = ({ onPrevStep, onNextStep }: IndustryCodeProps) => {
  const {
    form,
    register,
    handleSubmit,
    handleOnNextStep,
    industryOptions,
    setSelectedMainIndustry,
    isDisabled,
    isLoading,
    error,
  } = useIndustryCode({
    onNextStep,
  });

  return (
    <FormProvider {...form} {...{ graphQLErrors: error?.graphQLErrors }}>
      <form onSubmit={handleSubmit(handleOnNextStep)}>
        <div className="tw-flex tw-flex-col tw-gap-6 tw-text-sm tw-pt-6 tw-px-8 tw-mb-10">
          <div>
            Our sponsor bank in the US (Lewis & Clark Bank) needs to confirm a few details about your business to
            continue providing the best support possible.
          </div>
          <Select
            name="mainIndustry"
            label="Industry"
            placeholder="Select Option"
            required
            ref={register({ required: true })}
            options={mainIndustries}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setSelectedMainIndustry(e.target.value);
            }}
          />
          <Select
            name="industry"
            label="Sub-Industry"
            placeholder="Select Option"
            required
            ref={register({ required: true })}
            options={industryOptions}
          />
          <TextField
            name="naicsCodeValue"
            label="NAICS code selected based on your chosen industries:"
            className="tw-font-semibold"
            disabled
            ref={register({ required: true })}
          />
          <div className="tw-mt-4">
            Please contact{' '}
            <a target="_blank" rel="noopener noreferrer" href={`mailto:${config.supportEmail}`}>
              <span className="tw-font-semibold tw-text-neutral-dark">{config.supportEmail}</span>
            </a>{' '}
            if you have any questions about selecting your industry or NAICS code.
          </div>

          {error?.message && !isLoading && <div className="tw-text-semantic-error tw-text-xs">{error.message}</div>}
        </div>
        <div
          className={cx(
            'tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-py-4 tw-flex',
            onPrevStep ? 'tw-justify-between' : 'tw-justify-end'
          )}
        >
          {onPrevStep && (
            <Button onClick={onPrevStep} secondary>
              Back
            </Button>
          )}
          <Button primary isDisabled={isDisabled || isLoading}>
            Next
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default IndustryCode;
