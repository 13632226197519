import React from 'react';

const EmptyState = ({ type }) => (
  <div className="tw-flex tw-justify-content-center">
    <span className="tw-text-neutral-grey-2 tw-text-sm tw-text-center">
      {type === 'accounting'
        ? 'You have no accounting integrations connected.'
        : 'You have no connected sales channels.'}
    </span>
  </div>
);

export default EmptyState;
