import React from 'react';
import { Transition, Listbox } from '@headlessui/react';
import { BsChevronDown } from 'react-icons/bs';
import { IoCloseCircleOutline } from 'react-icons/io5';

import { TEAM_MEMBER_STATUS, TEAM_INVITATION_STATUS } from 'constants/index';

const Filters = ({ roles, setRoles, statuses, setStatuses, onClearFilters }) => {
  const roleOptions = [
    { label: 'Read-only', value: 'Read-only' },
    { label: 'Owner', value: 'Owner' },
    { label: 'Admin', value: 'Admin' },
    { label: 'Member', value: 'Member' },
  ];
  const statusOptions = [
    { label: 'Verified', value: TEAM_MEMBER_STATUS.active },
    { label: 'Inactive', value: TEAM_MEMBER_STATUS.inactive },
    { label: 'Not Invited', value: TEAM_MEMBER_STATUS.not_invited },
    { label: 'Invite Sent', value: TEAM_INVITATION_STATUS.pending },
    { label: 'Pending User Info', value: TEAM_INVITATION_STATUS.pending_info },
    { label: 'Pending Verification', value: TEAM_INVITATION_STATUS.pending_verification },
  ];
  const hasFilters = roles.length + statuses.length > 0;

  return (
    <div className="tw-flex tw-items-center tw-mb-4 tw-space-x-4">
      <small className="tw-text-neutral-grey-2">Filter</small>
      <FilterDropdown
        options={roleOptions}
        label="Role"
        testId="filter-role-dropdown"
        selectedValues={roles}
        setSelectedValues={setRoles}
      />
      <FilterDropdown
        options={statusOptions}
        label="Status"
        testId="filter-status-dropdown"
        selectedValues={statuses}
        setSelectedValues={setStatuses}
      />
      {hasFilters && (
        <div
          onClick={onClearFilters}
          className="tw-flex tw-items-center tw-cursor-pointer tw-text-neutral-grey-2 tw-space-x-2"
        >
          <IoCloseCircleOutline size={18} />
          <small>Clear all</small>
        </div>
      )}
    </div>
  );
};

const FilterDropdown = ({ options, label, selectedValues, setSelectedValues, testId }) => {
  const handleSelect = (option) => {
    const optionIndex = selectedValues.indexOf(option);
    if (optionIndex === -1) {
      setSelectedValues([...selectedValues, option]);
    } else {
      setSelectedValues([...selectedValues.slice(0, optionIndex), ...selectedValues.slice(optionIndex + 1)]);
    }
  };

  return (
    <Listbox value={selectedValues} onChange={handleSelect}>
      <div className="tw-relative tw-p-2">
        <Listbox.Button data-testid={testId} className="tw-flex tw-items-center tw-cursor-pointer tw-p-2">
          <small className="tw-mr-2">{`${label}${
            selectedValues && selectedValues.length > 0 ? ` (${selectedValues.length})` : ''
          }`}</small>
          <BsChevronDown size={14} className="tw-text-neutral-grey-2" />
        </Listbox.Button>
        <Transition
          unmount={false}
          as={React.Fragment}
          leave="tw-transition tw-ease-in tw-duration-100"
          leaveFrom="tw-opacity-100"
          leaveTo="tw-opacity-0"
        >
          <Listbox.Options className="tw-absolute tw-z-40 tw-w-max tw-py-1 tw-overflow-auto tw-bg-neutral-light tw-rounded-md tw-shadow-notification">
            {options.map((option) => {
              const isSelected = selectedValues.includes(option.value);
              const onCheck = () => handleSelect(option.value);

              return (
                <Listbox.Option
                  key={option.value}
                  value={option.value}
                  className="tw-flex tw-items-center tw-justify-start tw-p-2 tw-cursor-pointer tw-space-x-2"
                  data-testid={`${option.value.toLowerCase()}-option`}
                >
                  <input checked={isSelected} onChange={onCheck} type="checkbox" />
                  <small>{option.label}</small>
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default Filters;
