import React from 'react';
import { ReimbursementDetailProps } from './ReimbursementDetail.types';
import AttributeRow from '../AttributeRow';
import useReimbursementDetail from './hooks/useReimbursementDetail';
import { capitalize } from 'utility/string';

const ReimbursementDetail = ({ header, request }: ReimbursementDetailProps) => {
  const {
    formattedAmountRequested,
    formattedOriginalAmount,
    formattedTaxAmount,
    formattedOriginalCurrency,
    formattedTransactionDate,
    formattedDateApproved,
    status,
    requester,
    description,
    approver,
    showAmountInCAD,
    merchantName,
    dateApproved,
    receipt,
    declineReason,
    formattedApprovedByLabel,
    formattedDateApprovedLabel,
    formattedCreatedAt,
  } = useReimbursementDetail({ request });

  return (
    <div className="tw-bg-neutral-grey-3.5 lg:tw-bg-neutral-light tw-p-6 lg:tw-h-full">
      <div className="tw-h-28 tw-border-b tw-border-neutral-grey-4">
        {header}
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <div className="tw-flex tw-flex-col">
            <span className="tw-text-2xl tw-font-semibold">{formattedAmountRequested}</span>
            <div className="tw-text-sm tw-mt-2 tw-text-neutral-grey-2">{formattedCreatedAt}</div>
          </div>
          <div className="tw-text-sm tw-text-right">Expense Reimbursement</div>
        </div>
      </div>
      <div className="tw-mt-6 lg:tw-mt-0 tw-rounded-md tw-bg-neutral-light tw-p-2 lg:tw-p-0 tw-overflow-y-scroll tw-overflow-x-hidden tw-scrollbar-hide tw-h-96 lg:tw-h-4/5">
        <AttributeRow label="Status" value={capitalize(status)} />
        <AttributeRow label="Requester" value={capitalize(requester?.name)} />
        <AttributeRow label="Merchant Name" value={capitalize(merchantName)} />
        <AttributeRow label="Description" value={capitalize(description)} />
        <AttributeRow label="Amount" value={formattedOriginalAmount} />
        <AttributeRow label="Tax Amount" value={formattedTaxAmount} />
        <AttributeRow label="Currency" value={formattedOriginalCurrency} />
        {showAmountInCAD && <AttributeRow label="Amount in CAD" value={formattedAmountRequested} />}
        {declineReason && <AttributeRow label="Decline Reason" value={declineReason} />}
        <AttributeRow label="Transaction Date" value={formattedTransactionDate} />
        {dateApproved && <AttributeRow label={formattedDateApprovedLabel} value={formattedDateApproved} />}
        {approver && <AttributeRow label={formattedApprovedByLabel} value={capitalize(approver.name)} />}
        {receipt && (
          <div className="tw-my-3 tw-border-t tw-border-b tw-py-3 tw-border-neutral-grey-4">
            <a
              className="tw-font-semibold tw-text-sm tw-text-primary-dark-green"
              href={receipt}
              target="_blank"
              rel="noreferrer"
            >
              View Receipt
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReimbursementDetail;
