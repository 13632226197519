import React, {
  useState,
  createContext,
  SetStateAction,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useRef,
  MutableRefObject,
} from 'react';

import { CreateCardFormType, CreatedCardType } from '../CardForm.types';
import { CREDIT_CARD_LIMITS } from 'constants/index';
import { CreditCard, CreditCardCreateModal } from 'types/creditCard';
import { InternalContact, User } from 'types/user';
import { AuthContext } from 'context/Auth';
import useIsMember from 'hooks/useIsMember';
import { useGetSubscription } from 'hooks';
import { TeamContext } from 'components/settings/Team/TeamContext';
import { StepType } from '../CreateCardModal.types';
import { getCreateCardSteps } from './CreateCardContext.utils';

type CreateCardContextType = {
  createCardFormDataRef: MutableRefObject<CreateCardFormType | null>;
  createdCardData: CreatedCardType | null;
  setCreatedCardData: Dispatch<SetStateAction<CreatedCardType | null>>;
  stepTitle?: string;
  steps: StepType;
  currentStep: number;
  isPhysicalCardFlow: boolean;
  setIsPhysicalCardFlow: Dispatch<SetStateAction<boolean>>;
  onPrevStep: () => void;
  onNextStep: () => void;
  me: User;
  isMember: boolean;
  creditCards: CreditCard[];
  hasNoCards: boolean;
  onClose: () => void;
  members: InternalContact[];
  loadingMembers: boolean;
  showLimit: boolean;
  chargePhysicalCardFee: boolean;
};

export const CreateCardContext = createContext<CreateCardContextType>({} as CreateCardContextType);

export const CreateCardContextProvider = ({
  children,
  creditCards,
  onClose,
}: {
  children: React.ReactNode;
  creditCards: CreditCardCreateModal[];
  onClose: () => void;
}) => {
  const createCardFormDataRef = useRef<CreateCardFormType | null>(null);
  const [createdCardData, setCreatedCardData] = useState<CreatedCardType | null>(null);
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const { isMember } = useIsMember();
  const { maxVirtualCards, maxPhysicalCards } = useGetSubscription();

  const { members, loadingMembers, getMembers } = useContext(TeamContext) as unknown as {
    members: InternalContact[];
    loadingMembers: boolean;
    getMembers: () => void;
  };

  const hasNoCards = creditCards.length === 0;
  const [isPhysicalCardFlow, setIsPhysicalCardFlow] = useState(false);
  const steps = useMemo(
    () => getCreateCardSteps({ includeKeyTerms: hasNoCards, isPhysicalCard: isPhysicalCardFlow }),
    [hasNoCards, getCreateCardSteps, isPhysicalCardFlow]
  );
  const [currentStep, setCurrentStep] = useState(steps['Card Setup']);

  const stepTitle = Object.keys(steps).find((key) => steps[key] === currentStep);

  const chargePhysicalCardFee = me?.account?.physicalCardCount > (maxPhysicalCards || CREDIT_CARD_LIMITS.PHYSICAL);

  const virtualCardCount = creditCards.filter((creditCard: CreditCard) => creditCard.virtual).length;
  const isCreateLimitReached = virtualCardCount >= Number(maxVirtualCards || CREDIT_CARD_LIMITS.VIRTUAL);

  const showLimit = isCreateLimitReached && currentStep === 0;

  const onPrevStep = () => {
    setCurrentStep((prevStep) => (prevStep === 0 ? prevStep : prevStep - 1));
  };

  const onNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <CreateCardContext.Provider
      value={{
        createCardFormDataRef,
        createdCardData,
        setCreatedCardData,
        members,
        loadingMembers,
        currentStep,
        stepTitle,
        steps,
        isPhysicalCardFlow,
        setIsPhysicalCardFlow,
        onPrevStep,
        onNextStep,
        me,
        isMember,
        creditCards,
        hasNoCards,
        onClose,
        showLimit,
        chargePhysicalCardFee,
      }}
    >
      {children}
    </CreateCardContext.Provider>
  );
};
