import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { AiOutlineUser, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { Tab } from '@headlessui/react';

import { GET_ADMIN_NOTIFICATION_SETTINGS } from 'graphql/settings';
import { AuthContext } from 'context/Auth';
import { Loaders } from 'components/cards/Loader';
import SettingsLayout from 'containers/settings/SettingsLayout';
import NotificationSettings from 'components/settings/businessNotifications/NotificationSettings';
import GeneralNotifications from 'components/settings/businessNotifications/GeneralNotifications';
import { USER_ROLE_TYPES } from 'constants/index';
import { TabIndexContext } from 'context/TabIndex';

const BusinessNotificationSettings = () => {
  const { me, meLoading } = useContext(AuthContext);
  const role = _.get(me, 'internalContact.role');
  const internalContactId = _.get(me, 'internalContact.id');
  const creditCards = _.get(me, 'account.creditCards', []);
  const lineOfCredit = _.get(me, 'account.lineOfCredit', {});
  const payees = _.get(me, 'account.payees', []);

  return (
    <SettingsLayout>
      <h5 className="tw-py-3">Notifications</h5>
      {meLoading ? (
        <Loaders.Light />
      ) : (
        <>
          {role === USER_ROLE_TYPES.accountOwner || role === USER_ROLE_TYPES.admin ? (
            <Tabs
              creditCards={creditCards}
              lineOfCredit={lineOfCredit}
              payees={payees}
              internalContactId={internalContactId}
            />
          ) : (
            <MemberSettingsForm tab={false} internalContactId={internalContactId} />
          )}
        </>
      )}
    </SettingsLayout>
  );
};

const Tabs = (props) => {
  const { defaultIndex } = useContext(TabIndexContext);
  const query = new URLSearchParams(useLocation().search);
  const userNotifications = query.get('user');

  const tabs = [
    {
      label: 'Business Notification Settings',
      icon: <AiOutlineUsergroupAdd />,
    },
    {
      label: 'User Notification Settings',
      icon: <AiOutlineUser />,
    },
  ];

  return (
    <Tab.Group defaultIndex={userNotifications ? 1 : defaultIndex}>
      <div className="tw-flex tw-justify-between tw-items-center tw-my-4">
        <Tab.List>
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              route={tab.route}
              className={({ selected }) =>
                `tw-bg-neutral-grey-5 tw-py-2 tw-px-6 tw-border-2 ${
                  selected
                    ? 'tw-border-primary-light-green tw-bg-primary-light-green tw-text-primary-dark-green hover:tw-text-primary-dark-green'
                    : 'tw-border-neutral-grey-4'
                } tw-rounded-md tw-inline-flex tw-items-center tw-mr-4 tw-gap-x-2`
              }
            >
              {tab.icon}
              {tab.label}
            </Tab>
          ))}
        </Tab.List>
      </div>
      <Tab.Panels>
        <Tab.Panel>
          <AdminSettingsForm {...props} />
        </Tab.Panel>
        <Tab.Panel>
          <MemberSettingsForm internalContactId={props.internalContactId} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};

const MemberSettingsForm = ({ tab = true, internalContactId }) => {
  return (
    <div className="tw-px-4 tw-py-4">
      <span className="tw-text-sm tw-font-medium tw-text-neutral-grey-2">
        Customize your personal notification settings from the list below.
      </span>
      {!tab && <h5 className="tw-mt-8 tw-mb-4">Member Notification Settings</h5>}
      <GeneralNotifications internalContactId={internalContactId} />
    </div>
  );
};

const AdminSettingsForm = ({ creditCards, lineOfCredit, payees }) => {
  const { data: adminData, loading: adminDataLoading } = useQuery(GET_ADMIN_NOTIFICATION_SETTINGS);

  const cardAdminNotificationSettings = _.get(adminData, 'adminNotificationSettings.card');
  const accountAdminNotificationSettings = _.get(adminData, 'adminNotificationSettings.account');
  const locAdminNotificationSettings = _.get(adminData, 'adminNotificationSettings.capital');
  const paymentAdminNotificationSettings = _.get(adminData, 'adminNotificationSettings.payment');

  if (adminDataLoading) {
    return <Loaders.Light />;
  }

  return (
    <div className="tw-px-4 tw-py-4">
      <span className="tw-text-sm tw-font-medium tw-text-neutral-grey-2">
        Customize the notifications that your business receives and who receives them by selecting the notification from
        the list below.
      </span>
      {creditCards?.length > 0 && <NotificationSettings settings={cardAdminNotificationSettings} group="card" />}
      <NotificationSettings settings={accountAdminNotificationSettings} group="account" />
      {lineOfCredit && <NotificationSettings settings={locAdminNotificationSettings} group="capital" />}
      {payees?.length > 0 && <NotificationSettings settings={paymentAdminNotificationSettings} group="payment" />}
    </div>
  );
};

export default BusinessNotificationSettings;
