import React from 'react';

import useHome from './hooks/useHome';
import { OnboardingDashboardLayout } from '../';
import { Loaders } from 'components/cards/Loader';
import { PendingDocumentUpload, PendingDocumentVerification } from './components';
import IdentityVerification from 'components/IdentityVerification';

const Home = () => {
  const { loading, firstName, isMissingDocuments } = useHome();

  if (loading) return <Loaders.FullScreen />;

  return (
    <OnboardingDashboardLayout>
      <div>
        <h2>Your Financial Dashboard</h2>
        <p>Welcome back, {firstName}!</p>
      </div>

      <IdentityVerification />

      {isMissingDocuments ? <PendingDocumentUpload /> : <PendingDocumentVerification />}
    </OnboardingDashboardLayout>
  );
};

export default Home;
