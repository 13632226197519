import { useCallback, useContext, useMemo, useEffect } from 'react';
import { useLazyQuery, useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { ampTrackEvent } from 'amplitude';
import { CardRepaymentContext, CardRepaymentError } from 'context/CardRepayment';
import { PAY_BALANCE } from 'graphql/payments';
import { GET_PAY_CARD_BALANCE } from 'graphql/cards';
import { PayBalanceCurrencyOptions, PayBalanceTransactionResponse, PayBalanceType } from 'types/payments';
import { useToggle } from 'hooks';
import { cardRepaymentSteps } from 'components/payments/CardRepayment/constants';
import { ReviewProps } from '../Review.types';
import { BankAccount } from 'types/bankAccount';

const useReview = ({ onNextStep, onPrevStep, onComplete }: ReviewProps) => {
  const {
    payBalanceInfo,
    setError,
    setPayBalanceTransactions,
    setBankAccounts,
    bankAccounts,
    setIsLoading,
    isLoading,
  } = useContext(CardRepaymentContext);

  const [payBalance] = useMutation(PAY_BALANCE);
  const [getPayCardBalance, { data: getCardBalanceLoadingData, loading: isGetCardBalanceLoading }] = useLazyQuery(
    GET_PAY_CARD_BALANCE,
    { nextFetchPolicy: 'network-only' }
  );

  const { isOpen: showPDAModal, open: openPDAModal, close: closePDAModal } = useToggle();

  const { transactions, paymentCurrencyOption, paymentType } = payBalanceInfo || {};

  const activeTransactions = useMemo(
    () => transactions?.filter((txn) => !txn.isDisabled && txn.selectedFromAccountId),
    [transactions]
  );

  const selectedBankAccountsWithoutPAD = useMemo(() => {
    const selectedFromAccountIds = activeTransactions?.map((txn) => txn.selectedFromAccountId);

    return bankAccounts.filter(
      ({ id, defaultPreDepositAuthorization }) =>
        selectedFromAccountIds?.includes(id) && !defaultPreDepositAuthorization
    );
  }, [activeTransactions, bankAccounts]);

  const [firstFromBankAccountsWithoutPAD] = selectedBankAccountsWithoutPAD;

  const isPreDepositAuthorizationRequired = !!selectedBankAccountsWithoutPAD.length;

  const onMoveFunds = useCallback(async () => {
    const processingToast = toast.loading('Processing transactions...');
    setIsLoading(true);
    setError(null);

    try {
      if (!activeTransactions?.length) {
        throw new Error('Please select at least one transaction.');
      }

      const requests = activeTransactions.map((txn) => {
        const { selectedFromAccountId, originalAmount, fxAmount } = txn;
        const { currency: originalCurrency } = originalAmount;
        const amount = fxAmount || originalAmount;

        const payload = {
          from: selectedFromAccountId,
          to: originalCurrency,
          originalAmount: amount,
          chargedAmount: amount,
          payAll:
            paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_CAD ||
            paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_USD,
          payMin: paymentType === PayBalanceType.MINIMUM_DUE,
        };

        return payBalance({
          variables: payload,
        });
      });

      const responses = await Promise.allSettled(requests);

      if (!responses?.length) {
        throw new Error('Error paying balance - please try again');
      }

      const payBalanceTransactionsResponse: (PayBalanceTransactionResponse | null)[] = responses.map((response) =>
        response.status === 'fulfilled' ? response.value.data?.moveFunds : null
      );

      const isSuccessful = payBalanceTransactionsResponse.every(Boolean);

      const payBalanceTransactions = activeTransactions.map((txn, index) => {
        const txnResponse = payBalanceTransactionsResponse[index];

        const { transactionGroupId, initiatedAt } = txnResponse || {};

        return {
          ...txn,
          transactionGroupId,
          initiatedAt,
        };
      });

      setPayBalanceTransactions(payBalanceTransactions);

      if (isSuccessful) {
        ampTrackEvent('payBalance: transferFunds: success');
        toast.update(processingToast, {
          render: 'All transactions have been submitted',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        ampTrackEvent('payBalance: transferFunds: partially failed');
        toast.update(processingToast, {
          render: 'Some of the transactions have not been submitted',
          type: 'warning',
          isLoading: false,
          autoClose: 3000,
        });

        setError({
          message: 'Some of the transactions have not been submitted',
          step: cardRepaymentSteps.Complete,
        } as CardRepaymentError);
      }

      onNextStep();
      onComplete();
    } catch (err) {
      console.error(err);
      ampTrackEvent('payBalance: transferFunds: failed');
      toast.update(processingToast, {
        render: `Something went wrong. ${(err as ApolloError)?.message || err}. Please try again.`,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      setError({ ...(err as ApolloError), step: cardRepaymentSteps.Review });
      onPrevStep();
    } finally {
      setIsLoading(false);
    }
  }, [
    activeTransactions,
    paymentCurrencyOption,
    paymentType,
    onNextStep,
    onComplete,
    onPrevStep,
    setError,
    setPayBalanceTransactions,
    setIsLoading,
  ]);

  const handleSubmitReviewStep = () => {
    if (isPreDepositAuthorizationRequired) {
      openPDAModal();
      return;
    }

    onMoveFunds();
  };

  const handleSubmitPADAgreement = async () => {
    await getPayCardBalance();
    closePDAModal();
  };

  useEffect(() => {
    if (getCardBalanceLoadingData) {
      const { me } = getCardBalanceLoadingData;
      const updatedBankAccounts: BankAccount[] = get(me, 'account.bankAccounts') || [];
      setBankAccounts((prevBankAccounts) =>
        prevBankAccounts.map((bankAccount) => {
          const updatedBankAccount = updatedBankAccounts.find(({ id }) => bankAccount.id === id);
          return updatedBankAccount ? { ...bankAccount, ...updatedBankAccount } : bankAccount;
        })
      );
    }
  }, [getCardBalanceLoadingData, setBankAccounts]);

  return {
    transactions: activeTransactions,
    handleSubmitReviewStep,
    handleSubmitPADAgreement,
    isLoading: isLoading || isGetCardBalanceLoading,
    showPDAModal,
    closePDAModal,
    isPreDepositAuthorizationRequired,
    firstFromBankAccountsWithoutPAD,
    hideTransactionLabels:
      paymentType === PayBalanceType.MINIMUM_DUE ||
      paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_CAD ||
      paymentCurrencyOption === PayBalanceCurrencyOptions.ALL_IN_USD,
  };
};

export default useReview;
