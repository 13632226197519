import { formatMoneyV2 } from './currency';
import { Currencies } from 'constants/currencies';

const DEFAULT_AMOUNTS_BY_CURRENCY = {
  CAD: { currency: 'CAD', amount: 0 },
  USD: { currency: 'USD', amount: 0 },
  EUR: { currency: 'EUR', amount: 0 },
  GBP: { currency: 'GBP', amount: 0 },
};

const DEFAULT_BALANCES = Object.values(DEFAULT_AMOUNTS_BY_CURRENCY);

export const sumBalances = (creditCards) => {
  if (creditCards && creditCards.length > 0) {
    return creditCards.reduce((sum, card) => {
      return (card.balances || DEFAULT_BALANCES).map((balance) => {
        const current = sum.find((amount) => amount.currency === balance.currency) || {
          currency: balance.currency,
          amount: 0,
        };

        return { currency: balance.currency, amount: current.amount + balance.amount };
      });
    }, []);
  }

  return DEFAULT_BALANCES;
};

export const sumEndingBalanceAmount = (creditCards) => {
  const result = { ...DEFAULT_AMOUNTS_BY_CURRENCY };

  if (creditCards && creditCards.length > 0) {
    creditCards.forEach((card) => {
      if (card.statements) {
        const lastStatement = card.statements[0];

        if (lastStatement) {
          Object.keys(result).forEach((currency) => {
            const currentAmount =
              lastStatement.endingBalanceByCurrency && lastStatement.endingBalanceByCurrency[currency]
                ? lastStatement.endingBalanceByCurrency[currency].cents
                : 0;

            result[currency] = { currency, amount: result[currency].amount + currentAmount };
          });
        }
      }
    });
  }

  return result;
};

export const getLastStatement = (creditCards) => {
  const mainCreditCard = (creditCards && creditCards[0]) || { statements: [] };

  return mainCreditCard.statements[0];
};

export const getAvailableAmount = (groupOverlimit, groupAvailableBalance) => {
  if (groupOverlimit === null || groupAvailableBalance === null) {
    return formatMoneyV2({ amount: 0, currency: 'CAD' });
  }
  if (groupOverlimit?.amount === 0) {
    return formatMoneyV2(groupAvailableBalance);
  } else {
    const overlimitAmount = groupOverlimit?.amount || 0;
    const newAmount = {
      amount: groupAvailableBalance?.amount + overlimitAmount,
      currency: groupAvailableBalance?.currency || 'CAD',
    };
    return formatMoneyV2(newAmount);
  }
};

export const sortBalancesByCurrency = ({
  items,
  currencyField = 'currency',
  order = [Currencies.CAD, Currencies.USD, Currencies.EUR, Currencies.GBP],
}) => {
  if (!items?.length) return items;

  const itemsLength = order.length;

  const tempSortedItems = items.reduce((acc, item) => {
    const sortedIndex = order.indexOf(item[currencyField]);

    if (sortedIndex !== -1) {
      acc.splice(sortedIndex, 1, item);
    } else {
      acc.push(item);
    }

    return acc;
  }, new Array(itemsLength).fill(null));

  const sortedItems = tempSortedItems.filter(Boolean);

  return sortedItems;
};

export const getCreditCardLabel = ({ displayName, lastFourDigits }) => {
  if (!displayName || !lastFourDigits) return 'Credit Card';

  return `${displayName} - ${lastFourDigits}`;
};
