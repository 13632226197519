export enum CardType {
  Physical = 'Physical',
  Virtual = 'Virtual',
}

export enum LinkedAccountType {
  Credit = 'Credit',
  LoopAccount = 'Loop Account',
}

export enum SettlementType {
  MultiCurrency = 'Multi-Currency',
  SingleCurrency = 'Single Currency',
}
