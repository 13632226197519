import { useContext } from 'react';
import { toast } from 'react-toastify';
import { useMutation, ApolloError } from '@apollo/client';

import { ReviewReimbursementRequestDetailsProps } from '../ReviewReimbursementRequestDetails.types';
import { CreateReimbursementRequestContext } from 'components/Approvals/ApprovalsContent/components/ReimbursementRequestModal/context/CreateReimbursementRequest';
import { CREATE_REIMBURSEMENT_REQUEST, GET_REIMBURSEMENT_REQUESTS } from 'graphql/reimbursementRequest';
import { CreateReimbursementRequestDataType } from 'components/Approvals/ApprovalsContent/components/ReimbursementRequestModal/CreateReimbursementRequest.types';
import { APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';
import { FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';

const useReviewReimbursementRequestDetails = ({ onNextStep, onPrevStep }: ReviewReimbursementRequestDetailsProps) => {
  const { reimbursementRequestInfo, setReimbursementRequestInfo, showAmountInCAD } = useContext(
    CreateReimbursementRequestContext
  );
  const { page, numPerPage, period, selectedMembers, selectedCurrencies } = useContext(FilterRequestsContext);

  const { amountRequested, originalAmount, taxAmount, merchantName, transactionDate, description, category } =
    reimbursementRequestInfo || {};

  const [createReimbursementRequest, { loading }] = useMutation(CREATE_REIMBURSEMENT_REQUEST, {
    refetchQueries: [
      {
        query: GET_REIMBURSEMENT_REQUESTS,
        variables: {
          status: APPROVAL_REQUEST_STATUS.pending,
          page: `${page}`,
          numPerPage: `${numPerPage}`,
          startDate: period?.from,
          endDate: period?.to,
          members: selectedMembers.map((member) => member.id),
          currencies: selectedCurrencies,
        },
        fetchPolicy: 'network-only',
      },
    ],
  });

  const handleSubmit = async () => {
    try {
      const res = await createReimbursementRequest({
        variables: { ...reimbursementRequestInfo, requestType: 'reimbursement' },
      });
      if (!res.data?.createReimbursementRequest) throw new Error('Error adding Payment Request - please try again');

      setReimbursementRequestInfo({
        ...(reimbursementRequestInfo as CreateReimbursementRequestDataType),
        requestType: 'reimbursement',
      });

      onNextStep();
    } catch (err) {
      console.error(err);
      toast.error(`Error: ${(err as ApolloError)?.message || 'Please try again'}`);
      onPrevStep();
    }
  };

  return {
    handleSubmit,
    amountRequested,
    merchantName,
    originalAmount,
    showAmountInCAD,
    transactionDate,
    description,
    category,
    taxAmount,
    isLoading: loading,
  };
};

export default useReviewReimbursementRequestDetails;
