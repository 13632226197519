import React from 'react';
import { BiCheck, BiErrorCircle } from 'react-icons/bi';
import cx from 'classnames';

import { StepsProps } from './Step.types';

import styles from './Step.module.scss';

const Step = ({
  isCurrent,
  isPast,
  label,
  labelContent,
  hasError,
  isVertical,
  isFirstStep,
  isLastStep,
}: StepsProps) => (
  <div
    className={cx(
      isVertical ? styles.stepVertical : 'tw-flex tw-flex-col tw-items-start',
      isVertical && {
        [styles.isPast]: isPast,
        [styles.isFirstStep]: isFirstStep,
        [styles.isLastStep]: isLastStep,
        [styles.hasError]: hasError,
      }
    )}
  >
    {isPast || isCurrent ? (
      <div
        className={cx(
          isVertical ? 'tw--mt-0.5 tw--ml-3 tw-z-10' : 'tw-mb-2',
          'tw-w-5 tw-h-5 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-ring tw-border-2',
          hasError
            ? 'tw-bg-semantic-error tw-border-semantic-error tw-ring-semantic-error-background'
            : 'tw-border-primary-dark-green tw-ring-secondary-pastel-green-100',
          !hasError && (isPast ? 'tw-bg-primary-dark-green' : 'tw-bg-neutral-light')
        )}
      >
        {hasError ? (
          <BiErrorCircle className="tw-text-neutral-light" size="1.25rem" />
        ) : (
          <>
            {isPast && <BiCheck className="tw-text-neutral-light" size="1.25rem" />}
            {isCurrent && !isPast && <div className="tw-w-5 tw-h-5 tw-rounded-full"></div>}
          </>
        )}
      </div>
    ) : (
      <div
        className={cx(
          isVertical ? 'tw--ml-3 tw-z-10' : 'tw-mb-2',
          'tw-bg-neutral-light tw-rounded-full tw-h-5 tw-w-5 tw-border',
          hasError && 'tw-border-semantic-error tw-ring tw-ring-semantic-error-background',
          !hasError && (isCurrent ? 'tw-ring-secondary-pastel-green-100' : 'tw-border-dashed tw-border-neutral-grey-2')
        )}
      >
        <div className="tw-h-5 tw-w-5" />
      </div>
    )}

    <div className={cx(styles.label)}>
      {labelContent || (
        <span
          className={cx(
            'tw-text-xs',
            isCurrent ? 'tw-font-semibold' : 'tw-text-neutral-grey-2',
            !isVertical && !isLastStep && 'tw-pr-2'
          )}
        >
          {label}
        </span>
      )}
    </div>
  </div>
);

export default Step;
