import React from 'react';
import { useHistory } from 'react-router-dom';

import { Banner } from 'components/UI';
import { BannerType } from 'components/UI/Banner';
import Speedometer from 'components/svg/Speedometer';
import { CreditCardsSummary, AccountsSummary, PaymentsSummary, RecentTransactions } from './components';

const PendingDocumentVerification = () => {
  const history = useHistory();

  const navigateToOnboardingAdditionalDetails = () => history.push('/onboarding/additional-details');

  return (
    <div className="tw-mb-16">
      <Banner
        type={BannerType.secondary}
        title="Processing Your Account Information"
        message="We are reviewing the information you submitted and will send you an email once  processed. You may continue to  upload additional documents, re-send consent links to your directors or begin to explore your dashboard."
        submitButtonLabel="Upload Documents"
        onSubmit={navigateToOnboardingAdditionalDetails}
        icon={<Speedometer />}
        iconAlignment="center"
        classNames="tw-mt-8"
      />
      <CreditCardsSummary />
      <AccountsSummary />
      <PaymentsSummary />
      <RecentTransactions />
    </div>
  );
};

export default PendingDocumentVerification;
