import { useQuery } from '@apollo/client';

import { useGetUserInfo } from 'hooks';
import { GET_DOCUMENTS } from 'graphql/document';

const useHome = () => {
  const { loading: loadingDocumentData, data: documentData } = useQuery(GET_DOCUMENTS);

  const { documents: myDocuments } = documentData || {};

  const { isLoading, firstName, kycFailedReasons } = useGetUserInfo();

  const isMissingDocuments = !!kycFailedReasons.filter(
    (reason) => !myDocuments?.some((d: { category: string }) => d.category === reason)
  ).length;

  const loading = isLoading || loadingDocumentData;

  return {
    loading,
    firstName,
    isMissingDocuments,
  };
};

export default useHome;
