import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from './config';

if (config.env === 'production') {
  try {
    Sentry.init({
      dsn: config.sentryDSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  } catch (err) {
    console.log(err);
  }
}
