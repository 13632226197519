import React from 'react';

import { StepAndConnectorProps } from './StepAndConnector.types';
import { Connector, Step } from '../';
import cx from 'classnames';

const StepAndConnector = ({
  currentStep,
  steps,
  label,
  labelContent,
  hasError,
  isCompleted,
  isVertical,
  isFirstStep,
  isLastStep,
}: StepAndConnectorProps) => {
  const isCurrent = steps.includes(currentStep);
  const isPast = steps.every((step) => step < currentStep) || !!isCompleted;

  return (
    <div className={cx('tw-flex tw-flex-grow', isVertical ? '' : 'tw-justify-center tw-flex-col tw-w-1/3 tw-relative')}>
      <div className="tw-flex tw-w-full">
        <Step
          isPast={isPast}
          isCurrent={isCurrent}
          label={label}
          labelContent={labelContent}
          hasError={hasError}
          isVertical={isVertical}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
        />
      </div>
      {!isVertical && !isLastStep && <Connector isPast={isPast} />}
    </div>
  );
};

export default StepAndConnector;
