import { Currencies } from 'constants/currencies';

export enum ExternalAccountStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum AccountWalletStatus {
  active = 'active',
  inactive = 'inactive',
}

export type AccountWallet = {
  id: string;
  name: string;
  displayName: string;
  currency: Currencies;
  availableBalance: {
    amount: number;
    currency: Currencies;
  };
  ongoingPaymentsBalance: {
    amount: number;
    currency: Currencies;
  };
  activeExternalAccount: boolean;
  transactions: {}[];
  externalAccounts: {
    id?: string;
    internalId?: string;
    accountNumber?: string;
    routingNumber?: string;
    status?: ExternalAccountStatus;
    isVerified?: boolean;
  }[];
  statements: {
    id: string;
    createdAt: Date;
    title: string;
  }[];
  status: AccountWalletStatus;
  __typename: string;
};

export type Wallet = {
  id: string;
  name: string;
  displayName: string;
  currency: Currencies;
  statements: {
    id: string;
    createdAt: Date;
    title: string;
  }[];
  availableBalance: {
    amount: number;
    currency: Currencies;
  };
  externalAccountActive: boolean;
  __typename: string;
};

export type ExternalWalletAccount = {
  id: string;
  accountNumber: string;
  institutionNumber: string;
  transitNumber: string;
  status: string;
  __typename: string;
};
