import { useEffect, useContext } from 'react';

import { CADBankAccountOnboardingContext } from 'components/wallets/OpenCADAccountModal/contexts/CADBankAccountOnboardingContext';
import {
  useExternalAccountAgreements,
  useExternalAccountAgreementState,
} from 'components/wallets/OpenCADAccountModal/hooks';
import { UseTaxDeclarationProps } from '../CATaxDeclaration.types';

const useTaxDeclaration = ({ enableFullScreen, disableFullScreen, documentType }: UseTaxDeclarationProps) => {
  const {
    externalAccount,
    skipCATaxDeclaration,
    setSkipCATaxDeclaration,
    skipUSTaxDeclaration,
    setSkipUSTaxDeclaration,
  } = useContext(CADBankAccountOnboardingContext);
  const externalWalletAccountId = externalAccount?.id;

  const {
    agreements,
    loading: agreementsLoading,
    error: agreementsError,
  } = useExternalAccountAgreements(externalWalletAccountId);

  const {
    signingUrl,
    isAgreementSigned,
    isSigningFailed,
    error: agreementStateError,
    loading: agreementStateLoading,
  } = useExternalAccountAgreementState({
    externalWalletAccountId,
    agreements,
    selectedDocumentType: documentType,
  });

  useEffect(() => {
    enableFullScreen?.();
    return () => disableFullScreen?.();
  }, [enableFullScreen, disableFullScreen]);

  return {
    signingUrl,
    isCompleted: isAgreementSigned,
    isError: !signingUrl || !!agreementsError || !!agreementStateError || isSigningFailed,
    isLoading: agreementsLoading || agreementStateLoading,
    skipCATaxDeclaration,
    setSkipCATaxDeclaration,
    skipUSTaxDeclaration,
    setSkipUSTaxDeclaration,
  };
};

export default useTaxDeclaration;
