import React from 'react';

import config from 'config';
import { Loaders } from 'components/cards/Loader';
import { PADContentProps } from './PADContent.types';

const PADContent = ({
  payorEmail,
  payorBusinessName,
  payorRepresentativeName,
  signInDate,
  payeeName,
  payeeEmail,
  payeeAddress,
  payorBankName,
  payorBankAccountNumber,
  payorBankRoutingNumber,
  payorBankInstitutionNumber,
  payorBankTransitNumber,
  loading,
  isUSBankAccount,
}: PADContentProps) => {
  if (loading) return <Loaders.Light />;

  if (isUSBankAccount) {
    return (
      <>
        <h3 className="tw-mb-4 tw-text-center">Authorization Agreement for Preauthorized Payments via ACH Debit</h3>
        <div>Date: {signInDate}</div>
        <div>Authorized Signer: {payorRepresentativeName}</div>
        <div className="tw-text-sm tw-my-4">
          In accordance with the agreement (the “Terms”) I have with <b>{payeeName}</b>, I hereby authorize{' '}
          <b>{payeeName}</b> to initiate electronic funds transfer debits from my bank account listed below in order to
          to pay all fees charges and other amounts owed to the <b>{payeeName}</b> pursuant to the Terms and, if
          necessary, to initiate adjustments for any transactions debited in error. I understand that <b>{payeeName}</b>{' '}
          will debit my account for the full amount of the services each time a payment request is issued to me by the
          payor using the Loop platform. I agree that ACH transactions I authorize comply with all applicable law.
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
          <h5 className="tw-font-semibold">Payee’s Information</h5>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Full Legal Name: </div>
            <div className="tw-w-1/2">{payeeName}</div>
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Street Address: </div>
            <div className="tw-w-1/2">{payeeAddress}</div>
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Email Address: </div>
            <div className="tw-w-1/2">{payeeEmail}</div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
          <h5 className="tw-font-semibold">Payor’s Information</h5>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Full Legal Business Name: </div>
            <div className="tw-w-1/2">{payorBusinessName}</div>
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Email Address: </div>
            <div className="tw-w-1/2">{payorEmail}</div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
          <h5 className="tw-font-semibold">Payor’s Bank Account Information</h5>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Name of Bank: </div>
            <div className="tw-w-1/2">{payorBankName}</div>
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Routing Number: </div>
            <div className="tw-w-1/2">{payorBankRoutingNumber}</div>
          </div>
          <div className="tw-flex tw-flex-row tw-justify-between">
            <div className="tw-w-1/2">Account Number: </div>
            <div className="tw-w-1/2">{payorBankAccountNumber}</div>
          </div>
        </div>
        <div className="tw-text-sm">
          I understand that this authorization will remain in full force and effect until I notify the Payor via email,
          with a copy sent to {config.paymentsEmailAddress}, that I wish to revoke this authorization and I agree to
          notify <b>{payeeName}</b> at least 15 days in advance to any changes.
        </div>
      </>
    );
  }

  // CAD PAD Agreement
  return (
    <>
      <h3 className="tw-mb-4 tw-text-center">This Pre-Authorized Debit (PAD) Agreement is for Business Purposes</h3>
      <div>Date: {signInDate}</div>
      <div>Authorized Signer: {payorRepresentativeName}</div>
      <div className="tw-text-sm tw-my-4">
        In accordance with the agreement (the “Terms”) the Payor has with {payeeName}, I hereby authorize Loop Payments
        Inc. to initiate electronic funds transfer debits from my bank deposit account listed below in order to pay all
        fees, charges and other amounts owed to {payeeName} pursuant to the Terms and, if necessary, to initiate
        adjustments for any transactions debited in error. I understand that Loop Payments Inc. is acting as a payment
        service provider for {payeeName} and will debit my account for the full amount of the services each time a
        payment request is issued to me using the Loop platform and I understand that Loop’s name may appear in the
        Payor’s banking information as the debitor of the PAD. I agree that EFT transactions I authorize comply with all
        applicable law. I confirm that I have the authority to authorize this PAD Agreement.
      </div>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
        <h5 className="tw-font-semibold">Loop’s Information</h5>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Full Legal Name: </div>
          <div className="tw-w-1/2">Loop Financial Inc.</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Street Address: </div>
          <div className="tw-w-1/2">410 Adelaide St W, Toronto ON M5V1S8</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Email Address: </div>
          <div className="tw-w-1/2">{config.padAgreementEmailAddress}</div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
        <h5 className="tw-font-semibold">Biller’s Information</h5>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Full Legal Name: </div>
          <div className="tw-w-1/2">{payeeName}</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Street Address: </div>
          <div className="tw-w-1/2">{payeeAddress}</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Email Address: </div>
          <div className="tw-w-1/2">{payeeEmail}</div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
        <h5 className="tw-font-semibold">Payor’s Information</h5>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Full Legal Business Name: </div>
          <div className="tw-w-1/2">{payorBusinessName}</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Email Address: </div>
          <div className="tw-w-1/2">{payorEmail}</div>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
        <h5 className="tw-font-semibold">Payor’s Deposit Account Information</h5>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Name of Bank (the “Processing Institution”): </div>
          <div className="tw-w-1/2">{payorBankName}</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Branch Transit Number: </div>
          <div className="tw-w-1/2">{payorBankTransitNumber}</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Processing Institution Number: </div>
          <div className="tw-w-1/2">{payorBankInstitutionNumber}</div>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-w-1/2">Deposit Account Number: </div>
          <div className="tw-w-1/2">{payorBankAccountNumber}</div>
        </div>
      </div>
      <div className="tw-mt-8">
        <h5 className="tw-font-semibold">Change of Account:</h5>
        <div className="tw-text-sm tw-my-4">
          In the event of a change in the information of the Payor’s deposit account, the Payor undertakes to notify
          Loop Payments Inc. of such change in writing at least fifteen (15) business days before the date of the next
          scheduled payment and provide it with the information of its new deposit account.
        </div>
      </div>
      <div className="tw-mt-2">
        <h5 className="tw-font-semibold">Waiver to Pre-Notification:</h5>
        <div className="tw-text-sm tw-my-4">
          YOU HEREBY WAIVE ALL OF THE RIGHTS YOU MAY HAVE TO BE NOTIFIED PRIOR TO A DEBIT FROM YOUR DEPOSIT ACCOUNT FOR
          ANY REGULAR, VARIABLE OR SPORADIC AMOUNT, INCLUDING ANY RECURRING PAYMENTS AND ANY NSF FEE OR LATE PAYMENT
          FEE.
        </div>
      </div>
      <div className="tw-mt-2">
        <h5 className="tw-font-semibold">Recourse/Reimbursement Rights:</h5>
        <div className="tw-text-sm tw-my-4">
          The Payor has certain recourse rights if any debit does not comply with this PAD Agreement. For example, the
          Payor has the right to receive reimbursement for any debit that is not authorized or is not consistent with
          this PAD Agreement. To obtain more information on the Payor’s recourse rights, the Payor may contact its
          Processing Institution or visit <a href="www.cdnpay.ca">www.cdnpay.ca</a>.
        </div>
      </div>
      <div className="tw-mt-2">
        <h5 className="tw-font-semibold">Cancellation Rights:</h5>
        <div className="tw-text-sm tw-my-4">
          This authority is to remain in effect until Loop Payments Inc. has received written notification from me/us of
          its change or termination. This PAD Agreement may be canceled at any time provided that notice in writing is
          received by Loop Payments Inc. no later than thirty (30) days before the next scheduled debit. To obtain a
          sample cancelation form, or for more information on the Payor’s right to cancel this PAD Agreement, contact
          the Payor’s Processing Institution or visit <a href="www.cdnpay.ca">www.cdnpay.ca</a>.
        </div>
        <div className="tw-text-sm tw-my-4">
          Should the Payor elect to cancel this PAD Agreement, the Payor must make other arrangements to the
          satisfaction of {payorBusinessName} for the payments to be made under its agreement with {payeeName}.
        </div>
      </div>
      <div className="tw-mt-2">
        <h5 className="tw-font-semibold">Jurisdiction:</h5>
        <div className="tw-text-sm tw-my-4">
          Any legal action or proceeding arising out of or relating to this Agreement shall be instituted in the courts
          of the Province of Ontario, and the parties hereby submit to the exclusive jurisdiction of such courts.
        </div>
      </div>
    </>
  );
};

export default PADContent;
