import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { centsFromMoneyString, formatMoneyV2 } from 'utility/currency';
import { Currencies } from 'constants/currencies';
import { REIMBURSEMENT_CATEGORIES } from '../constants';
import { StepsProps } from '../../Steps.types';
import { CreateReimbursementRequestContext } from 'components/Approvals/ApprovalsContent/components/ReimbursementRequestModal/context/CreateReimbursementRequest';
import { CreateReimbursementRequestDataType } from 'components/Approvals/ApprovalsContent/components/ReimbursementRequestModal/CreateReimbursementRequest.types';

export const useAddReimbursementRequestDetails = ({ onNextStep }: Pick<StepsProps, 'onNextStep'>) => {
  const DEFAULT_CURRENCY = Currencies.CAD;

  const { setReimbursementRequestInfo, reimbursementRequestInfo, setShowAmountInCAD, showAmountInCAD } = useContext(
    CreateReimbursementRequestContext
  );

  const form = useForm({
    defaultValues: {
      originalAmountCurrency: reimbursementRequestInfo?.originalAmount?.currency || Currencies.CAD,
      amount: formatMoneyV2(reimbursementRequestInfo?.originalAmount),
      taxAmount: formatMoneyV2(reimbursementRequestInfo?.taxAmount),
      amountInCAD: formatMoneyV2(reimbursementRequestInfo?.amountRequested),
      transactionDate: reimbursementRequestInfo?.transactionDate,
      merchantName: reimbursementRequestInfo?.merchantName,
      description: reimbursementRequestInfo?.description,
      category: reimbursementRequestInfo?.category,
      blobSignedId: reimbursementRequestInfo?.blobSignedId,
    },
  });

  const { handleSubmit, register, watch } = form;

  const watchOriginalAmountCurrency = watch('originalAmountCurrency') as Currencies;

  const [formattedAmountRequested, setFormattedAmountRequested] = useState(
    formatMoneyV2({ amount: 0, currency: DEFAULT_CURRENCY })
  );
  const [formattedOriginalAmount, setFormattedOriginalAmount] = useState(
    formatMoneyV2({ amount: 0, currency: DEFAULT_CURRENCY })
  );
  const [formattedTaxAmount, setFormattedTaxAmount] = useState(
    formatMoneyV2({ amount: 0, currency: watchOriginalAmountCurrency })
  );

  const [blobSignedId, setBlobSignedId] = useState<string | null>(null);

  const originalAmountCurrencyOptions = Object.values(Currencies).map((currency) => ({
    value: currency,
    name: currency,
  }));

  const categoryOptions = Object.values(REIMBURSEMENT_CATEGORIES).map((category) => ({
    value: category,
    name: category,
  }));

  const handleOnNextStep = (data: CreateReimbursementRequestDataType) => {
    setReimbursementRequestInfo({
      merchantName: data.merchantName,
      transactionDate: data.transactionDate,
      category: data.category,
      requestType: 'reimbursement',
      description: data.description || '',
      amountRequested: { amount: centsFromMoneyString(formattedAmountRequested), currency: DEFAULT_CURRENCY },
      originalAmount: { amount: centsFromMoneyString(formattedOriginalAmount), currency: watchOriginalAmountCurrency },
      taxAmount: { amount: centsFromMoneyString(formattedTaxAmount), currency: watchOriginalAmountCurrency },
      blobSignedId: data.blobSignedId || '',
    });
    setShowAmountInCAD(watchOriginalAmountCurrency !== Currencies.CAD);
    onNextStep();
  };

  const showAmountInCadField = watchOriginalAmountCurrency !== Currencies.CAD;

  useEffect(() => {
    const originalAmount = centsFromMoneyString(formattedOriginalAmount);
    if (!showAmountInCadField) {
      setFormattedAmountRequested(formatMoneyV2({ amount: originalAmount, currency: watchOriginalAmountCurrency }));
    } else {
      setFormattedOriginalAmount(formatMoneyV2({ amount: originalAmount, currency: watchOriginalAmountCurrency }));
    }
    setShowAmountInCAD(watchOriginalAmountCurrency !== Currencies.CAD);
  }, [watchOriginalAmountCurrency, formattedOriginalAmount, formattedAmountRequested]);

  useEffect(() => {
    const amount = centsFromMoneyString(formattedAmountRequested);
    setFormattedAmountRequested(formatMoneyV2({ amount, currency: Currencies.CAD }));
  }, [formattedAmountRequested]);

  useEffect(() => {
    const taxAmount = centsFromMoneyString(formattedTaxAmount);
    setFormattedTaxAmount(formatMoneyV2({ amount: taxAmount, currency: watchOriginalAmountCurrency }));
  }, [formattedTaxAmount, watchOriginalAmountCurrency]);

  return {
    form,
    handleSubmit,
    handleOnNextStep,
    register,
    formattedAmountRequested,
    setFormattedAmountRequested,
    formattedOriginalAmount,
    setFormattedOriginalAmount,
    originalAmountCurrency: watchOriginalAmountCurrency,
    formattedTaxAmount,
    setFormattedTaxAmount,
    originalAmountCurrencyOptions,
    categoryOptions,
    showAmountInCAD,
    blobSignedId,
    setBlobSignedId,
  };
};
export default useAddReimbursementRequestDetails;
