import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';

import Button from 'components/Button';
import { Checkbox, ErrorLabel } from 'components/FormFields/v2';
import config from 'config';

const Agreements = ({
  onPreviousStep,
  onNextStep,
  loading,
  error,
}: {
  onPreviousStep: () => void;
  onNextStep: () => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}) => {
  const [agreementChecked, setAgreementChecked] = useState(false);

  const checkAgreement = () => {
    setAgreementChecked(!agreementChecked);
  };

  return (
    <div className="tw-max-h-screen tw-overflow-y-scroll">
      <div className="tw-px-8 tw-mb-8">
        <p className="tw-my-4">
          <strong>US Dollar Account Agreements:</strong>
        </p>
        <p className="tw-my-4 tw-px-8">
          <ul className="tw-list-disc">
            <li className="tw-mb-1 tw-underline">
              <a href={config.usAccountLegalUrl} target="_blank" rel="noopener noreferrer">
                <small>Loop US Dollar Business Account Agreement</small>
              </a>
            </li>
            <li className="tw-mb-1 tw-underline">
              <a href={config.baseLegalUrl} target="_blank" rel="noopener noreferrer">
                <small>Terms & Conditions</small>
              </a>
            </li>
            <li className="tw-mb-1 tw-underline">
              <a href={config.privacyPolicyUrl} target="_blank" rel="noopener noreferrer">
                <small>Privacy Policy</small>
              </a>
            </li>
          </ul>
        </p>
        <Checkbox
          name="agreement"
          label={
            <div className="tw-flex tw-cursor-pointer tw-text-sm">
              I have read and agree to the above agreements and disclosures.
            </div>
          }
          onChange={checkAgreement}
        />
        {error && !Array.isArray(error) && (
          <div className="tw-py-4">
            <ErrorLabel
              className="tw-px-8"
              error={error.message ? error : { message: 'Something went wrong. Please try again later.' }}
            />
          </div>
        )}
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between">
        <Button
          onClick={onPreviousStep}
          className="tw-bg-neutral-light tw-border tw-border-neutral-grey-3 tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-mb-4 lg:tw-mb-0 tw-w-full lg:tw-w-32"
        >
          Back
        </Button>

        <Button
          primary
          className="tw-w-full lg:tw-w-40"
          isDisabled={!agreementChecked}
          isFetching={loading}
          onClick={onNextStep}
        >
          {loading ? 'Submitting...' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default Agreements;
