export const loadFromLocalStorage = <T>(key: string): T | null => {
  try {
    const storedValue = localStorage.getItem(key) || '';
    return JSON.parse(storedValue);
  } catch (err) {
    console.warn(`Error loading key "${key}" from local storage: `, err);
    return null;
  }
};

export const saveToLocalStorage = <T>(key: string, value: T): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error(`Error saving key "${key}" to local storage: `, err);
  }
};
