import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { GET_CREDIT_PRODUCTS, GET_GLOBAL_CREDIT_LIMIT, GET_WALLETS } from 'graphql/creditOffer';
import { GET_TRANSACTIONS_PAGE } from 'graphql/transactions';
import { GET_CREDIT_CARDS_IDS, GET_CREDIT_CARDS_FOR_LIST, GET_GROUPED_CARD_INFO } from 'graphql/cards';
import { GET_BANK_ACCOUNTS } from 'graphql/integrations';
import { KYC_STATUS, pendingTask, TRANSACTION_PRODUCT_TYPES } from 'constants/index';
import config from 'config';
import DesktopLayout from 'components/home/desktop/DesktopLayout';
import { GET_COMPLETED_ACCOUNT_GAMIFICATION_EVENTS } from 'graphql/gamification';

const DesktopHomeContainer = () => {
  const { loading: loadingCreditInfo, data: creditProductsData } = useQuery(GET_CREDIT_PRODUCTS);

  const { loading, data: transactionsData } = useQuery(GET_TRANSACTIONS_PAGE, {
    variables: {
      currencies: [],
      categories: [],
      loopProducts: [],
      products: [
        TRANSACTION_PRODUCT_TYPES.creditCard,
        TRANSACTION_PRODUCT_TYPES.wallet,
        TRANSACTION_PRODUCT_TYPES.lineOfCredit,
      ],
      page: `1`,
      numPerPage: `10`,
    },
  });

  const { transactionsPage } = transactionsData || {
    transactionsPage: {
      items: [],
      pageData: { totalPages: 0, currentPage: 0, nextPage: 0, prevPage: 0 },
    },
  };
  const { items: transactions } = transactionsPage;

  const { data: walletData, loading: walletLoading } = useQuery(GET_WALLETS);
  const { data: creditCardData, loading: creditCardLoading } = useQuery(GET_CREDIT_CARDS_FOR_LIST);
  const { data: creditCardIdsData, loading: creditCardIdLoading } = useQuery(GET_CREDIT_CARDS_IDS);
  const { data: groupedCardsData, loading: groupedCardsDataLoading } = useQuery(GET_GROUPED_CARD_INFO);
  const { data: globalCreditLimitData, loading: globalCreditLimitLoading } = useQuery(GET_GLOBAL_CREDIT_LIMIT);
  const { data: bankAccountsData, loading: bankAccountsLoading } = useQuery(GET_BANK_ACCOUNTS);
  const { data: completedEventsData, loading: completedEventsLoading } = useQuery(
    GET_COMPLETED_ACCOUNT_GAMIFICATION_EVENTS
  );
  const { me } = creditProductsData || {};

  const completedEvents = _.get(completedEventsData, 'me.account.completedGamificationEvents', []);
  const bankAccounts = _.get(bankAccountsData, 'bankInfo.bankAccounts') || [];
  const connectedBankAccount = _.get(walletData, 'me.account.bankAccount.connected');
  const bankAccountVerified = _.get(walletData, 'me.account.bankAccount.verified');
  const lineOfCredit = _.get(me, 'account.lineOfCredit');
  const globalCreditLimit = _.get(globalCreditLimitData, 'me.account.globalCreditLimit') || false;
  const creditCards = _.get(creditCardData, 'me.account.creditCards') || [];
  const creditCardIds = _.get(creditCardIdsData, 'me.account.creditCards');
  const kycAssessment = _.get(creditCardIdsData, 'me.account.kycAssessment.status') || false;

  const groupedCardsInfo = _.get(groupedCardsData, 'primaryCreditCard');
  const wallets = _.get(walletData, 'me.account.wallets');
  let pendingTasks = _.get(me, 'account.pendingTasks') || [];

  const hasLineOfCredit = !!lineOfCredit;
  const isExistingLocUser = hasLineOfCredit && creditCards.length === 0;
  const hasAtLeastOneBankAccountVerified = bankAccounts.some((ba) => ba.verified);
  const allowCardCreation =
    kycAssessment === KYC_STATUS.APPROVED &&
    hasAtLeastOneBankAccountVerified &&
    !isExistingLocUser &&
    !config.cardApiDown;

  if (!allowCardCreation) {
    pendingTasks = pendingTasks.filter((task) => task.id !== pendingTask.card);
  }

  // TODO: fetch the primary.
  const creditCard = creditCards[0];
  const loadingProducts = walletLoading || creditCardLoading || loadingCreditInfo;

  return (
    <DesktopLayout
      hasAtLeastOneBankAccountVerified={hasAtLeastOneBankAccountVerified}
      connectedBankAccount={connectedBankAccount}
      bankAccountVerified={bankAccountVerified}
      bankAccountsLoading={bankAccountsLoading}
      hasLineOfCredit={hasLineOfCredit}
      lineOfCredit={lineOfCredit}
      globalCreditLimit={globalCreditLimit}
      creditCards={creditCards || []}
      creditCardIds={creditCardIds || []}
      groupedCardsInfo={groupedCardsInfo}
      wallets={wallets || []}
      transactions={transactions || []}
      loadingCreditInfo={loadingCreditInfo}
      loadingTransactions={loading}
      pendingTasks={pendingTasks}
      creditCardLoading={creditCardLoading || creditCardIdLoading || groupedCardsDataLoading}
      walletLoading={walletLoading}
      globalCreditLimitLoading={globalCreditLimitLoading}
      allowCardCreation={allowCardCreation}
      loadingProducts={loadingProducts}
      creditCard={creditCard}
      accountCompletedEvents={completedEvents}
      loadingCompletedEvents={completedEventsLoading}
    />
  );
};

export default DesktopHomeContainer;
