import config from 'config';

const DEFAULT_ACCEPTED_DOMAINS = 'lendingloop.ca,getloop.ca,bankonloop.com';
const ACCEPTED_DOMAINS = (config.experimentEmailDomains || DEFAULT_ACCEPTED_DOMAINS).split(',');
const ACCEPTED_TESTER_EMAILS = config.experimentTesterEmails ? config.experimentTesterEmails.split(',') : [];
export const EXPERIMENT_EXCLUDED_EMAILS = config.experimentExcludedEmails.split(',');
export const ACCEPTED_DOMAINS_REGEX = RegExp(`(${[...ACCEPTED_DOMAINS, ...ACCEPTED_TESTER_EMAILS].join('|')})`);
export const ADMIN_DOMAINS_REGEX = RegExp(`(${[...DEFAULT_ACCEPTED_DOMAINS.split(',')].join('|')})`);
export const LOC_STATEMENTS_EMAILS = (config.locStatementsFF || DEFAULT_ACCEPTED_DOMAINS).split(',');
export const UPDATE_CARD_LIMIT_SETTINGS = (config.updateCardLimitSettingsFF || DEFAULT_ACCEPTED_DOMAINS).split(',');
export const XERO_BANK_FEED_ALLOWED_EMAILS = (config.xeroBankFeedFF || DEFAULT_ACCEPTED_DOMAINS).split(',');
export const EMAIL_RECEIPTS_ALLOWED_EMAILS = (config.emailReceiptsFF || DEFAULT_ACCEPTED_DOMAINS).split(',');
export const ACCOUNT_LINKED_CARDS_ALLOWED_EMAILS = (config.accountLinkedCardsFF || DEFAULT_ACCEPTED_DOMAINS).split(',');
export const CAD_BANK_ACCOUNT_ALLOWED_EMAILS = (config.cadBankAccountFF || DEFAULT_ACCEPTED_DOMAINS).split(',');

// TODO move this to Netlify
export const FEATURES_LIST = [
  {
    featureName: 'loc_statements',
    allowedUserEmails: LOC_STATEMENTS_EMAILS,
  },
  {
    featureName: 'update_card_limit_settings',
    allowedUserEmails: UPDATE_CARD_LIMIT_SETTINGS,
  },
  {
    featureName: 'xero_bank_feed',
    allowedUserEmails: XERO_BANK_FEED_ALLOWED_EMAILS,
  },
  {
    featureName: 'email_receipts',
    allowedUserEmails: EMAIL_RECEIPTS_ALLOWED_EMAILS,
  },
  {
    featureName: 'account_linked_cards',
    allowedUserEmails: ACCOUNT_LINKED_CARDS_ALLOWED_EMAILS,
  },
  {
    featureName: 'cad_bank_account',
    allowedUserEmails: CAD_BANK_ACCOUNT_ALLOWED_EMAILS,
  },
  {
    featureName: 'user_permissions',
    allowedUserEmails: ACCEPTED_TESTER_EMAILS,
  },
];
