import React from 'react';
import { FormProvider } from 'react-hook-form';
import qs from 'query-string';

import config from 'config';
import { TextField, PasswordField, SubmitButton } from 'components/FormFields/v2';
import ArrowRight from 'components/svg/ArrowRight';
import PasswordStrengthMeter from 'components/auth/PasswordStrengthMeter';
import { PASSWORD_REGEX } from 'constants/regex';
import { useForm } from './hooks';
import { UTM_KEY } from 'utility/utm';
import { isNotURL } from 'utility/validators';

const Form = () => {
  const { form, error, isSubmitting, currentPassword, register, handleSubmit, onSubmit } = useForm();

  const queryParams = qs.parse(window?.location?.search);
  const { campaign } = queryParams;
  const DEFAULT_UTM_CAMPAIGN = config.defaultPromoCampaign;
  const utmCampaign = campaign ? campaign : DEFAULT_UTM_CAMPAIGN;
  const submitRegistration = (data) => {
    window.localStorage.setItem(
      UTM_KEY,
      JSON.stringify({ utm_campaign: utmCampaign, utm_source: data.invitationCode })
    );

    onSubmit(data);
  };

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(submitRegistration)}>
        <TextField
          rootClass="tw-mb-8"
          type="text"
          name="invitationCode"
          placeholder="LOOP10203"
          defaultValue={queryParams['Invitation-Code']}
          label="Promo or Invitation Code"
          ref={register()}
          tabIndex={5}
        />
        <div className="tw-flex tw-gap-x-4 tw-mb-8">
          <TextField
            rootClass="tw-flex-1"
            name="firstName"
            label="Legal First Name"
            placeholder="John"
            defaultValue={queryParams.name}
            ref={register({
              required: true,
              validate: (value) => isNotURL(value) || 'First name cannot be a URL',
            })}
            tabIndex={1}
          />
          <TextField
            rootClass="tw-flex-1"
            name="lastName"
            label="Legal Last Name"
            placeholder="Doe"
            ref={register({
              required: true,
              validate: (value) => isNotURL(value) || 'Last name cannot be a URL',
            })}
            tabIndex={2}
          />
        </div>
        <TextField
          rootClass="tw-mb-8"
          name="legalBusinessName"
          label="Legal Business Name"
          placeholder="12345678 Canada Inc."
          ref={register({ required: true })}
          tabIndex={3}
        />
        <TextField
          rootClass="tw-mb-8"
          type="text"
          name="email"
          placeholder="example@example.com"
          label="Work Email Address"
          defaultValue={queryParams.email}
          ref={register({ required: true })}
          tabIndex={5}
        />
        <PasswordField
          rootClass="tw-mb-4"
          type="password"
          name="password"
          placeholder="∗∗∗∗∗∗"
          label="Create Password"
          ref={register({
            required: true,
            pattern: {
              value: PASSWORD_REGEX,
            },
          })}
          tabIndex={6}
        />
        <PasswordStrengthMeter password={currentPassword} showScore={false} />
        <SubmitButton type="submit" className="tw-w-full tw-mt-8" tabIndex={7} disabled={isSubmitting}>
          {isSubmitting ? (
            'Submitting...'
          ) : (
            <div className="tw-flex tw-justify-center tw-items-center">
              <p className="tw-text-neutral-light tw-mr-2">Create Account</p>
              <ArrowRight />
            </div>
          )}
        </SubmitButton>
      </form>
    </FormProvider>
  );
};

export default Form;
