import React from 'react';
import { ampTrackEvent } from 'amplitude';
import { useHistory } from 'react-router-dom';

import { ProductOnboardingCard, FirstConversionModal, FirstDepositModal, FirstPurchaseModal } from './components';
import useProductCommunicationCards from './hooks/useProductCommunicationCards';
import { Loaders } from 'components/cards/Loader';

const ProductCommunicationCards = ({
  accountCompletedEvents,
  loadingCompletedEvents,
}: {
  accountCompletedEvents: [];
  loadingCompletedEvents: boolean;
}) => {
  const {
    setActiveModalTitle,
    closeActiveModal,
    showFirstPurchaseModal,
    showFirstDepositModal,
    showFirstConversionModal,
    productContent,
  } = useProductCommunicationCards({ accountCompletedEvents });

  if (loadingCompletedEvents)
    return (
      <div className="tw-flex tw-justify-center">
        <Loaders.Spinner />
      </div>
    );

  if (!productContent.length) return null;
  const history = useHistory();

  return (
    <div className="tw-flex tw-flex tw-gap-4">
      {productContent.map(({ icon, title, subtitle, cardColor }) => {
        return (
          <ProductOnboardingCard
            key={title}
            image={icon}
            backgroundColor={cardColor}
            backgroundColorOpacity={150}
            title={title}
            titleColor="tw-text-neutral-dark"
            subtitle={subtitle}
            onClick={() => {
              ampTrackEvent(`Gamification Bar: ${title}`);
              if (title === 'Refer A Business To Loop') {
                history.push('/dashboard/rewards?tab=refer-and-earn');
              } else {
                setActiveModalTitle(title);
              }
            }}
          />
        );
      })}
      <FirstPurchaseModal show={showFirstPurchaseModal} onClose={closeActiveModal} />
      <FirstDepositModal show={showFirstDepositModal} onClose={closeActiveModal} />
      <FirstConversionModal show={showFirstConversionModal} onClose={closeActiveModal} />
    </div>
  );
};

export default ProductCommunicationCards;
