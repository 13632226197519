import React from 'react';
import { ReimbursementApprovalRequestDataType } from 'components/Approvals/ApprovalsContent/components/ApprovalRequest.types';
import { Currencies } from 'constants/currencies';
import { formatMoneyWithoutCurrencyLabel } from 'utility/currency';
import { formatDateTime } from 'utility/date';
import { Money } from 'types/payments';
import { capitalize } from 'utility/string';
import { CurrencyBadge } from 'components/Approvals/ApprovalsContent/components/UI';

const useReimbursementDetail = ({ request }: { request?: ReimbursementApprovalRequestDataType }) => {
  const {
    amountRequested,
    originalAmount,
    transactionDate,
    createdAt,
    status,
    requester,
    defaultApprover,
    merchantName,
    description,
    taxAmount,
    dateApproved,
    approver,
    receipt,
    declineReason,
  } = request || {};

  const formatAmountValue = (value?: Money<Currencies>) => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-1">
        <div className="tw-font-semibold">{formatMoneyWithoutCurrencyLabel(value)}</div>
        <div>{value?.currency}</div>
      </div>
    );
  };

  const formattedAmountRequested = formatAmountValue(amountRequested) ?? '-';
  const formattedTaxAmount = formatAmountValue(taxAmount) ?? '-';
  const formattedTransactionDate = formatDateTime(transactionDate, 'EEEE, MMMM d, yyyy');
  const formattedCreatedAt = formatDateTime(createdAt, 'EEEE, MMMM d, yyyy');
  const formattedDateApproved = dateApproved ? formatDateTime(dateApproved, 'MMMM d, yyyy, h:mm b EEEE') : '-';
  const formattedOriginalAmount = formatAmountValue(originalAmount) ?? '-';
  const formattedDateApprovedLabel = `Date ${capitalize(status)}`;
  const formattedApprovedByLabel = `${capitalize(status)} By`;
  const formattedOriginalCurrency = originalAmount && <CurrencyBadge currency={originalAmount?.currency} />;

  const showAmountInCAD = originalAmount?.currency !== Currencies.CAD;

  return {
    amountRequested,
    originalAmount,
    taxAmount,
    description,
    merchantName,
    status,
    transactionDate,
    dateApproved,
    receipt,
    requester,
    defaultApprover,
    approver,
    showAmountInCAD,
    declineReason,
    formattedCreatedAt,
    formattedAmountRequested,
    formattedTaxAmount,
    formattedTransactionDate,
    formattedDateApproved,
    formattedOriginalAmount,
    formattedOriginalCurrency,
    formattedDateApprovedLabel,
    formattedApprovedByLabel,
  };
};

export default useReimbursementDetail;
