import { useCallback, useContext, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_CREDIT_CARD, GET_CREDIT_CARDS_LIST } from 'graphql/cards';
import { toast } from 'react-toastify';
import { shortenedContactName } from 'utility/string';
import { CreateCardContext } from 'components/creditCards/components/CreateCardModal/context/CreateCardContext';
import { ampTrackEvent } from 'amplitude';

export const useCreateCard = () => {
  const { createCardFormDataRef, setCreatedCardData, me, hasNoCards } = useContext(CreateCardContext);

  const [error, setError] = useState<ApolloError | undefined>();

  const defaultContactName = shortenedContactName(me.embossedName);

  const [createCard, { loading: isSubmitting }] = useMutation(CREATE_CREDIT_CARD, {
    update: (cache, { data }) => {
      const newCard = data?.createCreditCard;

      if (!newCard) return;

      cache.updateQuery({ query: GET_CREDIT_CARDS_LIST }, (existingData) => {
        return { ...existingData, creditCards: [...(existingData?.creditCards || []), newCard] };
      });
    },
  });

  const handleCreateCard = useCallback(async () => {
    const { virtual, displayName, nameOnCard, contactId, address } = createCardFormDataRef.current || {};

    const variables =
      hasNoCards || virtual
        ? { virtual, contactId, nameOnCard, displayName }
        : { virtual, contactId, nameOnCard, displayName, address };
    try {
      const response = await createCard({
        variables,
      });
      if (response?.data?.createCreditCard) {
        setCreatedCardData(response.data.createCreditCard);
        ampTrackEvent(virtual ? 'createVirtualCard: success' : 'createPhysicalCard: success');
        toast.success('Card successfully created.');
        return { success: true };
      }
    } catch (err) {
      console.error(err);
      ampTrackEvent(virtual ? 'createVirtualCard: error' : 'createPhysicalCard: error');
      toast.error('Failed to create card. Please try again.');
      setError(err as ApolloError);
      return { success: false, error: error };
    }
  }, [defaultContactName, createCardFormDataRef.current?.virtual, error, me?.account?.name]);

  return {
    handleCreateCard,
    isSubmitting,
    error,
  };
};
