import React, { createContext, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';

import { CREATE_CORE_ACCOUNT, ACTIVATE_CORE_ACCOUNT } from 'graphql/wallets';
import { ExternalWalletAccount } from 'types/wallet';
import { CADBankAccountOnboardingContextType } from '../OpenCADAccountModal.types';

export const CADBankAccountOnboardingContext = createContext<CADBankAccountOnboardingContextType>(
  {} as CADBankAccountOnboardingContextType
);

export const CADBankAccountOpeningProvider = ({
  externalWalletAccountId,
  children,
}: {
  externalWalletAccountId?: string;
  children: React.ReactNode;
}) => {
  const [craBusinessNumber, setCraBusinessNumber] = useState<string>();
  const [memberDetails, setMemberDetails] = useState<CADBankAccountOnboardingContextType['memberDetails']>([]);
  const [externalAccount, setExternalAccount] = useState<CADBankAccountOnboardingContextType['externalAccount']>({
    id: externalWalletAccountId,
  });
  const [error, setError] = useState<ApolloError>();

  const [createCoreAccount] = useMutation<{ createCoreAccount: ExternalWalletAccount | null }>(CREATE_CORE_ACCOUNT);
  const [activateCoreAccount] = useMutation<{ activateCoreAccount: ExternalWalletAccount | null }>(
    ACTIVATE_CORE_ACCOUNT
  );
  const [skipCATaxDeclaration, setSkipCATaxDeclaration] = useState<boolean>();
  const [skipUSTaxDeclaration, setSkipUSTaxDeclaration] = useState<boolean>();

  const onCreateAccount = async ({ members }: { members: CADBankAccountOnboardingContextType['memberDetails'] }) => {
    const result = await createCoreAccount({
      variables: {
        craBusinessNumber,
        members,
      },
    });

    return result.data?.createCoreAccount?.id;
  };

  const onActivateAccount = async () => {
    if (!externalAccount) return null;

    const result = await activateCoreAccount({
      variables: {
        externalWalletAccountId: externalAccount.id,
        skippedTaxDeclaration: skipCATaxDeclaration || skipUSTaxDeclaration,
      },
    });

    return result.data?.activateCoreAccount;
  };

  return (
    <CADBankAccountOnboardingContext.Provider
      value={{
        craBusinessNumber,
        setCraBusinessNumber,
        memberDetails,
        setMemberDetails,
        externalAccount,
        setExternalAccount,
        error,
        setError,
        onCreateAccount,
        onActivateAccount,
        skipCATaxDeclaration,
        setSkipCATaxDeclaration,
        skipUSTaxDeclaration,
        setSkipUSTaxDeclaration,
      }}
    >
      {children}
    </CADBankAccountOnboardingContext.Provider>
  );
};
