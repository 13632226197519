import React, { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';

import config from 'config';
import { useUnderDevelopmentRoutes } from 'hooks';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { SideNavProps } from './SideNav.types';
import { ExpandableNavItem, SingleNavItem } from './components';
import { getSideNavItemDetails } from './SideNav.utils';
import Button, { IconAnimation, ButtonSize } from 'components/Button';
import CashOutline from 'components/svg/CashOutline';

const SideNav = ({ sidebarItems, bottomSideNavItems }: SideNavProps) => {
  const location = useLocation();

  const allowedSidebarItems = sidebarItems
    // routes which are under development and allowed only for the internal users
    .filter(({ route }) => useUnderDevelopmentRoutes({ route }).isRouteAvailable)
    // routes which are in beta and allowed only for the certain users
    .filter(({ route }) =>
      config.featureFlagRoutes.split(',').includes(route) ? useFeatureToggle().isExperimentEnabled : true
    )
    // approvals route is allowed only for the certain users
    .filter(({ route }) =>
      'dashboard/approvals' === route ? useFeatureToggle(false, 'approvals').isExperimentEnabled : true
    );

  const selectedItem = useMemo(
    () =>
      [...allowedSidebarItems, ...(bottomSideNavItems || [])].find(
        (item) =>
          new RegExp(location.pathname, 'g').test(item.route) ||
          item?.subRoutes?.some((subRoute) => new RegExp(location.pathname, 'g').test(subRoute))
      ),
    [allowedSidebarItems, location.pathname]
  );

  return (
    <div className="tw-bg-neutral-grey-5 tw-py-4 tw-pr-2 tw-w-2/12 tw-min-w-min tw-hidden lg:tw-flex tw-flex-col tw-justify-between tw-flex-shrink-0 tw-overflow-auto">
      <div>
        {allowedSidebarItems.map((item) => {
          const { isBeta, isExpandable, isFeatured, isSelected, isCallToAction } = getSideNavItemDetails({
            item,
            selectedItem,
          });

          if (isCallToAction) {
            return (
              <div className="tw-px-2 tw-pb-3 tw-mb-2" key={item.label}>
                <Link to={item.route}>
                  <Button primary className="tw-w-full" iconAnimation={IconAnimation.rollOut}>
                    {item.callToActionLabel} <BsArrowRight className="tw-ml-4" />
                  </Button>
                </Link>
              </div>
            );
          }

          return isExpandable ? (
            <ExpandableNavItem
              key={item.label}
              item={item}
              selectedSubRoute={isSelected}
              isFeatured={isFeatured}
              isBeta={isBeta}
            />
          ) : (
            <SingleNavItem
              key={item.label}
              item={item}
              isSelected={isSelected}
              isFeatured={isFeatured}
              isBeta={isBeta}
            />
          );
        })}
      </div>
      {bottomSideNavItems?.length && (
        <div>
          {bottomSideNavItems.map((item) => {
            const { isBeta, isFeatured, isSelected, isCallToAction } = getSideNavItemDetails({ item, selectedItem });

            if (isCallToAction) {
              return (
                <div className="tw-px-2 tw-pt-3 tw-mb-2" key={item.label}>
                  <Link to={item.route} onClick={item.onClick}>
                    <Button
                      primary
                      size={ButtonSize.sm}
                      className="tw-w-full tw-font-bold tw-py-2"
                      iconAnimation={IconAnimation.pulse}
                    >
                      <CashOutline className="tw-mr-4" />
                      {item.callToActionLabel}
                    </Button>
                  </Link>
                </div>
              );
            }

            return (
              <SingleNavItem
                key={item.label}
                item={item}
                isSelected={isSelected}
                isFeatured={isFeatured}
                isBeta={isBeta}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SideNav;
