import React, { memo } from 'react';
import cx from 'classnames';

import { CardsListItem } from 'components/creditCards/components/CardsList/components';
import { CardsListTableProps } from './CardsListTable.types';
import { useCardsListTable } from './hooks';
import { tableHeaders } from './constants';

const CardsListTable = ({ creditCards }: CardsListTableProps) => {
  const { creditCardsByCardholderId, isMobile } = useCardsListTable({ creditCards });

  return (
    <div className="tw-flex tw-flex-col lg:tw-w-full tw-gap-1 lg:tw-gap-4 tw-pt-4 lg:tw-pt-0 tw-px-2 lg:tw-px-0 tw--mx-4 lg:tw-mx-0 lg:tw-mb-6 tw-bg-neutral-light">
      {!isMobile && (
        <div className="tw-flex tw-gap-4 tw-bg-neutral-grey-5 tw-rounded-md tw-px-2 tw-py-3 tw-w-full">
          {tableHeaders.map(({ text, className }, index) => (
            <div key={index} className={cx('tw-text-sm tw-text-neutral-grey-1', className)}>
              {text}
            </div>
          ))}
        </div>
      )}

      {creditCardsByCardholderId.map(([, { creditCards, cardholderName, isCurrentUser }]) =>
        creditCards.map((creditCard, index) => {
          const isFirstItemInTheGroup = index === 0;
          const isLastItemInTheGroup = index === creditCards.length - 1;

          return (
            <CardsListItem
              key={creditCard.id}
              cardholderName={cardholderName}
              creditCard={creditCard}
              showCardholder={isFirstItemInTheGroup}
              showBottomBorder={isLastItemInTheGroup}
              isCurrentUser={isCurrentUser}
            />
          );
        })
      )}
    </div>
  );
};

export default memo(CardsListTable);
