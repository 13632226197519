import React from 'react';

import { parseStatementStatus } from '../../StatementBalances.utils';
import { ContentWrapper, DueDate, PendingPayment, StatementBalanceCard, StatementDetailsSkeleton } from './components';
import { StatementDetailsProps } from './StatementDetails.types';
import { STATEMENTS_ORDER } from './constants';
import { STATEMENT_BG_COLOR } from '../../constants';

const StatementDetails = ({
  statement,
  openAddFundsToBalanceModal,
  isLoading,
  isCurrentCycle,
  isDueDatePassed,
  isLatestStatement,
  ongoingAmountDueByCurrency,
  ongoingPayments,
  isAdmin,
}: StatementDetailsProps) => {
  if (isLoading) return <StatementDetailsSkeleton isCurrentCycle={isCurrentCycle} />;

  if (!statement) return <p>Unable to get statement details</p>;

  const {
    dueDate,
    endingBalanceByCurrency,
    debits,
    credits,
    previousBalanceByCurrency,
    previousStartDate,
    previousEndDate,
    nextStatementGenerationDate,
    payment,
  } = statement;

  const { status } = payment || {};

  const { className, isFullyPaid, isMinDuePaid } = parseStatementStatus({ status, isDueDatePassed });
  const cardClassName = isCurrentCycle ? STATEMENT_BG_COLOR.current_cycle : className;
  const hidePaymentDueDate = isFullyPaid || isMinDuePaid || isCurrentCycle;

  return (
    <div>
      {nextStatementGenerationDate && (
        <div className="tw-flex tw-mb-2">
          <DueDate dueDate={nextStatementGenerationDate} isCurrentCycle={isCurrentCycle} />
        </div>
      )}

      <div className="tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-gap-2 xl:tw-gap-4 tw-mb-2">
        {!hidePaymentDueDate && (
          <div className="tw-flex">
            <DueDate dueDate={dueDate} isDueDatePassed={isDueDatePassed} />
          </div>
        )}

        {!!ongoingPayments?.length && (isCurrentCycle || isLatestStatement) && (
          <>
            {ongoingPayments.map((ongoingPayments) => (
              <PendingPayment key={ongoingPayments.currency} balance={ongoingPayments} />
            ))}
          </>
        )}
      </div>

      <ContentWrapper>
        {STATEMENTS_ORDER.map((currency) => {
          const balance = endingBalanceByCurrency[currency];
          const debitBalance = debits[currency];
          const creditBalance = credits[currency];
          const prevBalance = previousBalanceByCurrency?.[currency];
          const amountDue = isLatestStatement ? ongoingAmountDueByCurrency?.[currency] : undefined;

          const isZeroBalance = !balance?.cents;
          const className = !isCurrentCycle && isZeroBalance ? STATEMENT_BG_COLOR.paid : cardClassName;

          return (
            <StatementBalanceCard
              key={currency}
              balance={balance}
              prevStartDate={previousStartDate}
              prevEndDate={previousEndDate}
              prevBalance={prevBalance}
              currency={currency}
              credit={creditBalance}
              debit={debitBalance}
              amountDue={amountDue}
              openAddFundsToBalanceModal={openAddFundsToBalanceModal}
              isCurrentCycle={isCurrentCycle}
              className={className}
              isAdmin={isAdmin}
            />
          );
        })}
      </ContentWrapper>
    </div>
  );
};

export default StatementDetails;
