import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import cx from 'classnames';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { BiArrowBack } from 'react-icons/bi';
import { IoCashOutline } from 'react-icons/io5';

import DashboardLayout from 'components/dashboard/DashboardLayout';
import OnboardingDashboardLayout from 'components/OnboardingDashboard/components/OnboardingDashboardLayout';
import Button, { IconAnimation } from 'components/Button';
import { Loaders } from 'components/cards/Loader';

import { AddPayorModal, Payors, PaymentRequests, OpenUSDAccountBanner } from './components';
import { PayorsContextProvider } from './contexts/PayorsContext';
import { useInvoices } from './hooks';
import { payorsTabs } from './constants';

const Invoices = ({ isOnboardingPreview = false }) => {
  const {
    isMobile,
    showAddPayorModal,
    onCloseAddPayorModal,
    managePayors,
    createPayors,
    handleOpenUSDAccount,
    hasUSDAccount,
    handleAddPayor,
    loading,
    wallets,
    goBack,
    viewPayorPaymentsRequested,
    viewAccountBalances,
  } = useInvoices({ isOnboardingPreview });

  const Layout = isOnboardingPreview ? OnboardingDashboardLayout : DashboardLayout;
  const canCreatePayor = managePayors || createPayors;

  const [tabs, setTabs] = useState(payorsTabs);

  useEffect(() => {
    if (viewPayorPaymentsRequested) {
      setTabs([
        ...tabs,
        {
          label: 'Requested Payments List',
          icon: <IoCashOutline />,
        },
      ]);
    }
  }, []);

  if (loading) {
    return (
      <Layout className={cx(isMobile && 'tw-px-4')}>
        <Loaders.Light />
      </Layout>
    );
  }

  return (
    <Layout className={cx(isMobile && 'tw-px-4')}>
      <div className="tw-mb-8 tw-flex tw-flex-col">
        {isMobile && <BiArrowBack size={24} className="tw-cursor-pointer tw-mb-4" onClick={goBack} />}
        <h2 className="tw-text-2xl tw-mb-4">Manage Payors</h2>
        <p className="tw-text-sm tw-text-neutral-grey-2">
          {managePayors ? 'Add or edit your payors.' : 'List of payors.'}
        </p>
      </div>

      {managePayors && !hasUSDAccount && !isOnboardingPreview && viewAccountBalances && (
        <OpenUSDAccountBanner openUSDAccount={handleOpenUSDAccount} />
      )}

      <Tab.Group>
        <div className="tw-flex tw-justify-between tw-items-center tw-pb-8 tw-border-b tw-border-neutral-grey-3">
          <Tab.List>
            {tabs.map(({ icon, label }, i) => (
              <Tab
                key={i}
                className={({ selected }) =>
                  cx(
                    'tw-bg-neutral-grey-5 tw-py-2 tw-px-6 tw-border-2 tw-rounded-md tw-inline-flex tw-items-center tw-mr-4 tw-gap-x-2',
                    selected
                      ? 'tw-border-primary-light-green tw-bg-primary-light-green tw-text-primary-dark-green hover:tw-text-primary-dark-green'
                      : 'tw-border-neutral-grey-4'
                  )
                }
                hidden={isMobile}
              >
                {icon}
                {label}
              </Tab>
            ))}
          </Tab.List>

          {canCreatePayor && (
            <Button
              className="tw-w-44"
              onClick={handleAddPayor}
              primary
              data-testid="add-payor-btn"
              iconAnimation={IconAnimation.pulse}
            >
              <IoIosAddCircleOutline className="tw-text-neutral-light tw-mr-2" />
              Add Payor
            </Button>
          )}
        </div>

        <Tab.Panels className="tw-mb-16">
          <Tab.Panel>
            <PayorsContextProvider wallets={wallets}>
              <Payors handleAddPayor={handleAddPayor} />
            </PayorsContextProvider>
          </Tab.Panel>
          {!isMobile && (
            <Tab.Panel>
              <PaymentRequests />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>

      {canCreatePayor && <AddPayorModal show={showAddPayorModal} onClose={onCloseAddPayorModal} />}
    </Layout>
  );
};

export default Invoices;
