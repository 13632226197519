import React, { useContext } from 'react';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoCashOutline } from 'react-icons/io5';
import { FiUsers, FiClock } from 'react-icons/fi';
import { Tab } from '@headlessui/react';
import { useLocation } from 'react-router-dom';

import { SCOPE } from 'constants/index';
import { TabIndexContext } from 'context/TabIndex';
import { useToggle } from 'hooks';
import useScrollViewToPosition from 'hooks/useScrollViewToPosition';
import SendMoneyContent from './SendMoneyContent';
import ViewPaymentsContent from './ViewPaymentsContent';
import ViewApprovedPaymentsContent from './ViewApprovedPaymentsContent';
import AddPayeeModal from 'components/payments/AddPayeeModal';
import Button from 'components/Button';
import PermissionChecker from 'components/PermissionChecker';
import useIsContactPermission from 'hooks/useIsContactPermission';
import { MaintenanceBanner } from 'components/UI';
import config from 'config';

const Payees = (props) => {
  const { payees, payeePayments, lineOfCredit, wallets, loading, bankAccounts } = props;

  useScrollViewToPosition();
  const { createPayeePayment, approvePayeePayment, viewPayeePayments, createPayee, isReadOnly } =
    useIsContactPermission();

  const { isOpen: showAddPayeeModal, open: onShowAddPayeeModal, close: onCloseAddPayeeModal } = useToggle();
  const query = new URLSearchParams(useLocation().search);
  const pending = query.get('pending');
  const redirect = pending === 'true' && approvePayeePayment;

  const { defaultIndex } = useContext(TabIndexContext);
  const payeeTabs = [];
  if (createPayeePayment || createPayee || isReadOnly) {
    payeeTabs.push({
      label: 'Payees',
      icon: <IoCashOutline />,
    });
  }
  if (viewPayeePayments) {
    payeeTabs.push({
      label: 'Payments History',
      icon: <FiUsers />,
    });
  }
  if (approvePayeePayment || createPayeePayment) {
    payeeTabs.push({
      label: 'Pending Approval',
      icon: <FiClock />,
    });
  }

  return (
    <div className="tw-mb-8 tw-flex tw-flex-col">
      <h2 className="tw-text-2xl tw-mb-4">Manage & Pay Payees</h2>
      <PermissionChecker scope={SCOPE.managePayments}>
        <p className="tw-text-sm tw-text-neutral-grey-2">Add new payees and send money to existing ones.</p>
        <MaintenanceBanner maintenanceMessageSource={config.currencyCloudApiDown} />
      </PermissionChecker>
      <div>
        <div>
          <Tab.Group defaultIndex={redirect ? 2 : defaultIndex}>
            <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
              <Tab.List>
                {payeeTabs.map((payeeTab, i) => (
                  <Tab
                    key={i}
                    route={payeeTab.route}
                    className={({ selected }) =>
                      `tw-bg-neutral-grey-5 tw-py-2 tw-px-6 tw-border-2 ${
                        selected
                          ? 'tw-border-primary-light-green tw-bg-primary-light-green tw-text-primary-dark-green hover:tw-text-primary-dark-green'
                          : 'tw-border-neutral-grey-4'
                      } tw-rounded-md tw-inline-flex tw-items-center tw-mr-4 tw-gap-x-2`
                    }
                  >
                    {payeeTab.icon}
                    {payeeTab.label}
                  </Tab>
                ))}
              </Tab.List>
              <PermissionChecker scope={'create_payee'} memberScope={createPayee}>
                <Button className="tw-my-8 tw-w-44" onClick={onShowAddPayeeModal} primary data-testid="add-payee-btn">
                  <IoIosAddCircleOutline className="tw-text-neutral-light tw-mr-2" />
                  Add Payee
                </Button>
              </PermissionChecker>
            </div>
            <hr />
            <Tab.Panels>
              {(createPayee || createPayeePayment || isReadOnly) && (
                <Tab.Panel>
                  <SendMoneyContent
                    payees={payees}
                    wallets={wallets}
                    bankAccounts={bankAccounts}
                    lineOfCredit={lineOfCredit}
                    loading={loading}
                    onShowAddPayeeModal={onShowAddPayeeModal}
                  />
                </Tab.Panel>
              )}
              {viewPayeePayments && (
                <Tab.Panel>
                  <ViewPaymentsContent loading={loading} payeePayments={payeePayments} />
                </Tab.Panel>
              )}
              {(approvePayeePayment || createPayeePayment) && (
                <Tab.Panel>
                  <ViewApprovedPaymentsContent
                    loading={loading}
                    payeePayments={payeePayments}
                    props={props}
                    canApprove={approvePayeePayment}
                  />
                </Tab.Panel>
              )}
            </Tab.Panels>
          </Tab.Group>
        </div>
        <AddPayeeModal show={showAddPayeeModal} onClose={onCloseAddPayeeModal} />
      </div>
    </div>
  );
};

export default Payees;
