import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';

import { GET_PAYEE_PAYMENTS, PAY_PAYEE } from 'graphql/payees';
import { MFAContext } from 'context/MFA';
import { AuthContext } from 'context/Auth';
import { cacheUpdateOptions } from 'utility/transactions';
import { Progress } from 'components/UI';
import { PayPayeeContext, PayPayeeContextProvider } from './PayPayeeContext';
import { MFARequired, VerifySMSCode, VerifyEmailCode } from './MFA';
import { Complete, Details, PADAgreement, Review } from './components';
import { UPDATE_REIMBURSEMENT_REQUEST_STATUS, GET_REIMBURSEMENT_REQUESTS } from 'graphql/reimbursementRequest';
import { PAY_PAYEE_SOURCE, PAY_PAYEE_STEPS } from './constants';
import { APPROVAL_REQUEST_STATUS } from 'components/Approvals/constants';
import { FilterRequestsContext } from 'components/Approvals/ApprovalsContent/context';

const PayPayee = (props) => {
  const { reimbursement } = props;
  const { me } = useContext(AuthContext);
  const { smsMfaEnabled, totpMfaEnabled } = me || {};

  if (!smsMfaEnabled && !totpMfaEnabled) return <MFARequired />;

  return (
    <PayPayeeContextProvider initialReimbursementInfo={reimbursement}>
      <Content {...props} totpMfaEnabled={totpMfaEnabled} />
    </PayPayeeContextProvider>
  );
};

const Content = (props) => {
  const { totpMfaEnabled } = props;
  const [step, setStep] = useState(PAY_PAYEE_STEPS.Details[0]);

  const { getSMSToken } = useContext(MFAContext);
  const token = getSMSToken();

  if (!totpMfaEnabled) {
    if (!token) return <VerifySMSCode />;
  }
  if (totpMfaEnabled) {
    if (!token) return <VerifyEmailCode />;
  }

  return (
    <>
      <Progress currentStepIndex={step} stepLabelsAndIndexes={PAY_PAYEE_STEPS} />
      <Steps step={step} setStep={setStep} {...props} />
    </>
  );
};

const Steps = (props) => {
  const { step, setStep, onFinish, lineOfCredit, source, reimbursement } = props;
  const { paymentInfo, setPaymentInfo, setPayeePayment, setError } = useContext(PayPayeeContext);
  const { page, numPerPage, period, selectedMembers, selectedCurrencies } = useContext(FilterRequestsContext);
  const { setSMSToken, getSMSToken } = useContext(MFAContext);
  const [payPayee, { loading: isSubmitting }] = useMutation(
    PAY_PAYEE,
    {
      refetchQueries: [{ query: GET_PAYEE_PAYMENTS }],
    },
    cacheUpdateOptions({ key: 'payPayee', getTransactionFromKey: (payPayee) => payPayee.transaction })
  );
  const [updateReimbursement, { loading: isUpdatingStatus }] = useMutation(UPDATE_REIMBURSEMENT_REQUEST_STATUS, {
    refetchQueries: [
      {
        query: GET_REIMBURSEMENT_REQUESTS,
        variables: {
          status: APPROVAL_REQUEST_STATUS.pending,
          page: `${page}`,
          numPerPage: `${numPerPage}`,
          startDate: period?.from,
          endDate: period?.to,
          members: selectedMembers.map((member) => member.id),
          currencies: selectedCurrencies,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const updateReimbursementStatus = async () => {
    const reimbursementResult = await updateReimbursement({
      variables: { expenseReimbursementId: reimbursement.id, status: APPROVAL_REQUEST_STATUS.paid },
    });
    if (reimbursementResult.data?.updateReimbursementRequest?.status !== APPROVAL_REQUEST_STATUS.paid) {
      setError('Error updating reimbursement status, please approve the request manually.');
    }
  };

  const onPayPayee = async () => {
    setError('');
    const authorizationToken = getSMSToken();
    if (!authorizationToken) return setSMSToken(null); // If SMS token expired, set context to null to prompt MFA dialog

    const {
      fromAccount,
      toAccount,
      reason,
      notes,
      originalAmount,
      sendConfirmationEmail,
      invoiceBlobSignedId,
      needsConversion,
      exchangeRateReference,
      invoiceNumber,
      invoiceDate,
    } = paymentInfo;
    const payingFromLOC = lineOfCredit && lineOfCredit.id === fromAccount.id;
    const variables = {
      from: payingFromLOC ? null : fromAccount.id,
      payeeId: toAccount.id,
      reason,
      notes,
      authorizationToken,
      originalPayment: originalAmount,
      sendConfirmationEmail: sendConfirmationEmail === 'true',
      blobSignedId: invoiceBlobSignedId,
      invoiceNumber,
      invoiceDate,
    };
    if (needsConversion) variables.exchangeRateReference = exchangeRateReference;

    try {
      const response = await payPayee({ variables });
      if (response && response.data.payPayee) {
        if (source === PAY_PAYEE_SOURCE.reimbursements) {
          await updateReimbursementStatus();
        }

        setPayeePayment(response.data.payPayee);
        setStep(PAY_PAYEE_STEPS.Complete[0]);
      } else {
        setError('Something went wrong. Please try again later.');
      }
    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  const onNewPayment = () => {
    setPaymentInfo(null);
    setStep(PAY_PAYEE_STEPS.Details[0]);
  };

  switch (step) {
    case 0:
      return <Details onNextStep={setStep} {...props} />;
    case 1:
      return <PADAgreement onNextStep={() => setStep(PAY_PAYEE_STEPS.Review[1])} />;
    case 2:
      return (
        <Review
          onPreviousStep={() => {
            setStep(PAY_PAYEE_STEPS.Details[0]);
            setError('');
          }}
          onNextStep={onPayPayee}
          loading={isSubmitting || isUpdatingStatus}
          {...props}
        />
      );
    case 3:
      return <Complete onFinish={onFinish} onNewPayment={onNewPayment} {...props} />;
    default:
      return null;
  }
};

export default PayPayee;
