import React from 'react';

import { LinkedAccountType } from 'components/creditCards/components/CreateCardModal/constants';
import AccountLinked from 'components/svg/AccountLinked';
import CardLinked from 'components/svg/CardLinked';

export const mapLinkedAccountTypeOptions = Object.values(LinkedAccountType).map((val) => {
  const optionLabel = (
    <div className="tw-flex tw-flex-row tw-gap-2 tw-mr-3 tw-items-center">
      {val === LinkedAccountType.Credit ? <CardLinked /> : <AccountLinked />}
      {val}
    </div>
  );
  return { label: optionLabel, value: val };
});
