import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';

import useSetPageTitle from 'hooks/useSetPageTitle';
import useBusinessVerification from './hooks/useBusinessVerification';
import { BUSINESS_VERIFICATION_DESCRIPTION, PRE_REVENUE_DOCUMENT_TYPES, REVENUE_DOCUMENT_TYPES } from './constants';
import Layout from '../Layout';
import Progress from '../Progress';
import FileUpload from 'components/uploader/FileUpload';
import { FormFooter } from 'components/UI';
import { TextField } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';

const BusinessVerification = () => {
  const {
    loading,
    businessVerificationLabel,
    businessVerificationTitle,
    isPreRevenue,
    form,
    register,
    handleSubmit,
    onSubmit,
    isSubmitting,
    handleOnFileUpload,
    navigateToIdVerification,
    addBankAccountLink,
  } = useBusinessVerification();

  useSetPageTitle(businessVerificationLabel);

  if (loading) return <Loaders.Light />;

  return (
    <Layout>
      <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-flex-grow">
        <Progress currentStep={6} />
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="tw-w-full xl:tw-w-2/5 tw-mt-16 tw-flex tw-flex-col tw-gap-6"
          >
            <h1>{businessVerificationTitle}</h1>
            <p>
              {isPreRevenue ? BUSINESS_VERIFICATION_DESCRIPTION.preRevenue : BUSINESS_VERIFICATION_DESCRIPTION.revenue}
            </p>
            <div className="tw-rounded-md tw-bg-secondary-pastel-yellow-50 tw-p-6">
              <ul className="tw-list-disc tw-ml-5">
                {(isPreRevenue ? PRE_REVENUE_DOCUMENT_TYPES : REVENUE_DOCUMENT_TYPES).map((type, index) => (
                  <li key={index}>{type}</li>
                ))}
              </ul>
            </div>
            <div>
              <strong>{businessVerificationTitle}</strong>
              <div className="tw-mt-4">
                <FileUpload onUpload={handleOnFileUpload} />
                <TextField type="text" name="blobSignedId" hidden={true} ref={register({ required: true })} />
              </div>
              {!isPreRevenue && (
                <div className="tw-mt-4">
                  Alternatively, if your bank statements clearly show your source of income, you can{' '}
                  <Link to={{ pathname: addBankAccountLink }} rel="noreferrer">
                    <span className="tw-font-semibold tw-text-primary-dark-green">
                      click this link to connect your bank account to Loop to verify
                    </span>
                    .
                  </Link>
                </div>
              )}
            </div>
            <FormFooter
              submitButtonLabel="Continue"
              cancelButtonLabel="Upload Later"
              onCancel={navigateToIdVerification}
              isDisabled={isSubmitting}
            />
          </form>
        </FormProvider>
      </div>
    </Layout>
  );
};

export default BusinessVerification;
