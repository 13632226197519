import React, { useContext } from 'react';
import { BasisTheoryProvider } from '@basis-theory/basis-theory-react';

import Close from 'components/svg/Close';
import { Loaders } from 'components/cards/Loader';
import { ErrorState } from 'components/creditCards/components';
import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import {
  Header,
  CardImageSection,
  CardActions,
  LinkedProducts,
  SpendingLimits,
  SpendManagement,
  CardAddressesSection,
  CardMembers,
  CardsSearch,
} from './components';
import { useCardDetails } from './hooks';

const CardDetails = () => {
  const { error, isLoading } = useContext(CardDetailsContext);
  const {
    basisTheory,
    showCardDetails,
    cardNumberRef,
    cardExpiryRef,
    cardCVCRef,
    isLoadingCardDetails,
    goToCardsListPage,
  } = useCardDetails();

  if (isLoading) return <Loaders.Light />;

  if (error) return <ErrorState />;

  return (
    <div className="tw-mb-4">
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-items-end lg:items-center tw-justify-between tw-gap-4 lg:tw-gap-16 tw-mb-4">
        <div className="tw-order-2 lg:tw-order-1 tw-w-full lg:tw-w-3/5">
          <CardsSearch />
        </div>
        <button onClick={goToCardsListPage} title="Go to cards list" className="tw-order-1 lg:tw-order-2">
          <Close size="30" className="tw-cursor-pointer" />
        </button>
      </div>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-4 lg:tw-gap-16">
        <div className="tw-flex tw-flex-col tw-gap-4 lg:tw-w-3/5">
          <Header />

          <BasisTheoryProvider bt={basisTheory}>
            <CardImageSection
              isLoading={isLoadingCardDetails}
              cardNumberRef={cardNumberRef}
              cardExpiryRef={cardExpiryRef}
              cardCVCRef={cardCVCRef}
            />
          </BasisTheoryProvider>

          <CardActions showCardDetails={showCardDetails} />
          <CardAddressesSection />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-4 lg:tw-w-2/5">
          <LinkedProducts />
          <SpendingLimits />
          <SpendManagement />
          <CardMembers />
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
