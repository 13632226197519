import React from 'react';
import { PiLinkLight } from 'react-icons/pi';

const LinkedProducts = () => {
  return (
    <div className="tw-rounded-md tw-border tw-bg-neutral-light tw-border-neutral-grey-3.5 tw-px-3 lg:tw-px-4">
      <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-py-4 tw-border-b tw-border-neutral-grey-4.5">
        <div className="tw-flex tw-items-center">
          <PiLinkLight size={24} className="tw-mr-2" />
          <div className="tw-font-bold">Credit</div>
        </div>
      </div>
    </div>
  );
};

export default LinkedProducts;
