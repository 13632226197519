import React from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';

import { formatDateTime } from 'utility/date';
import { Loaders } from 'components/cards/Loader';
import DocumentTextOutline from 'components/svg/DocumentTextOutline';
import SettingsLayout from 'containers/settings/SettingsLayout';

const Agreements = (props) => {
  const { agreements, loading } = props;

  return <SettingsLayout>{loading ? <Loaders.Light /> : <AgreementsList agreements={agreements} />}</SettingsLayout>;
};

const AgreementsList = ({ agreements }) => {
  const availableAgreements =
    agreements &&
    agreements.filter(
      (agreement) =>
        agreement.documentType === 'us_bank_account_agreement' ||
        agreement.documentType === 'electronic_agreement' ||
        agreement.documentType === 'card_holder_agreement'
    );

  return (
    <div className="tw-py-8 tw-space-y-8 tw-w-3/5">
      <h5>Your account agreements</h5>
      {availableAgreements && availableAgreements.length > 0 ? (
        availableAgreements.map((agreement) => (
          <AgreementLinks
            key={agreement.documentType}
            label={agreement.name}
            url={agreement.url}
            signedAt={agreement.signedAt}
            signedBy={agreement.signedBy}
          />
        ))
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

const AgreementLinks = ({ label, url, signedBy, signedAt }) => {
  return (
    <div className="tw-rounded-md tw-border tw-border-neutral-grey-3 tw-flex tw-items-center tw-justify-between tw-p-4">
      <div className="tw-flex tw-items-center tw-space-x-4">
        <DocumentTextOutline />
        <div className="tw-flex tw-flex-col">
          <strong>{label}</strong>
          <span className="tw-text-xs">
            Signed on {formatDateTime(signedAt, 'dd-MM-yyyy')} | Signed By {signedBy}
          </span>
        </div>
      </div>
      <a className="tw-cursor-pointer tw-text-primary-dark-green" target="_blank" rel="noopener noreferrer" href={url}>
        <div className="tw-flex tw-space-x-2 tw-items-center">
          <strong>Open</strong>
          <BsBoxArrowUpRight />
        </div>
      </a>
    </div>
  );
};

const EmptyState = () => <p>There are no agreements that you can download at the moment.</p>;

export default Agreements;
