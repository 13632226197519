import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Loaders } from 'components/cards/Loader';
import { RadioField, Select, SubmitButton } from 'components/FormFields/v2';
import useCardSetup from './hooks/useCardSetup';
import config from 'config';
import { ProgressBar } from 'components/UI';
import { SettlementType } from 'components/creditCards/components/CreateCardModal/constants';
import { CardImage } from './components';
import SettlementBanner from './components/SettlementBanner/SettlementBanner';

const CardSetup = () => {
  const {
    register,
    form,
    linkAccountOptions,
    cardTypeOptions,
    settlementOptions,
    shouldDisplaySettlementOptions,
    selectedSettlementType,
    selectedAtlasCard,
    selectedCardImage,
    selectableAccountOptions,
    handleCardTypeChange,
    handleSubmit,
    handleOnNextStep,
    isAccountLinkedEnabledLoading,
    currentStep,
    steps,
    shouldDisplayAtlasCard,
    hasNoCards,
    isMember,
  } = useCardSetup();

  return (
    <FormProvider {...form}>
      <form
        className="tw-w-full tw-h-full tw-flex tw-flex-col tw-flex-grow tw-items-center"
        onSubmit={handleSubmit(handleOnNextStep)}
      >
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBar className="tw-mb-4" currentStepIndex={currentStep} stepLabelsAndIndexes={steps} />
          <CardImage type={selectedCardImage} onChange={handleCardTypeChange} isAtlas={selectedAtlasCard} />
          {hasNoCards && !isMember && (
            <small className="tw-text-neutral-grey-2">
              The first Loop Card you create needs to be virtual. After your first virtual card is created, you'll be
              able to order a physical card.
            </small>
          )}
          <div>
            <div className="tw-flex tw-flex-col tw-mt-2">
              <label htmlFor="cardType" className="tw-text-sm tw-text-primary-dark-green">
                Select Card
              </label>
              <RadioField
                optionWrapperClass="tw-w-full"
                label="Select Card Type"
                name="cardType"
                options={cardTypeOptions}
                ref={register({ required: true })}
              />
            </div>

            {shouldDisplayAtlasCard && (
              <div className="tw-grid tw-grid-cols-2 tw-gap-7 tw-justify-end tw-text-xs">
                <div className="tw-flex tw-justify-end tw-items-center tw-col-span-1">
                  <label className="tw-mr-3 tw-mb-0.5" htmlFor="atlasCardCheck">
                    <small>Make this your </small>
                    <small className="tw-underline">
                      <a target="_blank" href={'#'} rel="noopener noreferrer">
                        {/* TODO: placeholder link - link should be directed to an about page about the atlas card */}
                        Atlas Card
                      </a>
                    </small>
                  </label>
                  <input
                    id={'atlasCardCheck'}
                    className=""
                    type="checkbox"
                    name="atlasCardCheck"
                    ref={register({ required: false })}
                  />
                </div>
              </div>
            )}
          </div>

          {isAccountLinkedEnabledLoading ? (
            <Loaders.Spinner />
          ) : (
            <div className="tw-flex tw-flex-col">
              <label htmlFor="linkedAccount" className="tw-text-sm tw-text-primary-dark-green">
                Linked Account
              </label>
              <RadioField
                optionWrapperClass="tw-w-full"
                label="Linked Account"
                name="linkedAccount"
                options={linkAccountOptions}
                ref={register({ required: true })}
              />
            </div>
          )}

          {shouldDisplaySettlementOptions && (
            <div className="tw-flex tw-flex-col">
              <label htmlFor="settlementType" className="tw-text-sm tw-text-primary-dark-green">
                Settlement Type
              </label>
              <RadioField
                optionWrapperClass="tw-w-full"
                label="Select Settlement Type"
                name="settlementType"
                options={settlementOptions}
                ref={register({ required: true })}
              />
            </div>
          )}
          {selectedSettlementType === SettlementType.SingleCurrency && (
            <div className="tw-flex tw-flex-col">
              <label htmlFor="selectedWalletId" className="tw-text-sm tw-text-primary-dark-green">
                Select Account
              </label>
              <Select
                name="selectedWalletId"
                rootClass="tw-max-w-sm"
                options={selectableAccountOptions}
                ref={register({ required: true })}
                placeholder="Select Account"
                unselected
              />
              <span className="tw-text-sm tw-text-semantic-error">Note: Cannot be changed after card creation.</span>
            </div>
          )}

          {selectedSettlementType === SettlementType.MultiCurrency && (
            <div>
              <SettlementBanner
                content={
                  'If funds are unavailable in the purchase currency, the transaction will charged in another available currency in this order: CAD > USD > EUR > GBP. If the total transaction amount is unavailable in any single currency, the transaction will be rejected.'
                }
              />
            </div>
          )}
        </div>

        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-py-8 tw-rounded-b-md tw-justify-center tw-overflow-hidden tw-flex tw-flex-row tw-mt-auto">
          <div className="lg:tw-w-1/2 tw-px-4 lg:tw-px-0 tw-flex tw-items-center tw-justify-between tw-align-items-center">
            <div className="tw-flex tw-items-start tw-justify-start tw-flex-wrap">
              <span>By Continuing, you agree to</span>&nbsp;
              <a
                href={config.cardHolderAgreementUrl}
                className="tw-text-primary-dark-green hover:tw-text-primary-dark-green tw-font-bold tw-border-b-2"
                rel="noopener noreferrer"
                target="_blank"
              >
                Loop Access Card Agreement
              </a>
            </div>
            <SubmitButton className="tw-ml-2">Next Step</SubmitButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default CardSetup;
