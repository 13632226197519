// appStage:  staging | production
const staging = {
  graphUrl: 'https://api.staging.getloop.ca/graphql',
  rollbarAccessToken: '1ddae1d330cc4847af68620bd0a1a373',
  env: 'staging',
  coreAppUrl: 'https://api.staging.getloop.ca',
  amplitudeAPIKey: 'XXX',
  sentryDSN: 'https://40a7f9a68f374629bafecb268ce64869@o161107.ingest.sentry.io/5458552',
  facebookAppId: '1248402045526434',
  facebookPixelId: 'XXX',
  googleClientId: '714417746590-ff711skjl149bkv54u24k01ume5t7bj4.apps.googleusercontent.com',
  shopifyClientId: 'dbff985e8d990605a49cfe9559d56af4',
  awsRegion: 'ca-central-1',
  awsUserPoolId: 'ca-central-1_DsSA4QdWF',
  awsUserPoolWebClientId: '6eqlbkkcs2cr4ug60e2drikh02',
  googleAddressAPIKey: 'XXX',
  maintenance: 'off',
  creditCardMaintenance: 'off',
  airtableAPIKey: 'XXX',
  airtableAPIUrl: 'https://api.airtable.com/v0/app3SnJjcHVANzEAt/Waiting%20List',
  reportCardTypeFormUrl: 'https://form.typeform.com/to/zMpTmjpl',
  typeformSalesChannelID: 's0bUgka6',
  disputeTransactionBaseUrl:
    'https://form.typeform.com/to/ZD5PL7Qk?utm_source=xxxxx&utm_medium=xxxxx&utm_campaign=xxxxx&utm_term=xxxxx&utm_content=xxxxx',
  gtmId: 'GTM-NFW7STR',
  baseLegalUrl: 'https://www.bankonloop.com/legal',
  privacyPolicyUrl: 'https://www.bankonloop.com/legal?tab=Privacy',
  platformAgreementUrl: 'https://www.bankonloop.com/legal?tab=Platform-Agreement',
  cardBasedCreditFacilityTermsUrl:
    'https://loop-public.s3.ca-central-1.amazonaws.com/agreements/Loop+Card-Based+Credit+Facility+Terms+and+Conditions.pdf',
  walletAgreementUrl:
    'https://loop-public.s3.ca-central-1.amazonaws.com/agreements/Loop+Card-Based+Credit+Facility+Terms+and+Conditions.pdf',
  experimentEmailDomains: 'bankonloop.com,lendingloop.ca,getloop.ca',
  experimentExcludedEmails: '',
  experimentTesterEmails: '',
  localEncryptionKey: 'secret key 123',
  bankingEncryptionKey: '0575b7113789544f05455c1f98e3c102c8a1f5681c2551aa8ce1693de38815a7',
  virtualCreditCardLimit: 5,
  physicalCreditCardLimit: 2,
  supportEmailAddress: 'support@bankonloop.com',
  contactEmailAddress: 'contact@bankonloop.com',
  complaintsEmailAddress: 'complaints@bankonloop.com',
  legalEmailAddress: 'legal@bankonloop.com',
  padAgreementEmailAddress: 'pad@bankonloop.com',
  paymentsEmailAddress: 'payments@bankonloop.com',
  verificationEmail: 'verification@bankonloop.com',
  landingPageUrl: 'https://bankonloop.com',
  cardApiDown: '',
  currencyCloudApiDown: '',
  multiCurrencyAccountAgreement: 'https://www.bankonloop.com/legal?tab=Multi-Currency-Account-Agreement',
  multiCurrencyAccountAgreementCA: 'https://www.bankonloop.com/legal?tab=Multi-Currency-Account-Agreement',
  solidApiDown: '',
  usAccountLegalUrl:
    'https://loop-public.s3.ca-central-1.amazonaws.com/agreements/Loop+US+Dollar+Account+Agreement.pdf',
  electronicsCommunicationAgreement:
    'https://loop-public.s3.ca-central-1.amazonaws.com/agreements/Loop+Electronic+Communications+Agreement.pdf',
  rutterPublicKey: 'sandbox_pk_4d5a4e6d-43a9-4921-8dc2-dfacdfc789ed',
  featuredSideNavEntries: '',
  betaSideNavEntries: '',
  showRewards: false,
  squareClientId: 'sandbox-sq0idb-6R14es1r048OmVeoHsxe4g',
  squareBaseSite: 'squareupsandbox',
  year: new Date().getFullYear(),
  enableWalletStatements: false,
  paidSubscriptionRoutes: '',
  underDevelopmentRoutes: '',
  featureFlagRoutes: '',
  fxCompareUrl: 'https://forms.getloop.ca/fx_rates',
  cardHolderAgreementUrl: 'https://www.bankonloop.com/legal?tab=Cardholder-Agreement',
  updateCardLimitSettingsFF: process.env.REACT_APP_UPDATE_CARD_LIMIT_SETTINGS_FF || 'getloop.ca',
  locStatementsFF: process.env.REACT_APP_LOC_STATEMENTS_FF || 'getloop.ca',
  approvalsFF: process.env.REACT_APP_APPROVALS_FF || 'getloop.ca,bankonloop.com',
  xeroBankFeedFF: process.env.REACT_APP_XERO_BANK_FEED_FF || 'getloop.ca,bankonloop.com',
  emailReceiptsFF: process.env.REACT_APP_EMAIL_RECEIPTS_FF || 'getloop.ca,bankonloop.com',
  accountLinkedCardsFF: process.env.REACT_APP_ACCOUNT_LINKED_CARDS_FF || 'getloop.ca,bankonloop.com',
  cadBankAccountFF: process.env.REACT_APP_CAD_BANK_ACCOUNT_FF || 'bankonloop.com,lendingloop.ca,getloop.ca',
  defaultCardAPR: '30%',
  newUsRoutingNumber: '123206972',
  patriotActUrl: 'https://loop-public.s3.ca-central-1.amazonaws.com/agreements/USA+Patriot+Act+Notice.pdf',
  uploadCustomerFilesEmailDomain: 'loopreceipts.com',
  defaultPromoCampaign: 'DM04LP2023',
  borrowerReferralCampaign: 'In-App Referral',
  rootUrl: 'https://app.bankonloop.com',
  inAppReferralUrl: 'https://www.bankonloop.com/refer',
  domain: 'localhost',
  corporateContactFormUrl: 'https://loop-public.s3.ca-central-1.amazonaws.com/Corporate+Contact+Form.pdf',
  basisTheoryApiKey: 'key_test_us_pub_Jk79cgVytcS87H3NvMk9Gs',
  basisTheorySetPinProxyKey: 'key_test_us_proxy_YWWWk8ySNw7coPLznUZT1e',
  basisTheoryActivateCardProxyKey: 'key_test_us_proxy_LQ9K2vZCDjFPCkTUCseHYV',
};

const config = {
  graphUrl: process.env.REACT_APP_GRAPH_URL || staging.graphUrl,
  rollbarAccessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || staging.rollbarAccessToken,
  env: process.env.REACT_APP_ENV || staging.env,
  coreAppUrl: process.env.REACT_APP_CORE_APP_URL || staging.coreAppUrl,
  amplitudeAPIKey: process.env.REACT_APP_AMPLITUDE_API_KEY || staging.amplitudeAPIKey,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN || staging.sentryDSN,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || staging.facebookAppId,
  facebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID || staging.facebookPixelId,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || staging.googleClientId,
  shopifyClientId: process.env.REACT_APP_SHOPIFY_CLIENT_ID || staging.shopifyClientId,
  awsRegion: process.env.REACT_APP_AWS_REGION || staging.awsRegion,
  awsUserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || staging.awsUserPoolId,
  awsUserPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || staging.awsUserPoolWebClientId,
  googleAddressAPIKey: process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY || staging.googleAddressAPIKey,
  maintenance: process.env.REACT_APP_MAINTENANCE || staging.maintenance,
  creditCardMaintenance: process.env.REACT_APP_CARD_MAINTENANCE || staging.creditCardMaintenance,
  airtableAPIKey: process.env.REACT_APP_AIRTABLE_API_KEY || staging.airtableAPIKey,
  airtableAPIUrl: process.env.REACT_APP_AIRTABLE_API_URL || staging.airtableAPIUrl,
  reportCardTypeFormUrl: process.env.REACT_APP_REPORT_CARD_TYPE_FORM_URL || staging.reportCardTypeFormUrl,
  typeformSalesChannelID: process.env.REACT_APP_TYPEFORM_SALES_CHANNEL_ID || staging.typeformSalesChannelID,
  disputeTransactionBaseUrl: process.env.REACT_APP_DISPUTE_TRANSACTION_BASE_URL || staging.disputeTransactionBaseUrl,
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || staging.gtmId,
  baseLegalUrl: process.env.REACT_APP_BASE_LEGAL_URL || staging.baseLegalUrl,
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL || staging.privacyPolicyUrl,
  platformAgreementUrl: process.env.REACT_APP_PLATFORM_AGREEMENT_URL || staging.platformAgreementUrl,
  cardBasedCreditFacilityTermsUrl:
    process.env.REACT_APP_CARD_BASED_CREDIT_FACILITY_TERMS_URL || staging.cardBasedCreditFacilityTermsUrl,
  walletAgreementUrl: process.env.REACT_APP_WALLET_AGREEMENT_URL || staging.walletAgreementUrl,
  experimentEmailDomains: process.env.REACT_APP_EXPERIMENT_EMAIL_DOMAINS || staging.experimentEmailDomains,
  experimentExcludedEmails: process.env.REACT_APP_EXPERIMENT_EXCLUDED_EMAILS || staging.experimentExcludedEmails,
  experimentTesterEmails: process.env.REACT_APP_EXPERIMENT_EMAIL_TESTER_DOMAINS || staging.experimentTesterEmails,
  localEncryptionKey: process.env.REACT_APP_LOCAL_ENCRYPTION_KEY || staging.localEncryptionKey,
  bankingEncryptionKey: process.env.REACT_APP_BANKING_ENCRYPTION_KEY || staging.bankingEncryptionKey,
  virtualCreditCardLimit: process.env.REACT_APP_VIRTUAL_CREDIT_CARD_LIMIT || staging.virtualCreditCardLimit,
  physicalCreditCardLimit: process.env.REACT_APP_PHYSICAL_CREDIT_CARD_LIMIT || staging.physicalCreditCardLimit,
  supportEmail: process.env.REACT_APP_SUPPORT_EMAIL_ADDRESS || staging.supportEmailAddress,
  contactEmail: process.env.REACT_APP_CONTACT_EMAIL_ADDRESS || staging.contactEmailAddress,
  complaintsEmailAddress: process.env.REACT_APP_COMPLAINTS_EMAIL_ADDRESS || staging.complaintsEmailAddress,
  legalEmailAddress: process.env.REACT_APP_LEGAL_EMAIL_ADDRESS || staging.legalEmailAddress,
  padAgreementEmailAddress: process.env.REACT_APP_PAD_AGREEMENT_EMAIL_ADDRESS || staging.padAgreementEmailAddress,
  paymentsEmailAddress: process.env.REACT_APP_PAYMENTS_EMAIL_ADDRESS || staging.paymentsEmailAddress,
  verificationEmail: process.env.REACT_APP_VERIFICATION_EMAIL || staging.verificationEmail,
  landingPageUrl: process.env.REACT_APP_LANDING_PAGE_URL || staging.landingPageUrl,
  cardApiDown: process.env.REACT_APP_CARD_API_DOWN || staging.cardApiDown,
  currencyCloudApiDown: process.env.REACT_APP_CURRENCY_CLOUD_API_DOWN || staging.currencyCloudApiDown,
  multiCurrencyAccountAgreement: process.env.REACT_APP_ACCOUNT_LEGAL_URL || staging.multiCurrencyAccountAgreement,
  multiCurrencyAccountAgreementCA:
    process.env.REACT_APP_CA_ACCOUNT_LEGAL_URL || staging.multiCurrencyAccountAgreementCA,
  solidApiDown: process.env.REACT_APP_SOLID_API_DOWN || staging.solidApiDown,
  usAccountLegalUrl: process.env.REACT_APP_US_ACCOUNT_LEGAL_URL || staging.usAccountLegalUrl,
  electronicsCommunicationAgreement:
    process.env.REACT_APP_ELECTRONICS_COMMUNICATION_AGREEMENT || staging.electronicsCommunicationAgreement,
  rutterPublicKey: process.env.REACT_APP_RUTTER_PUBLIC_KEY || staging.rutterPublicKey,
  featuredSideNavEntries: process.env.REACT_APP_FEATURED_SIDENAV_ENTRIES || staging.featuredSideNavEntries,
  betaSideNavEntries: process.env.REACT_APP_BETA_SIDENAV_ENTRIES || staging.betaSideNavEntries,
  showRewards: process.env.REACT_APP_SHOW_REWARDS || staging.showRewards,
  squareClientId: process.env.REACT_APP_SQUARE_CLIENT_ID || staging.squareClientId,
  squareBaseSite: process.env.REACT_APP_SQUARE_BASE_SITE || staging.squareBaseSite,
  year: process.env.REACT_APP_YEAR || staging.year,
  enableWalletStatements: process.env.REACT_APP_ENABLE_WALLET_STATEMENTS || staging.enableWalletStatements,
  paidSubscriptionRoutes: process.env.REACT_APP_PAID_SUBSCRIPTION_ROUTES || staging.paidSubscriptionRoutes,
  underDevelopmentRoutes: process.env.REACT_APP_UNDER_DEVELOPMENT_ROUTES || staging.underDevelopmentRoutes,
  featureFlagRoutes: process.env.REACT_APP_FEATURE_FLAG_ROUTES || staging.featureFlagRoutes,
  fxCompareUrl: process.env.REACT_APP_FX_COMPARE_URL || staging.fxCompareUrl,
  cardHolderAgreementUrl: process.env.REACT_APP_CARD_HOLDER_AGREEMENT_URL || staging.cardHolderAgreementUrl,
  updateCardLimitSettingsFF: process.env.REACT_APP_UPDATE_CARD_LIMIT_SETTINGS_FF || staging.updateCardLimitSettingsFF,
  locStatementsFF: process.env.REACT_APP_LOC_STATEMENTS_FF || staging.locStatementsFF,
  approvalsFF: process.env.REACT_APP_APPROVALS_FF || staging.approvalsFF,
  xeroBankFeedFF: process.env.REACT_APP_XERO_BANK_FEED_FF || staging.xeroBankFeedFF,
  emailReceiptsFF: process.env.REACT_APP_EMAIL_RECEIPTS_FF || staging.emailReceiptsFF,
  accountLinkedCardsFF: process.env.REACT_APP_ACCOUNT_LINKED_FF || staging.accountLinkedCardsFF,
  cadBankAccountFF: process.env.REACT_APP_CAD_BANK_ACCOUNT_FF || staging.cadBankAccountFF,
  defaultCardAPR: process.env.REACT_APP_DEFAULT_CARD_APR || staging.defaultCardAPR,
  newUsRoutingNumber: process.env.REACT_APP_NEW_US_ROUTING_NUMBER || staging.newUsRoutingNumber,
  patriotActUrl: process.env.REACT_APP_PATRIOT_ACT_URL || staging.patriotActUrl,
  uploadCustomerFilesEmailDomain:
    process.env.REACT_APP_UPLOAD_CUSTOMER_FILES_EMAIL_DOMAIN || staging.uploadCustomerFilesEmailDomain,
  defaultPromoCampaign: process.env.REACT_APP_DEFAULT_PROMO_CAMPAIGN || staging.defaultPromoCampaign,
  borrowerReferralCampaign: process.env.REACT_APP_BORROWER_REFERRAL_CAMPAIGN || staging.borrowerReferralCampaign,
  rootUrl: process.env.REACT_APP_ROOT_URL || staging.rootUrl,
  inAppReferralUrl: process.env.REACT_APP_IN_APP_REFERRAL_URL || staging.inAppReferralUrl,
  domain: process.env.REACT_APP_DOMAIN || staging.domain,
  corporateContactFormUrl: process.env.REACT_APP_CORPORATE_CONTACT_FORM_URL || staging.corporateContactFormUrl,
  basisTheoryApiKey: process.env.REACT_APP_BASIS_THEORY_API_KEY || staging.basisTheoryApiKey,
  basisTheorySetPinProxyKey: process.env.REACT_APP_BASIS_THEORY_SET_PIN_PROXY_KEY || staging.basisTheorySetPinProxyKey,
  basisTheoryActivateCardProxyKey:
    process.env.REACT_APP_BASIS_THEORY_ACTIVATE_CARD_PROXY_KEY || staging.basisTheoryActivateCardProxyKey,
};

export default config;
