import React, { useState, useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { useForm, FormProvider } from 'react-hook-form';

import {
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
  GET_ADMIN_NOTIFICATION_SETTINGS,
} from 'graphql/settings';
import { TeamContext } from 'components/settings/Team/TeamContext';
import { memberFilter } from 'utility/team';
import { capitalize, formatCamelToNormalCase } from 'utility/string';
import { TEAM_MEMBER_STATUS } from 'constants/index';
import InfoTooltip from 'components/InfoTooltip';
import Button from 'components/Button';
import Modal from 'components/Modal/v2';
import Close from 'components/svg/Close';
import SuccessCheckmark from 'components/svg/SuccessCheckmark';
import { Select } from 'components/FormFields/v2';
import { Loaders } from 'components/cards/Loader';
import { getMemberTypeByRole } from 'components/creditCards/cardActions/ShareCardWithTeamMember/AddTeamMember';
import { NotificationTooltip } from 'components/settings/businessNotifications/NotificationTooltip';
import Notification from 'components/Notification';
import { NotificationAlertContext } from 'context/NotificationAlert';

const NotificationSettings = ({ settings, group = '' }) => {
  const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
  settings = JSON.parse(JSON.stringify(settings), omitTypename);

  return (
    <div className="tw-w-3/4 tw-p-4">
      <div className="tw-flex">
        <strong className="tw-w-1/2">{`${
          group === 'capital' ? 'Line of Credit' : capitalize(group)
        } Notifications`}</strong>
        <strong className="tw-w-1/2">Recipients</strong>
      </div>
      {Object.entries(settings).map(([settingsKey, settingsValue]) => {
        return (
          <NotificationBoxNew
            key={settingsKey}
            group={group}
            settingsKey={settingsKey}
            emailContacts={settingsValue.emailContacts}
          />
        );
      })}
    </div>
  );
};

const NotificationBoxNew = ({ group, settingsKey, emailContacts }) => {
  const [showAddRecipientModal, setShowAddRecipientModal] = useState(false);
  const onShowAddRecipientModal = () => setShowAddRecipientModal(true);

  const availableEmailContacts = emailContacts.filter((contact) => contact.status === TEAM_MEMBER_STATUS.active);

  return (
    <div className="tw-my-4 tw-flex">
      <div className="tw-w-1/2 tw-h-32">
        <div className="tw-flex">
          <small className="tw-mr-2">{formatCamelToNormalCase(settingsKey)}</small>
          <InfoTooltip
            testId={`notification-${group}-${settingsKey}`}
            message={NotificationTooltip(group, settingsKey)}
          />
        </div>
      </div>
      <div className="tw-w-1/2">
        {emailContacts && (
          <>
            {availableEmailContacts.map((contact) => {
              return (
                <div key={contact.id}>
                  <small>{`${contact.name} (${contact.email})`}</small>
                </div>
              );
            })}
          </>
        )}
        <Button onClick={onShowAddRecipientModal}>
          <div className="tw-p-0 tw-flex tw-items-center tw-font-bold tw-text-primary-dark-green">
            <MdOutlineChangeCircle size={16} />
            <span className="tw-ml-1 tw-text-sm">Change Recipients</span>
          </div>
        </Button>
      </div>
      <AddRecipientModal
        show={showAddRecipientModal}
        setShow={setShowAddRecipientModal}
        group={group}
        settingsKey={settingsKey}
        availableEmailContacts={availableEmailContacts}
      />
    </div>
  );
};

const AddRecipientModal = ({ show, setShow, group, settingsKey, availableEmailContacts }) => {
  const [formDataState, setFormDataState] = useState({});
  const [emailSettingsState, setEmailSettingsState] = useState(null);
  const { setNotification } = useContext(NotificationAlertContext);
  const onClose = () => setShow(false);
  const form = useForm();
  const { register, handleSubmit } = form;

  const { members, loadingMembers, getMembers } = useContext(TeamContext);
  const availableMembers = members.filter((member) => member.status === TEAM_MEMBER_STATUS.active);

  const emailContactsOptions = memberFilter(availableMembers, availableEmailContacts);

  const [updateNotificationSettings, { loading, error }] = useMutation(UPDATE_NOTIFICATION_SETTINGS, {
    refetchQueries: [
      { query: GET_ADMIN_NOTIFICATION_SETTINGS },
      { query: GET_NOTIFICATION_SETTINGS, variables: { internalContactId: formDataState.selectedContact } },
    ],
    awaitRefetchQueries: true,
  });

  const [getMemberSettings] = useLazyQuery(GET_NOTIFICATION_SETTINGS, {
    onCompleted: async (response) => {
      const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
      const updateResponse = JSON.parse(JSON.stringify(response.notificationSettings), omitTypename);

      updateResponse.notificationSettings[formDataState.groupSettingsKey][formDataState.settingsKey].email =
        emailSettingsState;

      try {
        await updateNotificationSettings({
          variables: {
            notificationSettings: [updateResponse],
          },
        });
        setNotification(
          <Notification
            Icon={SuccessCheckmark}
            message={`Recipient ${emailSettingsState === true ? 'added' : 'removed'} successfully`}
          />
        );
      } catch (error) {
        console.error(error);
      }
    },
    variables: { internalContactId: formDataState.selectedContact },
  });

  useEffect(() => {
    if (emailSettingsState !== null) {
      getMemberSettings();
    }
  }, [emailSettingsState]);

  const onAddSubmit = (data) => {
    setFormDataState(data);
    setEmailSettingsState(true);
  };

  const onRemoveSubmit = (data) => {
    setFormDataState(data);
    setEmailSettingsState(false);
  };

  useEffect(() => {
    getMembers();
  }, []);

  const MemberRow = ({ group, settingsKey, contact }) => {
    return (
      <tr>
        <td>{contact.name}</td>
        <td>{getMemberTypeByRole(contact.role)}</td>
        <td>
          <Button
            onClick={() =>
              onRemoveSubmit({
                groupSettingsKey: group,
                settingsKey: settingsKey,
                selectedContact: contact.id,
              })
            }
            primary
            className="tw-bg-semantic-error"
          >
            Remove
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <Modal show={show} onClose={onClose}>
      <div className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
        <strong>Edit Recipients</strong>
        <Close className="tw-cursor-pointer" onClick={onClose} />
      </div>
      <div className="tw-p-8">
        {loadingMembers ? (
          <div className="tw-text-center">
            <Loaders.Spinner />
          </div>
        ) : (
          <>
            <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
              <form onSubmit={handleSubmit(onAddSubmit)}>
                <div className="tw-pb-4">
                  {availableEmailContacts && (
                    <>
                      Notification Type: {formatCamelToNormalCase(settingsKey)}
                      <div className="tw-flex tw-items-end tw-gap-4 tw-mt-4">
                        <input type="hidden" name="groupSettingsKey" value={group} ref={register({ required: true })} />
                        <input
                          type="hidden"
                          name="settingsKey"
                          value={settingsKey}
                          ref={register({ required: true })}
                        />
                        <Select
                          name="selectedContact"
                          placeholder="Choose one"
                          options={emailContactsOptions.map((c) => ({ name: `${c.name} (${c.email})`, value: c.id }))}
                          ref={register({ required: true })}
                          rootClass="tw-w-1/2"
                        />
                        <Button primary isDisabled={loading}>
                          Add Recipient
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </FormProvider>
            <>
              {loading ? (
                <div className="tw-py-8 tw-text-center">
                  <Loaders.Spinner />
                </div>
              ) : (
                <div className={`${availableEmailContacts?.length > 0 && 'tw-my-4'}`}>
                  <table className="tw-table tw-table-auto tw-w-full">
                    {availableEmailContacts?.length > 0 && (
                      <thead>
                        <tr>
                          <th className="tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Name</th>
                          <th className="tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Role</th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {availableEmailContacts?.length > 0 &&
                        availableEmailContacts?.map((contact) => (
                          <MemberRow
                            key={contact.id}
                            group={group}
                            settingsKey={settingsKey}
                            contact={contact}
                            loading={loading}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </>
          </>
        )}
      </div>
      <div className="tw-flex tw-justify-end tw-px-8 tw-py-4 tw-border-t tw-border-neutral-grey-4">
        <Button secondary onClick={onClose}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default NotificationSettings;
