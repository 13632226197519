import React, { useContext } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import { AuthContext } from 'context/Auth';
import { GET_NOTIFICATION_SETTINGS } from 'graphql/settings';
import CardAndReceipt from 'components/svg/CardAndReceipt';

const SMSNotificationPromotion = () => {
  const { me } = useContext(AuthContext);
  const internalContactId = _.get(me, 'internalContact.id');

  const { loading, data } = useQuery(GET_NOTIFICATION_SETTINGS, {
    variables: { internalContactId: internalContactId },
  });

  if (loading || data?.notificationSettings?.notificationSettings?.card?.approvedCardTransaction?.sms) return null;

  return (
    <div className="tw-bg-primary-light-green tw-flex tw-justify-between tw-rounded tw-my-6 tw-px-4 tw-pt-5 tw-pb-4">
      <div className="tw-flex tw-items-center">
        <div className="tw-ml-2 tw-mr-6">
          <CardAndReceipt />
        </div>
        <div>
          <small className="tw-font-semibold tw-text-neutral-dark tw-block">Set Up Automatic Receipt Matching</small>
          <small className="tw-text-neutral-dark tw-mt-1 tw-mb-3 tw-block">
            Instantly attach receipts to your Loop Card Transactions using Loop's Automatic Receipt Matching feature.
            Enable SMS Notifications and you can simply reply with a photo of your receipt to attach it to the
            transaction automatically.
          </small>
          <div className="tw-cursor-pointer tw-text-primary-dark-green">
            <Link to="/dashboard/settings/notifications?user=true">
              <small className="tw-font-bold">
                Enable SMS Notification <BsArrowRight className="tw-inline-block" />
              </small>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SMSNotificationPromotion;
