import React, { useContext } from 'react';
import cx from 'classnames';

import { formatDateTime } from 'utility/date';
import { formatTransactionTableAmount } from 'utility/currency';
import { mapTransactionsByDate, TooltipIcon } from 'utility/transactions';
import { TransactionsContext } from 'context/Transactions';
import { TRANSACTION_STATUS } from 'constants/index';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import TransactionType from 'components/TransactionType';
import { TransactionCategory } from '.';
import CheckReceiptOutline from 'components/svg/CheckReceiptOutline';

const TransactionsTable = ({ accountNames }) => {
  const { productType, transactions } = useContext(TransactionsContext);

  const today = formatDateTime(new Date());
  const transactionsByDate = mapTransactionsByDate(transactions);

  const isCreditCardType = productType === 'credit_card';

  return (
    <table className="tw-table tw-table-auto tw-w-full">
      <thead>
        <tr>
          <th className="tw-text-sm tw-text-neutral-grey-2 tw-font-normal">Type</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">From</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal">To</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-right">Amount</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-right">Currency</th>
          <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-right">Receipt</th>
          {isCreditCardType && (
            <th className="tw-p-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal tw-text-right tw-w-52">Category</th>
          )}
        </tr>
      </thead>
      {Object.keys(transactionsByDate).map((date) => {
        const isToday = today === date;

        return (
          <TableSection
            key={date}
            date={date}
            transactions={transactionsByDate[date]}
            accountNames={accountNames}
            isToday={isToday}
          />
        );
      })}
    </table>
  );
};

const TableSection = ({ date, transactions, accountNames, isToday }) => (
  <tbody>
    <tr>
      <th className="tw-bg-neutral-grey-5 tw-py-3 tw-text-sm tw-text-neutral-grey-2 tw-font-normal" colSpan={7}>
        {isToday ? 'Today' : date}
      </th>
    </tr>
    {transactions.map((transaction) => (
      <TransactionRow key={transaction.transactionId} transaction={transaction} accountNames={accountNames} />
    ))}
  </tbody>
);

const TransactionRow = ({ transaction, accountNames }) => {
  const { setSelectedTransactionId, productType } = useContext(TransactionsContext);

  const accountName = accountNames.find((name) => name === transaction.to);
  const formattedAmount = formatTransactionTableAmount({ transaction, accountName, productType });
  const isNegative = formattedAmount.includes('-');
  const deniedTransaction = transaction.status === TRANSACTION_STATUS.DENIED;
  const isCreditCardType = productType === 'credit_card';

  const handleClickRow = () => {
    setSelectedTransactionId(transaction?.transactionId);
  };

  const handleStopClickRow = (e) => e.stopPropagation();

  return (
    <tr className="tw-cursor-pointer" onClick={handleClickRow}>
      <td className=" tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
        <TransactionType transaction={transaction} />
      </td>
      <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transaction.from}</td>
      <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">{transaction.to}</td>
      <td
        className={cx(
          'tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5 tw-text-right',
          deniedTransaction
            ? 'tw-line-through tw-text-semantic-error'
            : !isNegative && 'tw-border-b tw-border-neutral-grey-5 tw-text-semantic-success'
        )}
      >
        <div className="tw-flex tw-items-center tw-justify-end">
          <span className="tw-mr-2">{formattedAmount}</span>
        </div>
      </td>
      <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
        <div className="tw-flex tw-items-center tw-justify-end">
          <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mt-1 tw-mr-2">
            <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
              <CurrencyFlag currency={transaction.amount.currency} size={14} />
            </div>
          </div>
          <span>{transaction.amount.currency}</span>
        </div>
      </td>
      <td className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5">
        <div className="tw-flex tw-items-center tw-justify-end">
          {transaction.transactionReceipt && (
            <a className="tw-text-sm" href={transaction.transactionReceipt} target="_blank" rel="noreferrer">
              <TooltipIcon IconComponent={CheckReceiptOutline} tooltipText="Download the receipt" size="16" />
            </a>
          )}
        </div>
      </td>
      {isCreditCardType && (
        <td
          className="tw-px-3 tw-py-4 tw-text-sm tw-border-b tw-border-neutral-grey-5 tw-w-52"
          data-interaction="disabled"
        >
          <div className="tw-flex tw-items-end tw-justify-end" onClick={handleStopClickRow}>
            <TransactionCategory transaction={transaction} />
          </div>
        </td>
      )}
    </tr>
  );
};

export default TransactionsTable;
