import React, { useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';

import Close from 'components/svg/Close';
import { ModalProps } from './Modal.types';

const MODAL_OVERLAY_CLASS_NAME = 'modal-container__overlay';

const Modal = ({ show, onClose, title, children, fitContent = false, isFullScreen }: ModalProps) => {
  const handleCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!onClose) return;

      if (e.target instanceof HTMLElement && e.target.classList.contains(MODAL_OVERLAY_CLASS_NAME)) {
        onClose();
      }
    },
    [onClose]
  );

  const dummyClose = useCallback(() => {}, []);

  return (
    <Transition.Root show={show}>
      <Dialog
        className="tw-fixed tw-z-10 tw-overflow-y-scroll tw-inset-0 tailwind-container modal-container"
        open={show}
        static
        onClose={dummyClose}
        onClick={handleCloseModal}
      >
        <article className="tw-flex tw-items-center tw-justify-center">
          <div
            className={cx(
              'tw-fixed tw-inset-0 tw-bg-neutral-dark tw-bg-opacity-20 tw-transition-opacity',
              MODAL_OVERLAY_CLASS_NAME
            )}
          />

          <Transition.Child
            className="tw-flex tw-flex-col tw-min-h-screen tw-w-full tw-items-center tw-justify-center lg:tw-py-12"
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
            enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leave="tw-ease-in tw-duration-800"
            leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
            leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
          >
            <Dialog.Panel
              className={cx(
                'tw-flex-grow md:tw-flex-grow-0 md:tw-rounded-md tw-bg-neutral-light tw-transform tw-transition-all tw-transition-200',
                fitContent && 'tw-min-w-min',
                isFullScreen ? 'tw-w-full md:tw-w-5/6 tw-h-5/6' : 'tw-w-full md:tw-w-2/3 lg:tw-w-1/2 xl:tw-w-1/3'
              )}
            >
              {title && (
                <Dialog.Title
                  as="strong"
                  className="tw-flex tw-justify-between tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4"
                >
                  {title}
                  {onClose && <Close className="tw-cursor-pointer" onClick={onClose} />}
                </Dialog.Title>
              )}
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </article>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
