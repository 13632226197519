import React, { useContext } from 'react';
import cx from 'classnames';

import { CardDetailsContext } from 'components/creditCards/components/CardDetailsPage/CardDetailsContext';
import { CardImage } from 'components/creditCards/components';
import { Details } from './components';
import { CreditCardStatus } from 'types/creditCard';
import { CardImageSectionProps } from './CardImageSection.types';

import styles from '../../CardDetails.module.scss';

const CardImageSection = ({ isLoading, cardNumberRef, cardExpiryRef, cardCVCRef }: CardImageSectionProps) => {
  const { creditCardDetails } = useContext(CardDetailsContext);

  const { virtual, status } = creditCardDetails || {};
  const isActive = status === CreditCardStatus.active;

  return (
    <div className={cx(styles.cardImage, 'tw-mb-4 tw-relative')}>
      <CardImage isVirtual={virtual} isActive={isActive} />
      <Details
        isLoading={isLoading}
        cardNumberRef={cardNumberRef}
        cardExpiryRef={cardExpiryRef}
        cardCVCRef={cardCVCRef}
      />
    </div>
  );
};

export default CardImageSection;
