import { useCallback, useContext, useMemo } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { addDays, format } from 'date-fns';
import { get } from 'lodash';

import { PaymentRequestStatus, PayorUser } from 'types/invoicing';
import { formatMoneyV2 } from 'utility/currency';
import { composeFullAddress } from 'utility/address';
import { Currencies } from 'constants/currencies';
import {
  GET_PAYOR_PAYMENT_REQUESTS,
  PROCESS_PAYMENT_REQUEST,
  PAYOR_SIGN_PRE_DEPOSIT_AUTHORIZATION,
} from 'graphql/invoicing';
import { useDeepEffect } from 'hooks';
import { AuthContext } from 'context/Auth';
import { PaymentRequestsContext } from 'components/PayorPortal/PaymentRequests/contexts/PaymentRequestsContext';
import { ConfirmPaymentProps } from '../ConfirmPayment.types';
import { getPaymentRequestSchedule } from 'components/Invoices/Invoices.utils';

const useConfirmPayment = ({ selectedPaymentRequestId, selectedBankAccountId, onClose }: ConfirmPaymentProps) => {
  const { me } = useContext(AuthContext) as unknown as {
    me: PayorUser;
  };

  const { paymentRequests, bankAccounts } = useContext(PaymentRequestsContext);

  const paymentRequest = useMemo(
    () => paymentRequests.find(({ id }) => id === selectedPaymentRequestId)!,
    [paymentRequests, selectedPaymentRequestId]
  );

  const bankAccount = useMemo(
    () => bankAccounts.find(({ id }) => id === selectedBankAccountId)!,
    [bankAccounts, selectedBankAccountId]
  );
  const { id: paymentRequestId, amount, dueDate, invoiceNumber, schedule } = paymentRequest || {};
  const vendorName = get(paymentRequest, 'vendor.name', '');
  const vendorEmail = get(paymentRequest, 'vendor.email', '');
  const vendorAddress = get(paymentRequest, 'vendor.address');

  const { country, countrySubdivision, postalCode, city, street, unitNumber } = vendorAddress || {};

  const fullVendorAddress = composeFullAddress({ country, countrySubdivision, postalCode, city, street, unitNumber });

  const {
    id: payorBankAccountId,
    bankName,
    maskedAccountNumber,
    routingNumber,
    institutionNumber,
    transitNumber,
    accountNumber,
  } = bankAccount || {};

  const [payorSignPreDepositAuthorization, { loading: isPADProcessing }] = useMutation(
    PAYOR_SIGN_PRE_DEPOSIT_AUTHORIZATION
  );
  const [processPaymentRequest, { loading: isPaymentProcessing }] = useMutation(PROCESS_PAYMENT_REQUEST, {
    refetchQueries: [GET_PAYOR_PAYMENT_REQUESTS],
    awaitRefetchQueries: true,
  });

  const currency = amount?.currency || Currencies.USD;

  const formattedAmount = formatMoneyV2(amount);

  const minDueDate = useMemo(() => new Date(), []);
  const maxDueDate = useMemo(() => {
    const now = new Date();
    const maxDate = dueDate ? new Date(dueDate) : now;

    return maxDate < minDueDate ? minDueDate : addDays(maxDate, 1);
  }, [dueDate, minDueDate]);

  const availableDueDate = useMemo(
    () => (new Date(dueDate) < minDueDate ? format(minDueDate, 'yyyy-MM-dd') : dueDate),
    [dueDate, minDueDate]
  );

  const {
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = getPaymentRequestSchedule(schedule);

  const bankInfo = `${bankName} - ${maskedAccountNumber}`;

  const { firstName: payorFirstName, lastName: payorLastName, preAuthorizationForms } = me || {};

  const isPADAgreementSigned = preAuthorizationForms?.find((pad) => pad.bankAccount.id === payorBankAccountId) || false;

  const form = useForm({
    defaultValues: {
      vendorName,
      dueDate: availableDueDate,
      amount: formattedAmount,
      currency,
      bankInfo,
      invoiceNumber,
    },
  });

  const { register, handleSubmit, setValue } = form;

  const onSubmit = useCallback(
    async (data) => {
      const processingToast = toast.loading('Processing payment request...');

      try {
        if (!isPADAgreementSigned) {
          const payorSignPreDepositAuthorizationResponse = await payorSignPreDepositAuthorization({
            variables: { firstName: payorFirstName, lastName: payorLastName, bankAccountId: payorBankAccountId },
          });

          if (!payorSignPreDepositAuthorizationResponse?.data?.payorSignPreDepositAuthorization) {
            throw new Error('Could not sign pre-deposit authorization.');
          }
        }

        const processPaymentResponse = await processPaymentRequest({
          variables: { paymentRequestId, payorBankAccountId, dueDate: data.dueDate },
        });

        if (processPaymentResponse?.data?.processPaymentRequest?.status !== PaymentRequestStatus.scheduled) {
          throw new Error('Could not process payment request');
        }

        toast.update(processingToast, {
          render: 'Payment Request has been confirmed and scheduled',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

        onClose();
      } catch (err) {
        toast.update(processingToast, {
          render: `Something went wrong. ${(err as ApolloError)?.message || err}. Please try again.`,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

        console.error(err);
      }
    },
    [
      paymentRequestId,
      payorBankAccountId,
      payorFirstName,
      payorLastName,
      processPaymentRequest,
      payorSignPreDepositAuthorization,
      onClose,
      isPADAgreementSigned,
    ]
  );

  useDeepEffect(() => {
    setValue('vendorName', vendorName);
    setValue('bankInfo', bankInfo);
    setValue('dueDate', availableDueDate);
    setValue('amount', formattedAmount);
    setValue('currency', currency);
    setValue('invoiceNumber', invoiceNumber);
  }, [setValue, vendorName, bankInfo, availableDueDate, formattedAmount, currency, invoiceNumber]);

  return {
    onSubmit,
    form,
    register,
    handleSubmit,
    vendorName,
    vendorEmail,
    fullVendorAddress,
    invoiceNumber,
    formattedAmount,
    currency,
    bankName,
    routingNumber,
    institutionNumber,
    transitNumber,
    accountNumber,
    minDueDate,
    maxDueDate,
    isProcessing: isPADProcessing || isPaymentProcessing,
    isPADAgreementSigned,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  };
};

export default useConfirmPayment;
