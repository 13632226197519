import Close from 'components/svg/Close';
import React from 'react';
import { HeaderProps } from './Header.types';

const Header = ({ onClose, title, subtitle }: HeaderProps) => {
  return (
    <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-px-8 tw-pt-8 tw-pb-4 tw-border-b tw-border-neutral-grey-4">
      <div className="tw-flex tw-flex-col">
        <div className="tw-text-sm">{subtitle}</div>
        <div className="tw-text-md tw-font-bold">{title}</div>
      </div>
      <Close className="tw-cursor-pointer" onClick={onClose} />
    </div>
  );
};

export default Header;
