import React from 'react';

type AccountLinkedProps = {
  width?: number;
  height?: number;
};

const AccountLinked = ({ width = 24, height = 24 }: AccountLinkedProps) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5 5H3.5C3.10218 5 2.72064 5.15804 2.43934 5.43934C2.15804 5.72064 2 6.10218 2 6.5V18.5C2 18.8978 2.15804 19.2794 2.43934 19.5607C2.72064 19.842 3.10218 20 3.5 20H21.5C21.8978 20 22.2794 19.842 22.5607 19.5607C22.842 19.2794 23 18.8978 23 18.5V6.5C23 6.10218 22.842 5.72064 22.5607 5.43934C22.2794 5.15804 21.8978 5 21.5 5ZM21.5 6.5V8.75H3.5V6.5H21.5ZM21.5 18.5H3.5V10.25H21.5V18.5ZM20 16.25C20 16.4489 19.921 16.6397 19.7803 16.7803C19.6397 16.921 19.4489 17 19.25 17H16.25C16.0511 17 15.8603 16.921 15.7197 16.7803C15.579 16.6397 15.5 16.4489 15.5 16.25C15.5 16.0511 15.579 15.8603 15.7197 15.7197C15.8603 15.579 16.0511 15.5 16.25 15.5H19.25C19.4489 15.5 19.6397 15.579 19.7803 15.7197C19.921 15.8603 20 16.0511 20 16.25ZM14 16.25C14 16.4489 13.921 16.6397 13.7803 16.7803C13.6397 16.921 13.4489 17 13.25 17H11.75C11.5511 17 11.3603 16.921 11.2197 16.7803C11.079 16.6397 11 16.4489 11 16.25C11 16.0511 11.079 15.8603 11.2197 15.7197C11.3603 15.579 11.5511 15.5 11.75 15.5H13.25C13.4489 15.5 13.6397 15.579 13.7803 15.7197C13.921 15.8603 14 16.0511 14 16.25Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0_478_5604)">
      <path
        d="M6.00053 12.625H6C4.93693 12.625 3.89773 12.9402 3.01381 13.5309C2.1299 14.1215 1.44097 14.9609 1.03415 15.9431C0.627329 16.9252 0.520886 18.006 0.728281 19.0486C0.935677 20.0913 1.4476 21.049 2.1993 21.8007C2.95101 22.5524 3.90874 23.0643 4.95139 23.2717C5.99404 23.4791 7.07477 23.3727 8.05693 22.9659C9.03908 22.559 9.87854 21.8701 10.4692 20.9862C11.0598 20.1023 11.375 19.0631 11.375 18V17.9995C11.3735 16.5745 10.8068 15.2084 9.79919 14.2008L9.44564 14.5544L9.79919 14.2008C8.79161 13.1932 7.42547 12.6265 6.00053 12.625Z"
        fill="curentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_478_5604">
        <rect width="12" height="12" fill="white" transform="translate(0 12)" />
      </clipPath>
    </defs>
  </svg>
);

export default AccountLinked;
