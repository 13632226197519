import React from 'react';

/* eslint-disable import/namespace */
/* eslint-disable import/named */
import { Theme } from '../../styles/tailwind';
import config from '../../config';
import LoopLogo from '../svg/LoopLogo';

const Header = () => (
  <div className="tw-hidden lg:tw-flex tw-w-full tw-flex-row tw-justify-start">
    <LoopLogo />
  </div>
);

const Footer = () => (
  <div className="tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-items-start lg:tw-items-center tw-justify-between tw-mt-24">
    <div className="tw-flex tw-flex-grow-0 tw-items-center">
      <small className="tw-text-neutral-grey-2">{config.year} © Loop. ALL Rights Reserved. </small>
      <a className="tw-hidden lg:tw-block tw-ml-8" href={config.privacyPolicyUrl} target="_blank" rel="noreferrer">
        <small className="tw-underline tw-text-neutral-grey-2">Legal </small>
      </a>
    </div>
    <div className="tw-flex tw-items-center">
      <a className="tw-block lg:tw-hidden tw-mr-8" href={config.privacyPolicyUrl} target="_blank" rel="noreferrer">
        <small className="tw-underline tw-text-neutral-grey-2">Legal </small>
      </a>
      <a data-testid="btn-logout" href="/signout">
        <small className="tw-underline tw-text-neutral-grey-2">Log out </small>
      </a>
    </div>
  </div>
);

const Layout = ({ children }) => {
  return (
    <Theme>
      <div className="tw-min-h-screen tw-w-full tw-flex tw-flex-col tw-bg-neutral-light tw-px-6 lg:tw-px-12 tw-pt-6 tw-pb-4">
        <div className="tw-w-full tw-flex tw-flex-col tw-items-center tw-flex-grow tw-bg-neutral-light">
          <Header />
          {children}
          <Footer />
        </div>
      </div>
    </Theme>
  );
};

export default Layout;
