import React from 'react';
import { Link } from 'react-router-dom';
import { BiUnlink } from 'react-icons/bi';

import { TRANSACTION_PRODUCT_TYPES, SCOPE, TRANSACTION_STATUS } from 'constants/index';
import { capitalize } from 'utility/string';
import MobileCurrencyFlag from 'components/home/MobileCurrencyFlag';
import PermissionChecker from 'components/PermissionChecker';
import { TransactionCategory } from 'components/Transactions/components';
import Button from 'components/Button';
import { INSUFFICIENT_FUNDS_AUTH_TXN_CODE } from 'constants/carta';

import { useTransactionDetail } from './hooks';
import {
  AttributeRow,
  ConfirmUnlinkDialog,
  TransactionTypeCard,
  NoteReceiptForm,
  FxTransactionRows,
  DisputeTransactionRow,
  DeniedTransaction,
} from './components';

const TransactionDetail = ({ header, transaction, accountNames, productType }) => {
  if (!transaction) return null;

  const {
    formattedAmount,
    formattedDate,
    accountFXTransaction,
    isPending,
    isFXTransaction,
    deniedTransaction,
    flaggedForReview,
    isOpenOverwriteReceipt,
    openOverwriteReceipt,
    isOpenUnlinkDialog,
    openUnlinkDialog,
    closeUnlinkDialog,
  } = useTransactionDetail({ transaction, accountNames, productType });

  return (
    <div className="tw-bg-neutral-grey-3.5 lg:tw-bg-neutral-light tw-p-4 lg:tw-h-full">
      <div className="tw-h-28 tw-border-b tw-border-neutral-grey-4">
        {header}
        <div className="tw-flex tw-justify-between tw-items-start">
          <div className="tw-flex tw-flex-col">
            <span
              className={`${
                deniedTransaction && 'tw-line-through tw-text-semantic-error'
              } tw-text-2xl tw-font-semibold`}
            >
              {formattedAmount}
            </span>
            <div className="tw-text-sm tw-mt-2 tw-text-neutral-grey-2">{formattedDate}</div>
          </div>
          <TransactionTypeCard transaction={transaction} />
        </div>
      </div>
      <div className="tw-mt-6 lg:tw-mt-0 tw-rounded-md tw-bg-neutral-light tw-p-2 lg:tw-p-0 tw-overflow-y-scroll tw-overflow-x-hidden tw-scrollbar-hide tw-h-96 lg:tw-h-4/5">
        {transaction.preAuthorization && transaction.status !== TRANSACTION_STATUS.POSTED && (
          <AttributeRow label="" value="Pre-authorization" />
        )}
        <AttributeRow label="Status" value={capitalize(isPending ? 'pending' : transaction.status)} />
        {deniedTransaction && (
          <AttributeRow
            label="Reason"
            value={
              transaction.loopCardErrorCode === INSUFFICIENT_FUNDS_AUTH_TXN_CODE ? (
                <DeniedTransaction transaction={transaction} />
              ) : (
                transaction.rejectReason
              )
            }
          />
        )}
        {flaggedForReview && <AttributeRow label="" value="Your transaction is in review by our team." />}
        <AttributeRow label="From" value={transaction.from} />
        <AttributeRow label="To" value={transaction.to} />
        {transaction.description && <AttributeRow label="Description" value={transaction.description} />}
        <AttributeRow
          label="Amount"
          value={
            <>
              <span className="tw-font-bold tw-mr-1">{formattedAmount}</span>
              {transaction.amount.currency}
            </>
          }
        />
        <AttributeRow
          label="Currency"
          value={
            <div className="tw-flex tw-items-center">
              <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mt-1 tw-mr-2">
                <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
                  <MobileCurrencyFlag currency={transaction.amount.currency} size={14} />
                </div>
              </div>
              <span>{transaction.amount.currency}</span>
            </div>
          }
        />
        {productType === TRANSACTION_PRODUCT_TYPES.creditCard && (
          <AttributeRow
            label="Category"
            value={
              <div className="tw-w-56 tw-flex tw-justify-end">
                <TransactionCategory transaction={transaction} />
              </div>
            }
          />
        )}
        {transaction.points && <AttributeRow label="Points" value={transaction.points} />}
        {transaction.transactionNote && <AttributeRow label="Notes" value={transaction.transactionNote} />}
        {transaction.transactionReceipt && (
          <AttributeRow
            label="Receipt"
            value={
              <Link
                className="tw-text-sm tw-text-semantic-error hover:tw-text-semantic-error"
                to={{ pathname: transaction.transactionReceipt }}
                target="_blank"
              >
                Click to download receipt
              </Link>
            }
          />
        )}
        {transaction.transactionReceipt && (
          <AttributeRow
            value={
              <Button type="button" onClick={openUnlinkDialog} className="tw-text-md">
                <div className="tw-flex tw-items-center tw-cursor-pointer tw-text-semantic-error hover:tw-text-semantic-error">
                  <BiUnlink size={22} className="w-mr-1" />
                  <span className="tw-font-semibold">Unlink this receipt</span>
                </div>
              </Button>
            }
          />
        )}
        {transaction.transactionReceipt && !isOpenOverwriteReceipt && (
          <AttributeRow
            value={
              <Button type="button" onClick={openOverwriteReceipt} className="tw-text-xs">
                Not the correct receipt? Upload another one
              </Button>
            }
          />
        )}
        <NoteReceiptForm
          transaction={transaction}
          formattedAmount={formattedAmount}
          overwriteReceipt={isOpenOverwriteReceipt}
        />
        {isFXTransaction && (
          <div className="tw-border-t tw-border-neutral-grey-4 tw-my-8 tw-bg-neutral-light tw-p-2 lg:tw-p-0">
            <FxTransactionRows transaction={transaction} accountFXTransaction={accountFXTransaction} />
          </div>
        )}
      </div>
      <PermissionChecker scope={SCOPE.managePayments}>
        <DisputeTransactionRow transaction={transaction} />
      </PermissionChecker>
      <ConfirmUnlinkDialog
        transaction={transaction}
        openUnlinkDialog={isOpenUnlinkDialog}
        closeUnlinkDialog={closeUnlinkDialog}
      />
    </div>
  );
};

export default TransactionDetail;
