import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { IoLocationOutline } from 'react-icons/io5';

import { ADDRESS_CATEGORY } from 'constants/index';
import CardActionButton from '../CardActionButton';
import AdditionalAddresses from './AdditionalAddresses';
import MainBusinessAddress from './MainBusinessAddress';

const { OA, RP, DA, CA, US, DEFAULT } = ADDRESS_CATEGORY;

const ToggleAddresses = ({ creditCard, isImpersonating, onCopyValueToClipboard }) => {
  const [showAddresses, setShowAddresses] = useState(false);

  const onToggleAddresses = () => setShowAddresses(!showAddresses);

  const onCopyCardAddress = (postalCode, street) => {
    if (isImpersonating) return;

    const selectedAddress = creditCard.addresses.find(
      (address) => address.postalCode === postalCode && address.street === street
    );

    const selectedAddressText = `${selectedAddress?.street}
        ${selectedAddress?.city || ''} ${selectedAddress?.countrySubdivision || ''}
        ${selectedAddress?.postalCode} ${selectedAddress?.country || ''}`;
    onCopyValueToClipboard(selectedAddressText);
  };

  const mainAddress = creditCard.addresses.find((address) => address.category === DA || address.category === DEFAULT);

  const additionalAddresses = creditCard.addresses.filter((address) => [OA, RP, CA, US].includes(address.category));

  return (
    <>
      <CardActionButton
        label="Supported Addresses"
        Icon={IoLocationOutline}
        onClick={onToggleAddresses}
        className="tw-bg-secondary-light-green tw-text-primary-dark-green"
      />
      <Transition
        as="div"
        className="tw-mt-4 tw-mb-2"
        show={showAddresses}
        enter="tw-ease-in-out tw-duration-200 tw-transition-all"
        enterFrom="tw-transform tw-opacity-0"
        enterTo="tw-transform tw-opacity-100"
        leave="tw-ease-in-out tw-duration-200 tw-transition-all"
        leaveFrom="tw-transform tw-opacity-100"
        leaveTo="tw-transform tw-opacity-0"
      >
        <AdditionalAddresses additionalAddresses={additionalAddresses} creditCard={creditCard} />
        <MainBusinessAddress mainAddress={mainAddress} onCopyCardAddress={onCopyCardAddress} />
      </Transition>
    </>
  );
};

export default ToggleAddresses;
