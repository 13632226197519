import { get } from 'lodash';

import { PayorPaymentRequest, PayorBankAccount, PaymentRequestStatus } from 'types/invoicing';
import { formatDateTime } from 'utility/date';
import { formatMoneyV2 } from 'utility/currency';
import { getPaymentRequestSchedule } from 'components/Invoices/Invoices.utils';

const usePaymentRequestFieldValues = ({
  paymentRequest,
  bankAccount,
}: {
  paymentRequest: PayorPaymentRequest;
  bankAccount?: PayorBankAccount;
}) => {
  const {
    dueDate,
    amount: { amount, currency },
    status,
    schedule,
  } = paymentRequest;

  const vendorName = get(paymentRequest, 'vendor.name', '');

  const formattedAmount = formatMoneyV2({ amount, currency });
  const formattedDueDate = formatDateTime(dueDate);

  const bankAccountName = bankAccount ? `${bankAccount.bankName} - ${bankAccount.maskedAccountNumber}` : '-';

  const isAvailableForProcessing = [PaymentRequestStatus.pending, PaymentRequestStatus.overdue].includes(status);

  const {
    frequencyType,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  } = getPaymentRequestSchedule(schedule);

  return {
    formattedAmount,
    currency,
    formattedDueDate,
    status,
    vendorName,
    isAvailableForProcessing,
    bankAccountName,
    frequencyType,
    frequencyLabel,
    formattedStartDate,
    formattedEndDate,
    numberOfOccurrences,
    isOngoing,
    isStoppedByEndDate,
    isStoppedByNumberOfOccurrences,
    isStoppedByCancel,
  };
};

export default usePaymentRequestFieldValues;
