import React from 'react';
import { FormProvider } from 'react-hook-form';

import Button from 'components/Button';
import { Checkbox, SubmitButton } from 'components/FormFields/v2';
import { useMembers } from './hooks';
import { OwnerInformationSection, OwnerInformationSectionSkeleton } from './components';
import { MembersProps } from './Members.types';

const Members = ({ onNextStep, onPreviousStep }: MembersProps) => {
  const {
    firstName,
    lastName,
    relevantOwners,
    form,
    handleSubmit,
    register,
    isAcceptedTerms,
    onSubmit,
    memberDetailsLoading,
    isLoading,
  } = useMembers({
    onNextStep,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-flex tw-flex-col tw-gap-8 tw-p-8">
          <div className="tw-text-sm tw-text-neutral-grey-2">
            To open an account we require you to verify all persons who directly or indirectly own 25% or more of the
            business and confirm their tax residency.
          </div>

          <div className="tw-mb-6">
            <div className="tw-font-bold tw-mb-4">Beneficial Owners & Directors</div>
            <div className="tw-flex tw-gap-2 tw-text-xs 2xl:tw-text-sm tw-text-neutral-grey-1 tw-mb-2">
              <div className="tw-w-1/3">Name of Owner</div>
              <div className="tw-w-1/3">
                Tax Residence<span className="tw-text-semantic-error">&nbsp;*</span>
              </div>
              <div className="tw-w-1/3">
                Taxpayer Identification Number&nbsp;(TIN)<span className="tw-text-semantic-error">&nbsp;*</span>
              </div>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-4">
              {memberDetailsLoading ? (
                <OwnerInformationSectionSkeleton />
              ) : (
                relevantOwners.map((owner, index) => {
                  return <OwnerInformationSection key={owner.id} index={index} owner={owner} />;
                })
              )}
            </div>
          </div>

          <Checkbox
            label={
              <div className="tw-cursor-pointer tw-text-sm">
                <span className="tw-font-semibold">
                  I, {firstName} {lastName}, hereby certify
                </span>{' '}
                to the best of my knowledge, that the information provided is complete and correct.
              </div>
            }
            name="acceptedTerms"
            disabled={memberDetailsLoading}
            ref={register({ required: true })}
          />
        </div>

        <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-4 tw-flex tw-justify-between">
          <Button onClick={onPreviousStep} secondary>
            Back
          </Button>
          <SubmitButton isDisabled={!isAcceptedTerms || memberDetailsLoading || isLoading}>Next</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default Members;
