import { useMemo, useContext } from 'react';
import { get } from 'lodash';
import { useMutation, ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

import { AuthContext } from 'context/Auth';
import { AccountsContext } from 'context/Accounts';
import { PADAgreementProps } from 'components/Accounts/AccountsContent/components/AccountsModal/components/AddFunds/components/Steps/Steps.types';
import { FundsContext } from 'components/Accounts/AccountsContent/contexts/FundsContext';
import { MOVE_FUNDS } from 'graphql/payments';
import { GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS } from 'graphql/wallets';
import { BankAccountCountry, PADAgreementCAVersion } from 'types/bankAccount';
import { User } from 'types/user';

const usePADAgreement = ({ onNextStep }: PADAgreementProps) => {
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const { addFundsInfo, setAddFundsTransaction, setError } = useContext(FundsContext);
  const { wallets, selectedWalletId, bankAccounts } = useContext(AccountsContext);

  const padAgreementVersion = get(me, 'account.hasCadCoreIntegration') ? PADAgreementCAVersion.core : undefined;
  const fromAccountId = get(addFundsInfo, 'fromAccount', '');

  const selectedWallet = useMemo(() => wallets?.find(({ id }) => id === selectedWalletId), [wallets, selectedWalletId]);

  const selectedBankAccount = useMemo(
    () => bankAccounts?.find(({ id }) => id === fromAccountId),
    [bankAccounts, fromAccountId]
  );

  const fromAccount = get(addFundsInfo, 'fromAccount', '');
  const toAccount = get(addFundsInfo, 'toAccount', '');
  const amount = get(addFundsInfo, 'amount', 0);

  const [moveFunds, { loading: loadingMoveFunds }] = useMutation(MOVE_FUNDS, {
    refetchQueries: [{ query: GET_WALLETS_BANK_ACCOUNTS_AND_CREDIT_STATEMENTS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    try {
      const response = await moveFunds({
        variables: {
          from: fromAccount,
          to: toAccount,
          originalAmount: amount,
          chargedAmount: amount,
        },
      });
      if (response?.data?.moveFunds) {
        setAddFundsTransaction(response.data.moveFunds);
      }
      onNextStep();
    } catch (err) {
      console.error(err);
      toast.error('Error adding funds - please try again');
      setError(err as ApolloError);
    }
  };

  return {
    fromAccount,
    toAccount,
    amount,
    handleSubmit,
    loadingMoveFunds,
    selectedWallet,
    selectedBankAccount,
    isUSBankAccount: selectedBankAccount?.country === BankAccountCountry.US,
    padAgreementVersion,
  };
};

export default usePADAgreement;
