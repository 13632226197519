import React, { useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Select, TextField } from 'components/FormFields/v2';
import { CARD_INVALID_ERROR, CARD_STARTING_DIGIT_ERROR, creditCardTypes } from 'constants/index';
import { AddPayeeContext } from 'components/payments/AddPayee/AddPayeeContext';
import Button from 'components/Button';
import { DIGITS_ONLY_REGEX } from 'constants/regex';
import { dynamicCardLengthErrorMessage } from './utils';

const AddCreditCard = ({ onNextStep, onPreviousStep, error, setError }) => {
  const { setCreditCard, creditCard } = useContext(AddPayeeContext);
  const form = useForm({
    defaultValues: {
      ...creditCard,
    },
  });

  const { register, handleSubmit, watch, setError: setFormError } = form;
  const [startingDigits, setStartingDigits] = useState(null);

  const onSubmit = async (data) => {
    onNextStep();
    setCreditCard(data);
    setError(null);
  };

  const handlePreviousStep = () => {
    onPreviousStep();
    setCreditCard({});
    setError(null);
  };

  const watchedCardType = watch('cardType', '');

  useEffect(() => {
    const cardTypeInfo = creditCardTypes.find((type) => type.value === watchedCardType);
    setStartingDigits(cardTypeInfo ? cardTypeInfo.startingDigits : null);
  }, [watchedCardType]);

  useEffect(() => {
    if (error?.path === 'cardNumber') {
      setFormError('cardNumber', { type: 'manual', message: error.message });
    }
  }, [error]);

  return (
    <FormProvider {...form} graphQLErrors={error && error.graphQLErrors}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-p-8 tw-space-y-8">
          <strong>Add A New Payee</strong>
          <TextField name="bankName" label="Bank Name" placeholder="CIBC" ref={register({ required: true })} required />
          <TextField
            name="displayCurrency"
            label="Currency to Pay In"
            placeholder="CAD - Canadian Dollars"
            disabled={true}
            required
          />

          <Select
            name="cardType"
            label="Credit Card Type"
            placeholder="Select Option"
            ref={register({ required: true })}
            options={creditCardTypes}
            required
          />

          <TextField
            name="displayName"
            label="Payee Nickname or Identifier"
            placeholder="CIBC VISA - JOHN SMITY"
            ref={register({ required: true })}
            required
          />

          <TextField
            name="cardNumber"
            label="Credit Card Number"
            placeholder="4267 6645 2357 0000"
            ref={register({
              validate: {
                startingDigits: (value) =>
                  (value.length > 0 && startingDigits && startingDigits.some((digit) => value.startsWith(digit))) ||
                  CARD_STARTING_DIGIT_ERROR,
                onlyDigits: (value) =>
                  (!!value.length && DIGITS_ONLY_REGEX.test(value.replace(/\s/g, ''))) || CARD_INVALID_ERROR,
                length: (value) => {
                  const expectedLength = cardType === 'amex' ? 15 : 16;
                  const digitsOnly = value.replace(/\s/g, '');
                  return digitsOnly.length === expectedLength || dynamicCardLengthErrorMessage(expectedLength);
                },
              },
            })}
            required
          />
        </div>
        <div className="tw-border-t tw-border-neutral-grey-3 " />

        <div className="tw-flex tw-my-5 tw-px-5 tw-justify-between">
          <Button type="button" secondary className="tw-px-6" onClick={handlePreviousStep} data-testid="prev-step-btn">
            Back
          </Button>
          <Button primary type="submit" className="tw-px-6" data-testid="add-payee-btn">
            Next
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddCreditCard;
