import { useContext } from 'react';
import get from 'lodash/get';

import { AuthContext } from 'context/Auth';
import { User } from 'types/user';
import { useToggle } from 'hooks';

const useCustomEmailSection = () => {
  const { me, meLoading } = useContext(AuthContext) as unknown as { me: User; meLoading: boolean };
  const existingLoopDomainEmail = get(me, 'account.loopDomainEmail');
  const { isOpen: showConfigureModal, open: onShowConfigureModal, close: onCloseConfigureModal } = useToggle();

  return {
    meLoading,
    existingLoopDomainEmail,
    showConfigureModal,
    onShowConfigureModal,
    onCloseConfigureModal,
  };
};

export default useCustomEmailSection;
