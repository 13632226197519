import React, { useContext, useEffect, useMemo } from 'react';
import { Transition } from '@headlessui/react';
import { useLocation, useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';

import { AccountsContext } from 'context/Accounts';
import { Loaders } from 'components/cards/Loader';
import { WalletBox, ButtonPanel, Transactions } from './components';
import { balancesOrder } from 'constants/index';
import { CURRENCY_ACCOUNT, BUSINESS_OPERATING_ACCOUNT } from './constants';
import { useToggle, useIsMobile } from 'hooks';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { FundsContextProvider } from './contexts/FundsContext';
import { MaintenanceBanner } from 'components/UI';
import config from 'config';

const AccountsContent = () => {
  const { wallets, loading, selectedWalletId, setSelectedWalletId } = useContext(AccountsContext);

  const { isOpen: showWalletContent, open: openWalletContent, toggle: toggleWalletContent } = useToggle();

  const { isExperimentEnabled: isCadBankAccountEnabled, loading: featureToggleLoading } = useFeatureToggle(
    false,
    'cad_bank_account'
  );

  const isMobile = useIsMobile();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const currencyParam = searchParams.get('currency');
  const history = useHistory();

  useEffect(() => {
    if (currencyParam) {
      const wallet = wallets.find(({ currency }) => currency === currencyParam);
      if (wallet) {
        setSelectedWalletId(wallet.id);
        openWalletContent();
      }
    }
  }, [currencyParam, wallets, setSelectedWalletId, openWalletContent]);

  const onWalletClick = (id: string) => {
    if (id === selectedWalletId) {
      toggleWalletContent();
    } else {
      openWalletContent();
      setSelectedWalletId(id);
    }
  };

  const walletsOrder = useMemo(
    () => [...wallets].sort((a, b) => balancesOrder.indexOf(a.currency) - balancesOrder.indexOf(b.currency)),
    [wallets, balancesOrder]
  );

  const goHome = () => history.push('/dashboard/home');

  if (loading) return <Loaders.Light />;

  return (
    <>
      <div className="tw-w-full tw-mb-16">
        <BiArrowBack size={24} className="lg:tw-hidden tw-cursor-pointer tw-mb-4" onClick={goHome} />

        <h2 className="tw-mb-4 lg:tw-mb-8">Accounts</h2>

        <div className="tw-text-sm tw-text-neutral-grey-2 tw-italic">
          <b>{CURRENCY_ACCOUNT.account_type}:</b> {CURRENCY_ACCOUNT.description}
        </div>
        {isCadBankAccountEnabled && !featureToggleLoading && (
          <div className="tw-text-sm tw-text-neutral-grey-2 tw-italic">
            <b>{BUSINESS_OPERATING_ACCOUNT.account_type}:</b> {BUSINESS_OPERATING_ACCOUNT.description}
          </div>
        )}

        <MaintenanceBanner maintenanceMessageSource={config.currencyCloudApiDown} />

        <div className="tw-mt-10 tw-flex tw-flex-col md:tw-flex-row tw-gap-4 wallet-box-container">
          {walletsOrder.map(({ id, currency, availableBalance, ongoingPaymentsBalance, activeExternalAccount }) => {
            const isSelected = selectedWalletId === id && showWalletContent;

            return (
              <WalletBox
                key={id}
                currency={currency}
                availableBalance={availableBalance}
                ongoingPaymentsBalance={ongoingPaymentsBalance}
                activeExternalAccount={activeExternalAccount}
                onClick={(e) => {
                  !isMobile && e.preventDefault();
                  onWalletClick(id);
                }}
                isClicked={isSelected}
              />
            );
          })}
        </div>
        <Transition
          as="div"
          className="tw-mt-4 tw-mb-2"
          show={showWalletContent}
          enter="tw-ease-in-out tw-duration-200 tw-transition-all"
          enterFrom="tw-transform tw-opacity-0"
          enterTo="tw-transform tw-opacity-100"
          leave="tw-ease-in-out tw-duration-200 tw-transition-all"
          leaveFrom="tw-transform tw-opacity-100"
          leaveTo="tw-transform tw-opacity-0"
        >
          <FundsContextProvider>
            <ButtonPanel showWalletContent={showWalletContent} search={search} />
            <Transactions />
          </FundsContextProvider>
        </Transition>
      </div>
    </>
  );
};

export default AccountsContent;
