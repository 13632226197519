import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import cx from 'classnames';
import _ from 'lodash';
import { BsCheckCircle, BsCalendar4 } from 'react-icons/bs';
import { FiCircle } from 'react-icons/fi';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { TiInfo } from 'react-icons/ti';
import NumberFormat from 'react-number-format';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactTooltip from 'react-tooltip';
import { subYears } from 'date-fns';

import { formatDOB, dateToDateTime } from 'utility/date';
import { getCountries } from 'utility/countries';
import { formatMoney, centsFromMoneyString } from 'utility/currency';
import FlagCARound from 'components/svg/flags/ca_round';
import EyeOpen from 'components/svg/EyeOpen';
import EyeClosed from 'components/svg/EyeClosed';
import CountryCircle from 'components/CountryCircle';
import Button from 'components/Button';
import { CAPS_LOCK_ON_WARNING } from './constants';

import styles from './FormFields.module.scss';

const TextInputComponent = (props, ref) => {
  const { hasError, disabled, noBorder, className, ...inputProps } = props;

  return (
    <input
      id={inputProps.name}
      data-testid={inputProps.name}
      className={`${
        disabled ? 'tw-bg-neutral-grey-3 tw-cursor-not-allowed' : ''
      } tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-full ${
        noBorder ? 'tw-border-0 ' : 'tw-bg-neutral-light tw-border-2 focus:tw-shadow-input '
      } ${
        hasError
          ? 'tw-border-semantic-error focus:tw-border-semantic-error'
          : 'tw-border-neutral-grey-3 focus:tw-border-primary-dark-green'
      } tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-outline-none focus:tw-ring-0 ${className}`}
      disabled={disabled}
      {...inputProps}
      ref={ref}
    />
  );
};

const TextInput = React.forwardRef(TextInputComponent);

const parseGraphQLError = (graphQLErrors, name) => {
  let error;

  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) => {
      const fieldName = path && path.slice(1).join('.');
      if (fieldName === name) error = { type: 'graphQL', message };
    });
  }

  return error;
};

const WarningLabel = ({ message, className }) => {
  if (!message) return null;

  return (
    <small
      role="alert"
      className={`tw-flex tw-items-center tw-text-semantic-warning tw-my-2 ${!className ? '' : className}`}
    >
      <TiInfo />
      &nbsp;
      {message}
    </small>
  );
};

const ErrorLabel = ({ error, className, ...otherProps }) => (
  <small role="alert" className={`tw-text-semantic-error tw-my-2 ${!className ? '' : className}`} {...otherProps}>
    {error && (error.type === 'required' && !error.message ? 'This field is required' : error.message)}
  </small>
);

const InfoTip = ({ text }) => {
  if (!text) return null;

  return (
    <span className="tw-float-right tw-pt-2 tw-pr-2">
      <AiOutlineInfoCircle className="tw-text-neutral-grey-2" data-tip={text} />
      <ReactTooltip
        className="tw-w-1/2 lg:tw-w-1/4"
        textColor="var(--colors-natural-dark)"
        backgroundColor="var(--colors-natural-grey-4)"
      />
    </span>
  );
};

const TextFieldComponent = (props, ref) => {
  const { label, rootClass = '', infoText, ...inputProps } = props;
  const { errors, graphQLErrors } = useFormContext();
  const error = _.get(errors, inputProps.name) || parseGraphQLError(graphQLErrors, inputProps.name);

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      {label && (
        <label htmlFor={inputProps.name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
          {label}
          {inputProps.required && <span className="tw-text-semantic-error">{' *'}</span>}
          <InfoTip text={infoText} />
        </label>
      )}
      <TextInput {...inputProps} hasError={!!error} ref={ref} />
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const TextAreaComponent = (props, ref) => {
  const { label, rootClass = '', required, infoText, ...inputProps } = props;
  const { errors, graphQLErrors } = useFormContext();
  const error = _.get(errors, inputProps.name) || parseGraphQLError(graphQLErrors, inputProps.name);

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      {label && (
        <label htmlFor={inputProps.name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
          {label}
          {required && <span className="tw-text-semantic-error">{' *'}</span>}
          <InfoTip text={infoText} />
        </label>
      )}
      <textarea
        id={inputProps.name}
        className={cx(
          'tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input',
          error ? 'tw-border-semantic-error focus:tw-border-semantic-error' : 'tw-border-neutral-grey-3'
        )}
        {...inputProps}
        ref={ref}
      />
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const FixedSuffixFieldComponent = (props, ref) => {
  const { label, rootClass = '', required, infoText, inputSuffix, ...inputProps } = props;
  const { errors, graphQLErrors } = useFormContext();
  const error = _.get(errors, inputProps.name) || parseGraphQLError(graphQLErrors, inputProps.name);

  return (
    <div className="tw-flex tw-flex-col">
      {label && (
        <label htmlFor={inputProps.name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
          {label}
          {required && <span className="tw-text-semantic-error">{' *'}</span>}
          <InfoTip text={infoText} />
        </label>
      )}
      <div className={`tw-flex tw-flex-row tw-border-2 tw-border-neutral-grey-3 tw-rounded-md ${rootClass}`}>
        <TextInput {...inputProps} hasError={!!error} ref={ref} noBorder={true} />
        <div className={`tw-py-2 tw-pr-2 tw-font-bold ${rootClass}`}>{inputSuffix}</div>
      </div>
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const PasswordFieldComponent = (props, ref) => {
  const [show, setShow] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const { errors, graphQLErrors } = useFormContext();
  const { label, rootClass = '', required, ...inputProps } = props;
  const error = _.get(errors, inputProps.name) || parseGraphQLError(graphQLErrors, inputProps.name);
  const toggleShow = () => setShow(!show);

  const checkCapsLock = (e) => setIsCapsLockOn(e.getModifierState('CapsLock'));

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      <label htmlFor={inputProps.name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
        {required && <span className="tw-text-semantic-error">{' *'}</span>}
      </label>
      <div className="tw-relative">
        <TextInput
          {...inputProps}
          hasError={!!error}
          type={show ? 'text' : 'password'}
          ref={ref}
          onKeyUp={checkCapsLock}
        />
        <div
          onClick={toggleShow}
          className="tw-cursor-pointer tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-text-sm"
        >
          {show ? <EyeClosed /> : <EyeOpen />}
        </div>
      </div>
      {isCapsLockOn && <WarningLabel message={CAPS_LOCK_ON_WARNING} />}
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const PhoneField = (props) => {
  const { label, rootClass = '', required, name, rules, infoText, ...inputProps } = props;
  const { errors, graphQLErrors, control } = useFormContext();
  const error = _.get(errors, name) || parseGraphQLError(graphQLErrors, name);

  let className =
    'tw-block tw-px-3 tw-pr-2 tw-pl-16 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input';
  className += error ? ' tw-border-semantic-error' : ' tw-border-neutral-grey-3';

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      <label htmlFor={name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
        {required && <span className="tw-text-semantic-error">{' *'}</span>}
        <InfoTip text={infoText} />
      </label>

      <div className="tw-relative">
        <div className="tw-absolute tw-py-2.5 tw-pl-3 tw-flex tw-items-center">
          <FlagCARound size={22} className="tw-mr-2" /> +1
        </div>

        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ onChange, value, ref }) => (
            <NumberFormat
              id={name}
              className={className}
              mask="_"
              onValueChange={(v) => onChange(v.value)}
              format="(###) ###-####"
              placeholder="(613) 435-7314"
              value={value}
              ref={ref}
              {...inputProps}
            />
          )}
        />
      </div>
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const MaskedTextField = (props) => {
  const { label, rootClass = '', required, name, rules, format, ...inputProps } = props;
  const { errors, control, graphQLErrors } = useFormContext();
  const error = _.get(errors, name) || parseGraphQLError(graphQLErrors, name);

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      <label htmlFor={name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
        {required && <span className="tw-text-semantic-error">{' *'}</span>}
      </label>

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ onChange, value, ref }) => (
          <NumberFormat
            id={name}
            className={`${
              error ? 'tw-border-semantic-error' : 'tw-border-neutral-grey-3'
            } tw-block tw-px-3 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input`}
            mask="_"
            onValueChange={(v) => onChange(v.value)}
            format={format}
            value={value}
            ref={ref}
            {...inputProps}
          />
        )}
      />
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const PinField = (props) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const { label, rootClass = '', required, name, rules, format, ...inputProps } = props;
  const { errors, control, graphQLErrors } = useFormContext();
  const error = _.get(errors, name) || parseGraphQLError(graphQLErrors, name);

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      <label htmlFor={name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
        {required && <span className="tw-text-semantic-error">{' *'}</span>}
      </label>

      <div className="tw-relative">
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ onChange, value, ref }) => (
            <NumberFormat
              id={name}
              className={`${
                error ? 'tw-border-semantic-error' : 'tw-border-neutral-grey-3'
              } tw-block tw-px-3 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input`}
              mask="_"
              onValueChange={(v) => onChange(v.value)}
              format={format}
              value={value}
              ref={ref}
              type={show ? 'text' : 'password'}
              {...inputProps}
            />
          )}
        />
        <div
          onClick={toggleShow}
          className="tw-cursor-pointer tw-absolute tw-top-1/4 tw-right-0 tw-pr-3 tw-flex tw-items-center tw-text-sm"
        >
          {show ? <EyeClosed /> : <EyeOpen />}
        </div>
      </div>
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

export const SubmitButton = (props) => {
  const { disabled, className, ...otherProps } = props;

  if (disabled) otherProps.disabled = true;

  return (
    <Button
      type="submit"
      id={otherProps.type}
      className={`${
        disabled ? 'tw-bg-neutral-grey-3' : 'tw-bg-primary-dark-green'
      } tw-text-neutral-light tw-py-2 tw-px-4 tw-rounded-md tw-text-center ${className}`}
      {...otherProps}
    />
  );
};

const SelectComponent = (props, ref) => {
  const {
    label,
    placeholder,
    options,
    required,
    className,
    rootClass = '',
    infoText,
    disabled,
    unselected = false,
    ...inputProps
  } = props;
  const { errors, graphQLErrors, watch } = useFormContext();
  const selectValue = watch(inputProps.name);
  const error = _.get(errors, inputProps.name) || parseGraphQLError(graphQLErrors, inputProps.name);

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      {label && (
        <label htmlFor={inputProps.name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
          {label}
          {required && <span className="tw-text-semantic-error">{' *'}</span>}
          <InfoTip text={infoText} />
        </label>
      )}
      <select
        data-testid={inputProps.name}
        id={inputProps.name}
        defaultValue={placeholder && ''}
        className={`${
          disabled ? 'tw-bg-neutral-grey-3 tw-cursor-not-allowed' : ''
        } tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 ${
          error ? 'tw-border-semantic-error focus:tw-border-semantic-error' : 'tw-border-neutral-grey-3'
        } ${
          selectValue ? 'tw-text-neutral-black' : 'tw-text-neutral-grey-2'
        } tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-ring-0 focus:tw-outline-none focus:tw-shadow-input ${className}`}
        {...inputProps}
        ref={ref}
        disabled={disabled}
      >
        {(!!placeholder || unselected) && (
          <option disabled={!unselected} key="default" value="" hidden={!unselected}>
            {placeholder || ''}
          </option>
        )}

        {options.map((option, i) => (
          <option key={option.value || i} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const CountrySelectComponent = (props, ref) => {
  const {
    label,
    placeholder,
    className,
    required,
    rootClass = '',
    useShortCode,
    allowedCountries = [],
    ...inputProps
  } = props;
  const { errors, graphQLErrors, watch } = useFormContext();
  const error = _.get(errors, inputProps.name) || parseGraphQLError(graphQLErrors, inputProps.name);
  const countryName = watch(inputProps.name);
  const countries = getCountries([], allowedCountries);
  const options = countries.map((country) => ({
    name: country.name,
    value: useShortCode ? country.value : country.name,
  }));
  const country = countries.find((c) => c.value === countryName);

  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      <label htmlFor={inputProps.name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
        {required && <span className="tw-text-semantic-error">{' *'}</span>}
      </label>
      <div className="tw-relative">
        <select
          id={inputProps.name}
          className={`tw-block tw-px-3 tw-pl-10 tw-pr-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 ${
            error ? 'tw-border-semantic-error' : 'tw-border-neutral-grey-3'
          } ${
            inputProps.disabled ? 'tw-text-neutral-grey-2' : 'tw-text-neutral-black'
          } tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-ring-0 focus:tw-outline-none focus:tw-shadow-input ${className}`}
          {...inputProps}
          ref={ref}
        >
          {placeholder ? (
            <option key="default" value="">
              {placeholder}
            </option>
          ) : null}

          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
        {country && (
          <div className="tw-absolute tw-inset-y-0 tw-left-3 tw-pb-1 tw-pr-3 tw-flex tw-items-center tw-text-sm">
            <CountryCircle countryName={country.name} countryShortCode={country.value} />
          </div>
        )}
      </div>
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const CheckboxComponent = (props, ref) => {
  /*
   * TODO
   * This comment will be replaced after moving to TypeScript.
   * Possible check mark sizes: xs, sm, base, lg, xl, 2xl
   */
  const { label, name, rootClass = '', size = 'base', ...inputProps } = props;

  return (
    <div className={`${styles.checkbox} tw-flex tw-justify-start tw-items-center ${rootClass}`}>
      <input
        id={name}
        className={`${styles[`input__size-${size}`]}`}
        type="checkbox"
        name={name}
        ref={ref}
        {...inputProps}
      />
      <label className="tw-ml-4 tw-mb-0 tw-cursor-pointer" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

const RadioFieldComponent = (props, ref) => {
  const {
    name,
    options = [],
    rootClass = '',
    hideIcons = false,
    size = 'regular',
    optionWrapperClass = '',
    ...other
  } = props;

  const { errors = {}, graphQLErrors = [] } = useFormContext() || {};
  const error = _.get(errors, name) || parseGraphQLError(graphQLErrors, name);

  return (
    <div className={cx(rootClass)}>
      <div className={cx(styles.radioBtnWrapper, styles[`radioBtnWrapper--${size}`])}>
        {options.map((o, i) => {
          const id = `radio-${name}-${i}`;
          const labelId = `label-${name}-${i}`;

          return (
            <div
              className={cx(
                styles.radioBtn,
                error && styles.error,
                other.disabled && styles.disabled,
                optionWrapperClass
              )}
              key={id}
            >
              <input
                id={id}
                type="radio"
                name={name}
                value={o.value}
                defaultChecked={!!o.defaultChecked}
                ref={ref}
                {...other}
              />
              <label id={labelId} htmlFor={id}>
                {!hideIcons && (
                  <span>
                    <BsCheckCircle className={styles.checkIcon} />
                    <FiCircle className={styles.unCheckIcon} />
                  </span>
                )}

                <span>{o.label}</span>
              </label>
            </div>
          );
        })}
      </div>
      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const DatePickerComponent = (props, ref) => {
  const {
    label,
    name,
    rootClass = '',
    className,
    required,
    minDate,
    maxDate,
    valueFormat = 'yyyy-MM-dd',
    inputFormat = 'dd/MM/yyyy',
    placeholder,
  } = props;
  const { errors, setValue, watch, graphQLErrors } = useFormContext();
  const calendarRef = useRef();
  const currentValue = watch(name);
  const selectedDate = currentValue && dateToDateTime(currentValue);
  const error = (!currentValue && _.get(errors, name)) || parseGraphQLError(graphQLErrors, name);

  const handleDateChange = (date) => {
    setValue(name, formatDOB(date, valueFormat));
  };

  const onShowCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.setOpen(true);
    }
  };

  const maxDateLimit = useMemo(() => maxDate || new Date(), [maxDate]);
  const minDateLimit = useMemo(() => minDate || subYears(new Date(), 100), [minDate]);

  return (
    <div className={`${rootClass}`}>
      <label htmlFor={name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
        {required && <span className="tw-text-semantic-error">{' *'}</span>}
      </label>

      <div className="tw-relative">
        <ReactDatePicker
          id="datepicker-input"
          ref={calendarRef}
          selected={selectedDate}
          dateFormat={inputFormat}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={maxDateLimit}
          minDate={minDateLimit}
          onChange={handleDateChange}
          className={`tw-block tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 ${
            error ? 'tw-border-semantic-error' : 'tw-border-neutral-grey-3'
          } tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input ${className}`}
          showPopperArrow={false}
          placeholderText={placeholder}
        />
        <div
          onClick={onShowCalendar}
          className="tw-cursor-pointer tw-absolute tw-inset-y-0 tw-right-0 tw-pb-1 tw-pr-3 tw-flex tw-items-center tw-text-sm"
        >
          <BsCalendar4 />
        </div>
      </div>
      <input type="hidden" ref={ref} name={name} id={name} value={currentValue} />

      {error && <ErrorLabel error={error} />}
    </div>
  );
};

const ReadOnlyCountryComponent = (props, ref) => {
  const { label, rootClass = '', required, disabled, ...inputProps } = props;
  const countries = getCountries();
  const { watch } = useFormContext();
  const value = watch(inputProps.name);
  const country = countries.find((c) => c.value === value) || {};
  const { name, value: countryCode } = country;
  return (
    <div className={`tw-flex tw-flex-col ${rootClass}`}>
      <label htmlFor={inputProps.name} className="tw-text-neutral-grey-1 tw-text-sm tw-cursor-pointer">
        {label}
        {required && <span className="tw-text-semantic-error">{' *'}</span>}
      </label>
      <input data-testid={inputProps.name} type="hidden" id={inputProps.name} {...inputProps} ref={ref} />
      <div
        className={`${
          disabled ? 'tw-bg-neutral-grey-3 tw-cursor-not-allowed' : ''
        } tw-px-3 tw-py-2 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3`}
      >
        {countryCode && <CountryCircle countryName={name} countryShortCode={countryCode} />}
        <span className="tw-ml-4">{name}</span>
      </div>
    </div>
  );
};

const MoneyInputFieldComponent = (props, ref) => {
  const { currency, moneyFormatter = formatMoney, value, setValue, onChange, ...otherProps } = props;

  useEffect(() => {
    const extractedValue = centsFromMoneyString(value);

    setValue(moneyFormatter({ amount: extractedValue, currency }));
  }, [currency]);

  const handleFormatMoney = (e) => {
    const extractedValue = centsFromMoneyString(e.target.value);
    const formatted = moneyFormatter({ amount: extractedValue, currency });

    setValue(formatted);
    onChange && onChange(formatted);
  };

  return <TextField {...otherProps} ref={ref} onChange={handleFormatMoney} value={value} />;
};

const TextField = React.forwardRef(TextFieldComponent);
const TextArea = React.forwardRef(TextAreaComponent);
const PasswordField = React.forwardRef(PasswordFieldComponent);
const Select = React.forwardRef(SelectComponent);
const CountrySelect = React.forwardRef(CountrySelectComponent);
const Checkbox = React.forwardRef(CheckboxComponent);
const RadioField = React.forwardRef(RadioFieldComponent);
const DatePicker = React.forwardRef(DatePickerComponent);
const ReadOnlyCountry = React.forwardRef(ReadOnlyCountryComponent);
const MoneyInputField = React.forwardRef(MoneyInputFieldComponent);
const FixedSuffixField = React.forwardRef(FixedSuffixFieldComponent);

export {
  TextField,
  TextArea,
  PasswordField,
  Select,
  CountrySelect,
  Checkbox,
  RadioField,
  DatePicker,
  PhoneField,
  MaskedTextField,
  PinField,
  ReadOnlyCountry,
  MoneyInputField,
  FixedSuffixField,
  ErrorLabel,
};
