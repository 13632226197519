export const buildApolloGlobalId = ({ type, id }: { type: string; id: string }) => {
  if (!id || !type) return null;

  return `gid://loop-banking/${type}/${id}`;
};

export const getIdFromApolloGlobalId = ({ gid }: { gid: string }) => {
  const splitGid = gid.split('/');

  if (!splitGid.length || splitGid.length < 5) {
    console.error('Wrong Apollo Global ID format. Expected format: gid://loop-banking/{type}/{id}');
    return null;
  }

  return splitGid[splitGid.length - 1];
};

export const getEntityType = (entityId: string) => {
  const splitUserInfo = entityId.split('/');
  const idType = splitUserInfo.at(-2);
  return { idType: idType, id: splitUserInfo.at(-1) };
};
