import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import './styles';
import Routes from './Routes';
import Maintenance from 'components/Maintenance';
import config from './config';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import { ThemeContextProvider } from 'context/ThemeContext';

// init GTM session
import './gtm';

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <ErrorBoundary>{config.maintenance === 'on' ? <Maintenance /> : <Routes />}</ErrorBoundary>
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
