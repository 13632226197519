import { gql } from '@apollo/client';

import { TRANSACTION_FIELDS } from './transactions';

export const GET_PAYEES = gql`
  query Payees {
    payees {
      id
      paymentsAvailable
      externalContact {
        ... on SolidContact {
          id
        }
      }
      record {
        ... on Supplier {
          id
          bankAccount {
            code
            maskedNumber
            accountNumber
            verified
          }
          companyName
          currency
          firstName
          lastName
          name
          type
          displayName
          hasCurrencyCloud
          email
          status
        }
        ... on CreditCardPayee {
          id
          cardType
          cardNumber
          displayName
          verified
          currency
        }
        ... on CRAAccount {
          displayName
          verified
          currency
        }
      }
    }
  }
`;

export const ADD_CREDIT_CARD_PAYEE = gql`
  mutation AddCreditCardPayee(
    $bankName: String!
    $currency: String!
    $displayName: String!
    $cardType: CreditCardTypeEnum!
    $cardNumber: String!
  ) {
    addCreditCardPayee(
      bankName: $bankName
      currency: $currency
      displayName: $displayName
      cardType: $cardType
      cardNumber: $cardNumber
    ) {
      id
    }
  }
`;

export const ADD_CRA_ACCOUNT = gql`
  mutation AddCRAAccount(
    $businessNumber: String!
    $programAccount: CRAProgramAccountTypeEnum!
    $programAccountNumber: String!
  ) {
    addCraAccount(
      businessNumber: $businessNumber
      programAccount: $programAccount
      programAccountNumber: $programAccountNumber
    ) {
      id
    }
  }
`;

export const DELETE_PAYEE = gql`
  mutation DeletePayee($payeeId: ID!) {
    deletePayee(payeeId: $payeeId)
  }
`;

export const UPDATE_PAYEE = gql`
  mutation UpdatePayee($payeeId: ID!, $displayName: String, $email: String) {
    updatePayee(payeeId: $payeeId, displayName: $displayName, email: $email)
  }
`;

export const REJECT_PAYEE_PAYMENT = gql`
  mutation RejectPayeePayment($payeePaymentId: ID!, $authorizationToken: String!) {
    rejectPayeePayment(payeePaymentId: $payeePaymentId, authorizationToken: $authorizationToken)
  }
`;

export const PAY_PAYEE = gql`
  ${TRANSACTION_FIELDS}
  mutation PayPayee(
    $payeeId: ID!
    $authorizationToken: String!
    $exchangeRateReference: String
    $originalPayment: MoneyInput!
    $reason: String!
    $notes: String
    $from: String
    $sendConfirmationEmail: Boolean
    $payeePaymentId: ID
    $blobSignedId: String
    $invoiceNumber: String
    $invoiceDate: String
  ) {
    payPayee(
      payeeId: $payeeId
      authorizationToken: $authorizationToken
      exchangeRateReference: $exchangeRateReference
      originalPayment: $originalPayment
      reason: $reason
      notes: $notes
      from: $from
      sendConfirmationEmail: $sendConfirmationEmail
      payeePaymentId: $payeePaymentId
      blobSignedId: $blobSignedId
      invoiceNumber: $invoiceNumber
      invoiceDate: $invoiceDate
    ) {
      status
      payment {
        amount
        currency
      }
      chargedPayment {
        amount
        currency
      }
      conversionRate
      payee {
        record {
          ... on Supplier {
            name
            displayName
            bankAccount {
              maskedNumber
            }
            address {
              street
              postalCode
              city
              country
              countrySubdivision
            }
            bankName
          }
          ... on CreditCardPayee {
            displayName
            cardNumber
          }
          ... on CRAAccount {
            displayName
          }
        }
      }
      transactionTime
      transaction {
        ...TransactionFields
      }
    }
  }
`;

export const GET_SUBSCRIPTION_SAVINGS = gql`
  query SubscriptionSavings($buy: MoneyInput!, $sell: MoneyInput!) {
    subscriptionSavings(buy: $buy, sell: $sell) {
      savingsAmount {
        amount
        currency
      }
    }
  }
`;

export const GET_PAYEE_PAYMENTS = gql`
  query PayeePayments {
    payeePayments {
      id
      payeePaymentId
      status
      approvalStatus
      reason
      sendConfirmationEmail
      productId
      payment {
        amount
        currency
      }
      payee {
        id
        record {
          ... on Supplier {
            displayName
            address {
              street
              postalCode
              city
              country
              countrySubdivision
            }
            currency
            bankName
            bankAccount {
              maskedNumber
            }
          }
          ... on CreditCardPayee {
            displayName
            cardNumber
            cardType
            currency
          }
          ... on CRAAccount {
            displayName
            businessNumber
            currency
          }
        }
      }
      transactionTime
      transaction {
        transactionId
        transferMethod
        from
        completedAt
      }
      conversionRate
      chargedPayment {
        amount
        currency
      }
      approvalsNeeded
      approvalsMade
      approvalRequiredFromUser
      payeePaymentApprovals {
        id
        name
        approvedAt
      }
    }
  }
`;

export const GET_PAYEE_PAYMENTS_HOME = gql`
  query PayeePaymentsHome {
    payeePayments {
      id
      payeePaymentId
      payee {
        id
        record {
          ... on Supplier {
            displayName
          }
          ... on CreditCardPayee {
            displayName
          }
          ... on CRAAccount {
            displayName
          }
        }
      }
    }
  }
`;
