import React, { useContext, useState } from 'react';
import { IoCopyOutline, IoDownloadOutline } from 'react-icons/io5';
import ReactTooltip from 'react-tooltip';
import { useLazyQuery } from '@apollo/client';

import { AuthContext } from 'context/Auth';
import fileDownload from 'utility/fileDownload';
import { Loaders } from 'components/cards/Loader';
import { GET_BANK_DETAILS_PDF } from 'graphql/usBankDetails';
import config from 'config';
import { RadioField } from 'components/FormFields/v2';

const USDBankAccountDetails = ({ loading, walletExternalAccount }) => {
  const { me, meLoading } = useContext(AuthContext);
  const { account } = me;
  const { address } = walletExternalAccount || {};
  const { street, unitNumber, city, countrySubdivision, postalCode } = address || {};
  const isReady = walletExternalAccount.isReady();

  const onGetDownloadUrl = (data) => {
    const { downloadUrl } = (data && data.proofOfAccountDetailsDownload) || {};
    try {
      fileDownload(downloadUrl, `proof_of_account_details_${account && account.name}.pdf`);
    } catch (error) {
      console.error(error);
    }
  };

  const [getUrl, { loading: downloadingPdf }] = useLazyQuery(GET_BANK_DETAILS_PDF, {
    onCompleted: onGetDownloadUrl,
  });

  const businessAddress = `${street}, ${unitNumber || ''} ${
    unitNumber ? ',' : ''
  } ${city}, ${countrySubdivision} ${postalCode}`;

  if (meLoading || loading || downloadingPdf) return <Loading />;

  return (
    <div className="tw-space-y-8 tw-p-8" data-private>
      <h3>US Bank Account</h3>
      {isReady ? (
        <>
          <div onClick={getUrl} className="tw-flex tw-items-center tw-cursor-pointer">
            <IoDownloadOutline size={18} className="tw-text-primary-dark-green tw-mr-1" />
            <small className="tw-text-primary-dark-green tw-font-semibold">Download US Proof of Account Details</small>
          </div>
          <AccountReady
            routingNumber={walletExternalAccount.routingNumber}
            accountNumber={walletExternalAccount.accountNumber}
            accountName={account && account.name}
            accountType={walletExternalAccount.type}
            businessAddress={businessAddress}
          />
        </>
      ) : (
        <AccountVerificationInProgress />
      )}
    </div>
  );
};

const Loading = () => (
  <div className="tw-flex tw-items-center tw-justify-center tw-p-16">
    <Loaders.Small />
  </div>
);

const AccountVerificationInProgress = () => {
  return (
    <div className="tw-space-y-8 tw-flex tw-flex-col">
      <small className="tw-text-neutral-grey-1">
        Your Loop USD account verification is now in progress. Verification can take up to two business days. You will
        receive an email when your USD account has been verified and you can begin to make transactions to and from the
        account.
      </small>
      <small className="tw-text-neutral-grey-1">
        Please make sure the correct identification documents were uploaded. If you want to upload new documents you can
        do so{' '}
        <span className="tw-text-primary-dark-green tw-underline tw-font-semibold">
          <a href="/dashboard/settings/documents">here</a>
        </span>
        .
      </small>
    </div>
  );
};

const AccountReady = ({ accountNumber, accountName }) => {
  const routingNumber = config.newUsRoutingNumber;
  const [selectedTypeOfAccountDetails, setSelectedTypeOfAccountDetails] = useState('local');

  const radioFieldOptions = [
    {
      label: 'ACH',
      value: 'local',
      defaultChecked: true,
    },
    {
      label: 'Domestic Wire',
      value: 'domestic',
    },
    {
      label: 'International Wire',
      value: 'international',
    },
  ];

  const handleSwitchType = (value) => {
    setSelectedTypeOfAccountDetails(value);
  };

  return (
    <div className="tw-space-y-8">
      <div className="tw-flex tw-flex-col tw-space-y-8">
        <small className="tw-text-neutral-grey-2">
          Use these account details to get funds paid by your customers or payment processors directly into your Loop
          USD Bank Account.
        </small>
        <RadioField
          rootClass="tw-flex tw-mt-2 tw-mb-4"
          size="sm"
          label="type of account details"
          name="typeOfaccountDetails"
          options={radioFieldOptions}
          onClick={(e) => handleSwitchType(e.target.value)}
        />
        <div className="tw-space-y-4">
          {selectedTypeOfAccountDetails === 'local' && (
            <>
              <CopyableField title="Account Holder Name" value={accountName} />
              <CopyableField title="Account Number" value={accountNumber} />
              <CopyableField title="Routing Number" value={routingNumber} />
            </>
          )}
          {selectedTypeOfAccountDetails === 'domestic' && (
            <>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-flex tw-flex-col tw-space-y-1 tw-my-2 tw-text-xs">
                  <small className="tw-font-semibold">Send to Bank:</small>
                  <small className="tw-text-neutral-grey-1">Lewis & Clark Bank</small>
                  <small className="tw-text-neutral-grey-1">15960 S Agnes Ave</small>
                  <small className="tw-text-neutral-grey-1">Oregon City, OR 97045</small>
                  <small className="tw-text-neutral-grey-1">Routing Number: 123206972</small>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-flex tw-flex-col tw-space-y-1 tw-my-2 tw-text-xs">
                  <small className="tw-font-semibold">BENEFICIARY CUSTOMER:</small>
                  <small className="tw-text-neutral-grey-1">Name: {accountName}</small>
                  <small className="tw-text-neutral-grey-1">Account Number: {accountNumber}</small>
                </div>
              </div>
              <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-col tw-text-xs">
                  <span className="tw-font-semibold tw-mt-4">
                    Note: Incoming Wires will be subject to a USD $50 charge
                  </span>
                </div>
              </div>
            </>
          )}
          {selectedTypeOfAccountDetails === 'international' && (
            <>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <div className="tw-flex tw-flex-col tw-space-y-1 tw-my-2 tw-text-xs">
                  <small className="tw-font-semibold">INTERMEDIARY INSTITUTION:</small>
                  <small className="tw-text-neutral-grey-1">Pacific Coast Bankers' Bank</small>
                  <small className="tw-text-neutral-grey-1">San Francisco, CA 94104</small>
                  <small className="tw-text-neutral-grey-1">SWIFT CODE: PCBBUS66</small>
                  <small className="tw-text-neutral-grey-1">ABA Number: 121042484</small>
                </div>
              </div>
              <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-col tw-space-y-1 tw-text-xs">
                  <small className="tw-font-semibold">ACCOUNT WITH:</small>
                  <small className="tw-text-neutral-grey-1">Lewis & Clark Bank</small>
                  <small className="tw-text-neutral-grey-1">15960 S Agnes Ave</small>
                  <small className="tw-text-neutral-grey-1">PO Box 1630</small>
                  <small className="tw-text-neutral-grey-1">Oregon City, OR 97045</small>
                  <small className="tw-text-neutral-grey-1">ABA Number: 123206972</small>
                </div>
              </div>
              <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-col tw-space-y-1 tw-text-xs">
                  <small className="tw-font-semibold">BENEFICIARY CUSTOMER:</small>
                  <small className="tw-text-neutral-grey-1">Name: LCB FBO Solid Financial</small>
                  <small className="tw-text-neutral-grey-1">
                    Address (Line 1): 15960 S Agnes Ave, Oregon City OR 97045
                  </small>
                  <small className="tw-text-neutral-grey-1">Address (Line 2): BLANK</small>
                  <small className="tw-text-neutral-grey-1">Account Number: 700010000252</small>
                </div>
              </div>
              <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-col tw-space-y-1 tw-text-xs">
                  <small className="tw-font-semibold">ORIGINATOR TO BENEFICIARY INFORMATION:</small>
                  <small className="tw-text-neutral-grey-1">
                    {accountName} {accountNumber}
                  </small>
                </div>
              </div>
              <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-flex-col tw-text-xs">
                  <span className="tw-font-semibold tw-mt-4">
                    Note: Incoming Wires will be subject to a USD $50 charge
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const CopyableField = ({ value, title }) => {
  const onCopy = () => navigator.clipboard.writeText(value);

  return (
    <div>
      <small className="tw-text-neutral-grey-1">{title}</small>
      <div className="tw-flex tw-items-center tw-space-x-2">
        <small className="tw-text-neutral-grey-1">{value}</small>
        <IoCopyOutline
          className="tw-text-primary-dark-green tw-cursor-pointer"
          onClick={onCopy}
          data-tip="Copied!"
          data-for={`tooltip-${title}`}
        />
        <ReactTooltip
          id={`tooltip-${title}`}
          event="mouseup"
          eventOff="mouseout"
          delayHide={500}
          textColor="var(--colors-natural-light)"
          backgroundColor="var(--colors-natural-grey-1)"
        />
      </div>
    </div>
  );
};

export default USDBankAccountDetails;
