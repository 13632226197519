import { useContext } from 'react';

import { AuthContext } from 'context/Auth';
import { USER_ROLE_TYPES } from 'constants/index';

const useIsContactPermission = () => {
  const { me, meLoading } = useContext(AuthContext);
  const isOwner = !!(me && me?.internalContact?.role === USER_ROLE_TYPES.accountOwner);
  const isAdmin = !!(me && me?.internalContact?.role === USER_ROLE_TYPES.admin);

  const permissions = me?.internalContact?.permissions || [];
  const manageAccountingIntegrations = permissions.includes('manage_accounting_integrations');
  const manageMembers = permissions.includes('manage_members');
  const viewAllAccountBalances = permissions.includes('view_all_account_balances') || false;
  const createFxConversions = permissions.includes('create_fx_conversions');
  const addFundsToAccount = permissions.includes('add_funds_to_account');
  const withdrawFundsToVerifiedConnectedBankAccounts = permissions.includes(
    'withdraw_funds_to_verified_connected_bank_accounts'
  );
  const viewAccountDetails = permissions.includes('view_account_details');
  const viewAccountBalances = permissions.includes('view_all_account_balances');
  const viewPayors = permissions.includes('view_payors');
  const managePayors = permissions.includes('manage_payors');
  const createPayors = permissions.includes('create_payors');
  const viewPayorPaymentsRequested = permissions.includes('view_payor_payments_requested');
  const requestPayments = permissions.includes('create_payor_payments_request');
  const managePayments = permissions.includes('manage_payments');
  const viewPayees = permissions.includes('view_payees');
  const createPayeePayment = permissions.includes('create_payee_payment');
  const manageExternalAccounts = permissions.includes('manage_external_accounts');
  const manageBankAccounts = permissions.includes('manage_bank_accounts');
  const viewPayeePayments = permissions.includes('view_payee_payment');
  const createPayee = permissions.includes('create_payee');

  if (isOwner || isAdmin) {
    return {
      isOwner,
      isLoading: meLoading,
      createVirtualCard: true,
      createPhysicalCard: true,
      creditAgreementSigned: true,
      createPayeePayment,
      approvePayeePayment: true,
      viewPayeePayments,
      createPayee,
      requestPayments,
      manageAccountingIntegrations,
      manageMembers,
      viewAllAccountBalances,
      createFxConversions,
      addFundsToAccount,
      withdrawFundsToVerifiedConnectedBankAccounts,
      viewAccountDetails,
      viewPayors,
      managePayors,
      createPayors,
      viewPayorPaymentsRequested,
      managePayments,
      viewPayees,
      viewAccountBalances,
      manageExternalAccounts,
      manageBankAccounts,
    };
  }

  const isMember = !!(me && me?.internalContact?.role === USER_ROLE_TYPES.member);
  const isReadOnly = !!(me && me?.internalContact?.role === USER_ROLE_TYPES.readOnly);
  const isBookkeeper = !!(me && me?.internalContact?.role === USER_ROLE_TYPES.bookkeeper);
  const createVirtualCard = permissions.includes('create_virtual_card');
  const createPhysicalCard = permissions.includes('create_physical_card');
  const creditAgreementSigned = me?.creditAgreementSigned;
  const approvePayeePayment = isMember && permissions.includes('approve_payee_payment');

  return {
    isLoading: meLoading,
    createVirtualCard,
    createPhysicalCard,
    creditAgreementSigned,
    createPayeePayment,
    approvePayeePayment,
    viewPayeePayments,
    createPayee,
    viewPayors,
    managePayors,
    createPayors,
    requestPayments,
    isReadOnly,
    isBookkeeper,
    isOwner,
    manageAccountingIntegrations,
    manageMembers,
    viewAllAccountBalances,
    createFxConversions,
    addFundsToAccount,
    withdrawFundsToVerifiedConnectedBankAccounts,
    viewAccountDetails,
    viewPayorPaymentsRequested,
    managePayments,
    viewPayees,
    viewAccountBalances,
    manageExternalAccounts,
    manageBankAccounts,
  };
};

export default useIsContactPermission;
