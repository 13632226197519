import React from 'react';
import cx from 'classnames';

import { CardImageProps } from './CardImage.types';
import PhysicalCardClean from 'components/svg/PhysicalCardClean';

import creditCardVirtualSrc from 'images/creditCardVirtual.png';
import atlasCardSrc from 'images/AtlasCardMock.png';

const getCardComponent = ({ isVirtual, isAtlas }: CardImageProps) => {
  if (isVirtual) {
    return <img src={creditCardVirtualSrc} alt="Credit card" className="tw-w-full tw-h-full tw-object-contain" />;
  } else if (isAtlas) {
    return <img src={atlasCardSrc} alt="Credit card" className="tw-w-full tw-h-full tw-object-contain" />;
  } else {
    return <PhysicalCardClean width="100%" height="100%" />;
  }
};

const CardImage = ({ isActive, isVirtual, isAtlas }: CardImageProps) => {
  return (
    <div className={cx('tw-w-full tw-h-full', !isActive && 'tw-opacity-60')}>
      {getCardComponent({ isVirtual, isAtlas })}
    </div>
  );
};

export default CardImage;
