import { gql } from '@apollo/client';

export const SUBMIT_BUSINESS_DETAIL = gql`
  mutation SubmitBusinessDetail(
    $displayName: String
    $entityType: BusinessEntityTypeEnum!
    $mainIndustry: MainIndustryEnum!
    $industry: IndustryEnum!
    $legalBusinessName: String!
    $averageProductMargin: Float!
    $website: String
    $businessDescription: String
    $phone: String!
    $monthlyOnlineRevenue: MonthlyOnlineRevenueEnum!
    $referralSource: ReferralSourceEnum!
  ) {
    submitBusinessDetail(
      displayName: $displayName
      entityType: $entityType
      mainIndustry: $mainIndustry
      industry: $industry
      legalBusinessName: $legalBusinessName
      averageProductMargin: $averageProductMargin
      website: $website
      businessDescription: $businessDescription
      phone: $phone
      monthlyOnlineRevenue: $monthlyOnlineRevenue
      referralSource: $referralSource
    )
  }
`;

export const SUBMIT_BUSINESS_ADDRESS = gql`
  mutation SubmitBusinessAddress($address: AddressInput!) {
    submitBusinessAddress(address: $address)
  }
`;

export const SUBMIT_OWNERS_PROFILE = gql`
  mutation SubmitOwnerProfile(
    $address: AddressInput!
    $birthdate: ISO8601Date!
    $livedInTheSameAddressRecently: Boolean!
    $isAccountOwner: Boolean!
    $otherAddress: AddressInput
    $accountOwnerContact: ContactInput
    $exposedInPolitics: Boolean
    $fundsInThirdParty: Boolean
  ) {
    submitOwnerProfile(
      address: $address
      birthdate: $birthdate
      livedInTheSameAddressRecently: $livedInTheSameAddressRecently
      isAccountOwner: $isAccountOwner
      otherAddress: $otherAddress
      accountOwnerContact: $accountOwnerContact
      exposedInPolitics: $exposedInPolitics
      fundsInThirdParty: $fundsInThirdParty
    )
  }
`;

export const ADD_CORPORATE_OWNERSHIP = gql`
  mutation AddCorporateOwnership($corporateOwnership: Int!, $blobSignedId: String!) {
    addCorporateOwnership(corporateOwnership: $corporateOwnership, blobSignedId: $blobSignedId)
  }
`;

export const GET_CORPORATE_OWNERSHIP_INFO = gql`
  query CorporateOwnershipInfo {
    me {
      ... on User {
        account {
          hasCorporateOwnershipDocument
          corporateOwnership
        }
      }
    }
  }
`;

export const DELETE_CORPORATE_SHAREHOLDER = gql`
  mutation DeleteCorporateShareholder($corporateShareholderId: ID!) {
    deleteCorporateShareholder(corporateShareholderId: $corporateShareholderId)
  }
`;

export const ADD_INDIVIDUAL_OWNER = gql`
  mutation AddContact(
    $invitedToLoop: Boolean
    $email: String!
    $firstName: String!
    $lastName: String!
    $ownership: Int
    $roles: [RoleEnum!]
  ) {
    addContact(
      invitedToLoop: $invitedToLoop
      email: $email
      firstName: $firstName
      lastName: $lastName
      ownership: $ownership
      roles: $roles
    ) {
      id
    }
  }
`;

export const EDIT_INDIVIDUAL_OWNER = gql`
  mutation EditContact(
    $contactId: ID!
    $invitedToLoop: Boolean
    $email: String!
    $firstName: String!
    $lastName: String!
    $ownership: Int
    $roles: [RoleEnum!]
  ) {
    editContact(
      contactId: $contactId
      invitedToLoop: $invitedToLoop
      email: $email
      firstName: $firstName
      lastName: $lastName
      ownership: $ownership
      roles: $roles
    ) {
      id
    }
  }
`;

export const DELETE_INDIVIDUAL_OWNER = gql`
  mutation DeleteContact($contactId: ID!) {
    deleteContact(contactId: $contactId)
  }
`;

export const SUBMIT_SHAREHOLDERS_STEP = gql`
  mutation SubmitShareholdersStep($shareholders: [ShareholderInput!]!) {
    submitShareholdersStep(shareholders: $shareholders)
  }
`;

export const GET_CONTACTS_AND_CORPORATE_SHAREHOLDER = gql`
  query ContactsAndCorporateShareholder {
    me {
      ... on User {
        account {
          entityType
          contacts {
            id
            firstName
            lastName
            ownership
            roles
            email
            phone
            invitedToLoop
            address {
              street
              postalCode
              city
              country
              countrySubdivision
            }
          }
          corporateShareholders {
            id
            name
            ownership
            signingOfficerContact {
              email
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;
export const GET_BUSINESS_DETAILS = gql`
  query BusinessDetails {
    me {
      ... on User {
        account {
          name
          displayName
          entityType
          mainIndustry
          industry
          phone
          website
          businessDescription
          creditApplication {
            averageProductMargin
          }
          monthlyOnlineRevenue
          referralSource
        }
      }
    }
  }
`;

export const GET_BUSINESS_ADDRESS = gql`
  query BusinessAddress {
    me {
      ... on User {
        account {
          entityType
          address {
            street
            unitNumber
            city
            countrySubdivision
            postalCode
            country
          }
        }
      }
    }
  }
`;

export const GET_OWNERS_PROFILE = gql`
  query OwnersProfile {
    me {
      ... on User {
        account {
          entityType
          address {
            street
            unitNumber
            city
            countrySubdivision
            postalCode
            country
          }
          contacts {
            address {
              street
              unitNumber
              city
              countrySubdivision
              postalCode
              country
            }
            otherAddress {
              street
              unitNumber
              city
              countrySubdivision
              postalCode
              country
            }
            firstName
            lastName
            email
            birthdate
            livedInTheSameAddressRecently
            roles
          }
        }
      }
    }
  }
`;

export const COMPLETE_ACCOUNT_APPLICATION = gql`
  mutation CompleteAccountApplication($exposedInPolitics: Boolean!, $fundsInThirdParty: Boolean!) {
    completeAccountApplication(exposedInPolitics: $exposedInPolitics, fundsInThirdParty: $fundsInThirdParty)
  }
`;

export const GET_KYC_AND_CREDIT_ASSESSMENT_STATUS = gql`
  query KycAndCreditAssessmentStatus {
    me {
      ... on User {
        account {
          name
          address {
            unitNumber
            street
            city
            countrySubdivision
          }
          kycAssessment {
            id
            status
            reasons
          }
          creditApplication {
            status
          }
          creditAssessment {
            status
          }
          globalCreditLimit {
            availableBalance {
              amount
              currency
            }
          }
          contactsWithoutEmail {
            id
            firstName
            lastName
          }
          contactsNeedConsent {
            id
            firstName
            lastName
            creditCheckAuthorizationSent
            creditCheckAuthorizationProvided
            creditCheckAuthorizationSentAt
            creditCheckAuthorizationExpired
            thirdPartyVerificationComplete
            email
          }
          contacts {
            id
            firstName
            lastName
            email
          }
          contactsRequireDocument {
            id
            firstName
            lastName
          }
          corporateShareholders {
            id
            name
            ownership
            signingOfficerContact {
              email
              firstName
              lastName
            }
          }
          corporateShareholdersWithoutEmail {
            id
            name
            ownership
            signingOfficerContact {
              email
              firstName
              lastName
            }
          }
          corporateShareholdersNeedConsent {
            id
            name
            ownership
            signingOfficerContact {
              email
              firstName
              lastName
              creditCheckAuthorizationSent
              creditCheckAuthorizationProvided
              creditCheckAuthorizationSentAt
              creditCheckAuthorizationExpired
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTACTS_FOR_KYC_CHECK = gql`
  query ContactsForKycCheck {
    me {
      ... on User {
        account {
          contacts {
            id
          }
        }
      }
    }
  }
`;

export const RESEND_GUARANTOR_CONSENT_FORM = gql`
  mutation ResendGuarantorConsentForm($contactId: ID!) {
    resendGuarantorConsentForm(contactId: $contactId)
  }
`;

export const GET_MONTHLY_ONLINE_REVENUE = gql`
  query MonthlyOnlineRevenue {
    me {
      ... on User {
        account {
          monthlyOnlineRevenue
        }
      }
    }
  }
`;
