import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { SOLID_OWNERSHIP_DISCLOSURE_STATE } from 'graphql/wallets';
import { SCOPE } from 'constants/index';
import useHasPermission from 'hooks/useHasPermission';
import { ButtonPanelProps } from './ButtonPanel.types';
import { getButtons } from './ButtonPanel.utils';
import ActionButton from './ActionButton';
import AccountsModal from '../AccountsModal';
import { useAccountsModal } from '../AccountsModal/hooks';
import OpenFreeAccountBanner from './OpenFreeAccountBanner/OpenFreeAccountBanner';
import USAccountOwnershipDisclosureBanner from './USAccountOwnershipDisclosureBanner/USAccountOwnershipDisclosureBanner';
import { USBankAccountOwnershipDisclosureModal } from 'components/Accounts/components';
import { Currencies } from 'constants/currencies';
import CurrencyFlag from 'components/svg/CurrencyFlag';
import useFeatureToggle from 'hooks/useFeatureToggle';
import { useToggle } from 'hooks';

const ButtonPanel = ({ showWalletContent, search }: ButtonPanelProps) => {
  const [activeModal, setActiveModal] = useState<string>('');
  const { activeExternalAccount, currency } = useAccountsModal();
  const { isExperimentEnabled: isCadBankAccountEnabled, loading: toggleLoading } = useFeatureToggle(
    false,
    'cad_bank_account'
  );
  const featureFlag =
    (currency === Currencies.CAD && isCadBankAccountEnabled && !toggleLoading) || currency !== Currencies.CAD;
  const history = useHistory();
  const { hasPermission: canManageBankAccounts } = useHasPermission({ scope: SCOPE.manageBankAccounts });

  const {
    isOpen: showUSAccountOwnershipDisclosureModal,
    open: openUSAccountOwnershipDisclosureModal,
    close: closeUSAccountOwnershipDisclosureModal,
  } = useToggle();

  const searchParams = new URLSearchParams(search);
  const addFundsParam = searchParams.get('add-funds');
  const convertFundsParam = searchParams.get('convert-funds');

  useEffect(() => {
    if (addFundsParam) {
      setActiveModal('Add Funds');
    } else if (convertFundsParam) {
      setActiveModal('Convert');
    }
  }, [addFundsParam, convertFundsParam]);

  const Buttons = getButtons(currency || Currencies.CAD, (activeExternalAccount && featureFlag) || false);

  const closeActiveModal = () => {
    setActiveModal('');
    history.push(window.location.pathname);
  };

  const Modals = Buttons?.map(({ buttonText }) => (
    <AccountsModal key={buttonText} show={activeModal === buttonText} onClose={closeActiveModal} title={buttonText} />
  ));

  const { data: solidRequirementsData } = useQuery(SOLID_OWNERSHIP_DISCLOSURE_STATE, { fetchPolicy: 'network-only' });

  const solidOwnershipDisclosureSigned = get(solidRequirementsData, 'solidRequirements.ownershipDisclosureSigned');
  const solidAccountExists = get(solidRequirementsData, 'solidRequirements.solidAccountExists');

  const showUSAccountOwnershipDisclosureBanner =
    canManageBankAccounts && currency === Currencies.USD && solidAccountExists && !solidOwnershipDisclosureSigned;

  return (
    <>
      <div
        id="wallet-actions-bar"
        className={cx(
          'wallet-actions-bar tw-mt-8 tw-mb-5 tw-p-4 tw-border tw-border-primary-dark-green tw-rounded tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap tw-justify-center tw-items-center tw-gap-2',
          showWalletContent ? 'tw-bg-primary-light-green' : 'tw-bg-neutral-grey-5'
        )}
      >
        <div className="tw-rounded-full tw-bg-neutral-grey-4 tw-p-0.5 tw-mt-1 tw-mr-auto md:tw-mr-4">
          <div className="tw-rounded-full tw-bg-neutral-light tw-p-0.5">
            <CurrencyFlag currency={currency} size={26} />
          </div>
        </div>
        {Buttons?.map(({ buttonText, buttonIcon, iconSize, primary }) => (
          <ActionButton
            key={buttonText}
            buttonIcon={buttonIcon}
            buttonText={buttonText}
            fontSize={iconSize}
            primary={primary}
            onClick={() => setActiveModal(buttonText)}
          />
        ))}
      </div>
      {canManageBankAccounts && !activeExternalAccount && !showUSAccountOwnershipDisclosureBanner && featureFlag && (
        <OpenFreeAccountBanner search={search} />
      )}

      {canManageBankAccounts && showUSAccountOwnershipDisclosureBanner && (
        <USAccountOwnershipDisclosureBanner openModal={openUSAccountOwnershipDisclosureModal} />
      )}

      {/* Money movement modals */}
      {Modals}

      <USBankAccountOwnershipDisclosureModal
        show={showUSAccountOwnershipDisclosureModal}
        onClose={closeUSAccountOwnershipDisclosureModal}
      />
    </>
  );
};

export default ButtonPanel;
