import { Money, MoneyInCents, PayBalanceCurrency } from 'types/payments';
import { Statement } from 'types/statement';
import { Address } from 'types/shared';
import { Currencies } from 'constants/currencies';
import { InternalContact } from 'types/user';

export enum AddressCategory {
  DA = 'DA',
  S1 = 'S1',
  S2 = 'S2',
  OA = 'OA',
  RP = 'RP',
  DEFAULT = 'DEFAULT',
  CA = 'CA',
  US = 'US',
}

export enum CreditCardCurrency {
  CAD = Currencies.CAD,
  USD = Currencies.USD,
  EUR = Currencies.EUR,
  GBP = Currencies.GBP,
}

export type CreditCardContact = Pick<InternalContact, 'id' | 'firstName' | 'lastName' | 'role' | 'status'>;

export enum CreditCardStatus {
  active = 'active',
  inactive = 'inactive',
  admin_suspended = 'admin_suspended',
  suspended = 'suspended',
  block = 'block',
  damaged = 'damaged',
  fraudulent = 'fraudulent',
  lost = 'lost',
  stolen = 'stolen',
}

export type CreditCard = {
  id: string;
  cardProcessorId?: string;
  displayName?: string;
  contacts?: CreditCardContact[];
  creditLimitCents?: number;
  creditLimitCurrency?: PayBalanceCurrency.CAD;
  lastFourDigits?: string;
  nameOnCard?: string;
  nickname?: string;
  cardHolderName?: string;
  status?: CreditCardStatus;
  virtual?: boolean;
  trackerUrl?: string;
  addresses?: Address[];
};

export type CreditCardLimitSettings = {
  cardLimitCents: number;
  expiresMonthly: boolean;
  expiresAnnually: boolean;
  currentExpenseCents: number;
};

export type CreditCardCreateModal = {
  id: string;
  displayName: string;
  virtual: boolean;
};

export type GroupOngoingAmount<T = PayBalanceCurrency> = { [key: string]: MoneyInCents<T> };

export type GroupedCardsInfo = {
  cycle?: string;
  billingCycle?: {
    startDate: Date;
    endDate: Date;
    nextStartDate: Date;
    previousEndDate: Date;
    previousStartDate: Date;
  };
  paymentDate?: string;
  groupCreditLimit?: Money;
  groupOngoingAmountDueByCurrency: GroupOngoingAmount;
  groupOngoingMinimumAmountDueByCurrency: GroupOngoingAmount;
  groupConvertedMinimumAmountDueByCurrency: GroupOngoingAmount<PayBalanceCurrency.CAD>;
  amountDue?: Money;
  groupBalances?: Money<PayBalanceCurrency>[];
  groupPendingBalances?: Money<PayBalanceCurrency>[];
  groupPostedBalances?: Money<PayBalanceCurrency>[];
  groupAvailableBalance?: Money;
  groupOverlimit?: Money;
};

export type GlobalLimitInfoQueryResponse = {
  groupAmountDue: Money | null;
  groupAvailableBalance: Money | null;
  groupCreditLimit: Money | null;
  groupOverlimit: Money | null;
  groupTotalSpent: Money | null;
};

export type GlobalLimitInfo = {
  groupAmountDue: Money;
  groupAvailableBalance: Money;
  groupCreditLimit: Money;
  groupOverlimit: Money;
  groupTotalSpent: Money;
};

export type CardBalance = Money<PayBalanceCurrency>;

export type CreditCardBalancesQueryResponse = {
  billingCycle: {
    startDate: Date;
    endDate: Date;
  } | null;
  groupBalances: CardBalance[] | null;
  groupPendingBalances: CardBalance[] | null;
  groupPostedBalances: CardBalance[] | null;
} | null;

export type PreFundedCardBalancesQueryResponse = {
  groupBalances: CardBalance[] | null;
  groupOngoingPayments: CardBalance[] | null;
} | null;

export type CardBalances = {
  groupBalances?: CardBalance[];
  // next fields are fetched only for credit card users
  billingCycle?: {
    startDate: Date;
    endDate: Date;
  } | null;
  groupPendingBalances?: CardBalance[];
  groupPostedBalances?: CardBalance[];
  groupOngoingPayments?: CardBalance[];
};

export type CreditCardStatementsQueryResponse = {
  primaryCreditCard: {
    id: string;
    groupOngoingAmountDueByCurrency?: GroupOngoingAmount;
    groupPendingBalances?: CardBalance[];
    latestCycleStartDate: Date;
    statements: Pick<Statement, 'id' | 'title' | 'createdAt' | 'startDate' | 'endDate'>[];
  }[];
};

export type CurrentBillingCycleInfo = {
  groupBalances?: CardBalance[];
  latestStatement?: {
    id: string;
    endingBalanceByCurrency: GroupOngoingAmount;
    dueDate: Date;
    startDate: Date;
    endDate: Date;
  };
  groupCurrentCycleSpentByCurrency: GroupOngoingAmount;
  groupCurrentCyclePaymentsByCurrency: GroupOngoingAmount;
  nextStatementGenerationDate: Date;
};

export type CurrentBillingCycleInfoQueryResponse = {
  primaryCreditCard: {
    id: string;
  } & CurrentBillingCycleInfo;
};

export type CreditCardDetails = Pick<
  CreditCard,
  'id' | 'virtual' | 'status' | 'displayName' | 'lastFourDigits' | 'addresses' | 'cardProcessorId' | 'cardHolderName'
>;

export enum CreditCardProcessorSettingsLimitsCadence {
  monthly = 'monthly',
  annually = 'annually',
  lifetime = 'lifetime',
}

export type CreditCardProcessorSettingsLimits = {
  cadence?: CreditCardProcessorSettingsLimitsCadence;
  frequency?: string;
  amount?: Money;
  spentAmount?: Money;
  nextCadenceStartsAt?: Date;
};

export type CreditCardProcessorSettings = {
  allowedMccsCategories?: string[];
  blockedMccsCategories?: string[];
  allowedCurrencies?: CreditCardCurrency[];
  blockedCurrencies?: CreditCardCurrency[];
  allowedLocations?: string[];
  blockedLocations?: string[];
  merchantsList?: string[];
  allowedMerchants?: string[];
  limitSettings?: CreditCardProcessorSettingsLimits;
};

export type CreditCardProcessorSettingsQueryResponse = {
  creditCard: {
    processorSettings: CreditCardProcessorSettings;
  };
};
