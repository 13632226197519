import React, { useState, createContext, SetStateAction } from 'react';
import { CreateReimbursementRequestDataType } from '../CreateReimbursementRequest.types';

type CreateReimbursementRequestContextType = {
  reimbursementRequestInfo: CreateReimbursementRequestDataType | null;
  setReimbursementRequestInfo: React.Dispatch<SetStateAction<CreateReimbursementRequestDataType | null>>;
  showAmountInCAD: boolean;
  setShowAmountInCAD: React.Dispatch<SetStateAction<boolean>>;
};

export const CreateReimbursementRequestContext = createContext<CreateReimbursementRequestContextType>({
  reimbursementRequestInfo: null,
  setReimbursementRequestInfo: () => {},
  showAmountInCAD: false,
  setShowAmountInCAD: () => {},
});

export const CreateReimbursementRequestContextProvider: React.FC = ({ children }) => {
  const [reimbursementRequestInfo, setReimbursementRequestInfo] = useState<CreateReimbursementRequestDataType | null>(
    null
  );
  const [showAmountInCAD, setShowAmountInCAD] = useState<boolean>(false);

  return (
    <CreateReimbursementRequestContext.Provider
      value={{ reimbursementRequestInfo, setReimbursementRequestInfo, showAmountInCAD, setShowAmountInCAD }}
    >
      {children}
    </CreateReimbursementRequestContext.Provider>
  );
};
