import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormFooter, ProgressBar } from 'components/UI';

import AddressFields from 'components/onboarding/AddressFields';
import useCardAddress from './hooks/useCardDeliveryAddress';
import CardImage from 'components/creditCards/components/CardImage';

const CardDeliveryAddress = () => {
  const { address, loading, country, currentStep, steps, form, handleSubmit, handleOnPreviousStep, handleOnNextStep } =
    useCardAddress();
  const autocompleteCountryRestrictions = React.useMemo(() => [country], [country]);

  return (
    <FormProvider {...form}>
      <form
        className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-flex-grow"
        onSubmit={handleSubmit(handleOnNextStep)}
      >
        <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-w-full lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0">
          <ProgressBar className="tw-mb-4" currentStepIndex={currentStep} stepLabelsAndIndexes={steps} />
          <div className="tw-h-48 tw-mx-auto">
            <CardImage isVirtual={false} isActive />
          </div>
          <AddressFields
            name="address"
            streetAddressLabel="Personal Street Address"
            cityLabel="City"
            selectedCountry={form.watch('address.country')}
            rootClass="tw-px-8"
            autocompleteCountryRestrictions={autocompleteCountryRestrictions}
            initialAddress={address}
            disabled={loading}
          />
        </div>
        <div className="tw-bg-neutral-grey-4.5 tw-w-full tw-pt-4 tw-pb-8 tw-rounded-b-md tw-overflow-hidden tw-mt-auto">
          <FormFooter
            cancelButtonLabel="Go Back"
            onCancel={handleOnPreviousStep}
            submitButtonLabel="Next Step"
            loading={loading}
            rootClass="lg:tw-w-1/2 tw-mx-auto tw-px-4 lg:tw-px-0"
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default CardDeliveryAddress;
