import React from 'react';
import cx from 'classnames';

import Button, { IconAnimation } from 'components/Button';
import ArrowRight from 'components/svg/ArrowRight';
import { SubmitButton } from 'components/FormFields/v2';
import { FormFooterProps } from './FormFooter.types';

const FormFooter = ({
  onCancel,
  onSubmit,
  cancelButtonLabel = 'Back',
  submitButtonLabel = 'Submit',
  loading = false,
  isDisabled = false,
  disableSubmitButtonAnimation = false,
  hideSubmitButtonIcon = false,
  rootClass,
}: FormFooterProps) => (
  <div className={cx('tw-flex tw-mt-4', onCancel ? 'tw-justify-between' : 'tw-justify-end', rootClass)}>
    {onCancel && (
      <Button isDisabled={loading} secondary type="button" onClick={onCancel}>
        {cancelButtonLabel}
      </Button>
    )}
    <SubmitButton
      isDisabled={isDisabled}
      isFetching={loading}
      onClick={onSubmit}
      className="tw-flex tw-items-center"
      iconAnimation={!loading && !isDisabled && !disableSubmitButtonAnimation && IconAnimation.rollOut}
    >
      <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
        <p className="tw-text-neutral-light">{loading ? 'Submitting' : submitButtonLabel}</p>
        {!loading && !hideSubmitButtonIcon && <ArrowRight />}
      </div>
    </SubmitButton>
  </div>
);

export default FormFooter;
