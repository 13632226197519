import Dinero from 'dinero.js';

import { TRANSACTION_PRODUCT_TYPES, TRANSACTION_TYPES } from 'constants/index';
import { NON_NUMERIC_REGEX } from 'constants/regex';

Dinero.globalLocale = 'en-CA';

export const currencyToNumber = (currency) => {
  return Number(String(currency).split('$').join('').split(',').join(''));
};

export const formatMoney = (money, abs = false, showDefault = false) => {
  let result = Dinero({ amount: 0, currency: 'CAD' }).toFormat();

  if (money && money.currency) {
    result = Dinero({
      amount: parseInt(abs ? Math.abs(money.amount) : money.amount) || 0,
      currency: money.currency,
    }).toFormat();
  }
  return `${showDefault ? 'CA' : ''}${result}`;
};

export const numberToCurrency = (number) => {
  const str = String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return number > -1 ? '$' + str : '- $' + str.substr(1);
};

export const sortAmounts = (a, b, column) => {
  return a.values[column].amount > b.values[column].amount ? -1 : 1;
};

// Convert Centes amount to $$
export const toUnit = (amount) => {
  return Dinero({ amount: amount || 0 }).toUnit();
};

export const toCents = (amount) => parseInt(amount * 100);

// Dinero library is very limited with custom formatter,
// Creating our own formatter that uses browsers default currency formatter
export const formatMoneyV2 = (money, abs = false) => {
  let { amount, currency } = money || { amount: 0, currency: 'CAD' };
  if (isNaN(amount) && currency) amount = 0;
  if (!isNaN(amount) && !currency) currency = 'CAD';

  const options = { style: 'currency', currency, currencyDisplay: 'narrowSymbol' };

  let formattedAmount;
  if (currency === 'JPY') {
    formattedAmount = parseInt(abs ? Math.abs(amount) : amount);
  } else {
    formattedAmount = parseInt(abs ? Math.abs(amount) : amount) / 100;
  }

  return new Intl.NumberFormat(currency === 'USD' ? 'en-US' : 'en-CA', options).format(formattedAmount);
};

export const centsFromMoneyString = (moneyString) => {
  if (!moneyString) return 0;

  const onlyDigits = moneyString.replace(NON_NUMERIC_REGEX, '');

  return parseInt(onlyDigits);
};

export const formatHomeTransactionAmount = (value, transaction) => {
  const amount =
    transaction.transferMethod === 'Card' ||
    (transaction.transferMethod === 'Domestic Transfer' &&
      !(
        transaction.type.includes('PAYMENT') ||
        transaction.type === 'CREDIT' ||
        transaction.type.includes('CANCELLATION') ||
        transaction.type.includes('CORRECTION') ||
        transaction.type.includes('DEPOSIT')
      ))
      ? -Math.abs(value.amount)
      : Math.abs(value.amount);
  return formatMoneyWithoutCurrencyLabel({ amount: amount, currency: value.currency });
};

export const formatTransactionTableAmount = ({ transaction, amount, productType }) => {
  const value = amount || transaction.amount;
  let formatted;

  if (productType === TRANSACTION_PRODUCT_TYPES.wallet) {
    const isComingFromWallet = transaction.from.includes(`${transaction.amount.currency} Account`);
    formatted = isComingFromWallet ? -Math.abs(value.amount) : Math.abs(value.amount);
  } else {
    const isGoingToCard = transaction.to.includes('Loop Card'); // false
    const isGoingToLineOfCredit = transaction.to.includes('Line Of Credit'); // false
    const creditTransactionType =
      transaction.type === TRANSACTION_TYPES.MONTHLY_PAYMENT ||
      transaction.type === TRANSACTION_TYPES.BALANCE_REPAYMENT ||
      transaction.type === TRANSACTION_TYPES.CREDIT ||
      transaction.type.includes(TRANSACTION_TYPES.CANCELLATION) ||
      transaction.type.includes(TRANSACTION_TYPES.CORRECTION) ||
      transaction.type.includes(TRANSACTION_TYPES.DEPOSIT);
    formatted =
      isGoingToCard || isGoingToLineOfCredit || creditTransactionType
        ? Math.abs(value.amount)
        : -Math.abs(value.amount);
  }

  return formatMoneyWithoutCurrencyLabel({ amount: formatted, currency: value.currency });
};

export const formatMoneyWithoutCurrencyLabel = (value) => {
  return formatMoney(value).replace(/[A-Z]/g, '');
};

export const cardsAmountValue = (amount) => {
  return amount > 0 ? -Math.abs(amount) : Math.abs(amount);
};

export const validateMoneyInput = (value, inputValueSetter) => {
  inputValueSetter(value);
  return centsFromMoneyString(value) > 0 || 'Please enter a valid amount';
};
