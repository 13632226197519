import { isEqual, pick } from 'lodash';

export const isAddressSame = (addressA, addressB) => {
  const keys = ['street', 'unitNumber', 'city', 'countrySubdivision', 'postalCode', 'country'];
  return isEqual(pick(addressA, keys), pick(addressB, keys));
};

export const composeFullAddress = ({ unitNumber, street, city, countrySubdivision, country, postalCode }) => {
  return `${unitNumber ? `${unitNumber}-` : ''}${street}, ${city}, ${countrySubdivision} ${postalCode}, ${country}`;
};
