import { gql } from '@apollo/client';

export const GET_PAYORS = gql`
  query Payors {
    payors {
      id
      name
      email
      referenceId
    }
  }
`;

export const ADD_PAYOR = gql`
  mutation AddPayor($name: String!, $email: String!, $referenceId: String) {
    addPayor(name: $name, email: $email, referenceId: $referenceId) {
      id
    }
  }
`;

export const DELETE_PAYOR = gql`
  mutation DeletePayor($payorId: ID!) {
    deletePayor(payorId: $payorId)
  }
`;

export const UPDATE_PAYOR = gql`
  mutation UpdatePayor($payorId: ID!, $referenceId: String) {
    updatePayor(payorId: $payorId, referenceId: $referenceId) {
      id
    }
  }
`;

export const GET_PAYMENT_REQUESTS = gql`
  query PaymentRequests {
    paymentRequests {
      id
      payor {
        name
      }
      account {
        name
      }
      amount {
        amount
        currency
      }
      createdAt
      dueDate
      status
      invoiceNumber
      fee {
        amount
        currency
      }
      schedule {
        frequency
        startDate
        endDate
        numberOfOccurrences
      }
    }
  }
`;

export const GET_PAYMENT_REQUEST_FEE = gql`
  query PaymentRequestFee($amount: MoneyInput!) {
    paymentRequestFee(amount: $amount) {
      amount
      currency
    }
  }
`;

export const ADD_PAYMENT_REQUEST = gql`
  mutation AddPaymentRequest(
    $payorId: ID!
    $accountId: ID!
    $amount: MoneyInput!
    $dueDate: ISO8601Date!
    $invoiceNumber: String
    $frequency: String
    $endDate: ISO8601Date
    $numberOfOccurrences: Int
  ) {
    addPaymentRequest(
      payorId: $payorId
      accountId: $accountId
      amount: $amount
      dueDate: $dueDate
      invoiceNumber: $invoiceNumber
      frequency: $frequency
      endDate: $endDate
      numberOfOccurrences: $numberOfOccurrences
    ) {
      id
      payor {
        id
      }
      account {
        id
      }
      amount {
        amount
        currency
      }
      dueDate
      status
      invoiceNumber
      fee {
        amount
        currency
      }
      schedule {
        frequency
        startDate
        endDate
        numberOfOccurrences
      }
    }
  }
`;

export const CANCEL_PAYMENT_REQUEST = gql`
  mutation CancelPaymentRequest($paymentRequestId: ID!) {
    cancelPaymentRequest(paymentRequestId: $paymentRequestId) {
      id
    }
  }
`;

export const GET_PAYOR_PAYMENT_REQUEST = gql`
  query PayorPaymentRequest($signedId: String!) {
    payorPaymentRequest(signedId: $signedId) {
      id
      payor {
        id
        email
      }
      vendor {
        name
      }
      amount {
        amount
        currency
      }
      dueDate
      status
      invoiceNumber
      schedule {
        frequency
        startDate
        endDate
        numberOfOccurrences
      }
    }
  }
`;

export const GET_PAYOR_PAYMENT_REQUESTS = gql`
  query PayorPaymentRequests {
    payorPaymentRequests {
      id
      amount {
        amount
        currency
      }
      createdAt
      dueDate
      status
      invoiceNumber
      schedule {
        frequency
        startDate
        endDate
        numberOfOccurrences
      }
      vendor {
        name
        email
        # TODO: exclude the address data from the query
        address {
          street
          unitNumber
          city
          countrySubdivision
          country
          postalCode
        }
      }
    }
  }
`;

export const GET_PAYOR_BANK_ACCOUNTS = gql`
  query PayorBankAccounts {
    payorBankAccounts {
      connectionsReady
      accounts {
        id
        bankName
        accountNumber
        routingNumber
        institutionNumber
        transitNumber
        maskedAccountNumber
        currency
      }
    }
  }
`;

export const ADD_PAYOR_BANK_ACCOUNT = gql`
  mutation AddPayorBankAccount(
    $bankName: String!
    $accountNumber: String!
    $routingNumber: String
    $institutionNumber: String
    $transitNumber: String
    $accountType: String!
    $currency: String!
    $country: String
  ) {
    addPayorBankAccount(
      bankName: $bankName
      accountNumber: $accountNumber
      routingNumber: $routingNumber
      institutionNumber: $institutionNumber
      transitNumber: $transitNumber
      accountType: $accountType
      currency: $currency
      country: $country
    ) {
      id
    }
  }
`;

export const PROCESS_PAYMENT_REQUEST = gql`
  mutation ProcessPaymentRequest($paymentRequestId: ID!, $payorBankAccountId: ID!, $dueDate: ISO8601Date!) {
    processPaymentRequest(
      paymentRequestId: $paymentRequestId
      payorBankAccountId: $payorBankAccountId
      dueDate: $dueDate
    ) {
      id
      status
    }
  }
`;

export const GET_PAYOR_PAYMENT_REQUEST_VENDOR = gql`
  query PayorPaymentRequestVendorAddress($signedId: String!) {
    payorPaymentRequest(signedId: $signedId) {
      vendor {
        name
        email
        address {
          street
          unitNumber
          city
          countrySubdivision
          country
          postalCode
        }
      }
    }
  }
`;

export const PAYOR_SIGN_PRE_DEPOSIT_AUTHORIZATION = gql`
  mutation PayorSignPreDepositAuthorization($firstName: String!, $lastName: String!, $bankAccountId: String!) {
    payorSignPreDepositAuthorization(firstName: $firstName, lastName: $lastName, bankAccountId: $bankAccountId)
  }
`;
