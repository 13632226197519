import React, { useContext } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import { ApolloProvider } from '@apollo/client';

import FlinksConnection from 'callbacks/FlinksConnection';
import PayorFlinksConnection from 'callbacks/PayorFlinksConnection';
import Impersonate from 'callbacks/Impersonate';

import { ToastContainer } from 'react-toastify';

import AccountSummaryContainer from 'containers/AccountSummary';
import CallbackContainer from 'containers/IntegrationCallback';
import AdditionalDetailsContainer from 'containers/onboarding/AdditionalDetails';
import ContactDetailsContainer from 'containers/onboarding/ContactDetails';
import BusinessDetailsContainer from 'containers/BusinessDetails';
import BusinessAddressContainer from 'containers/BusinessAddress';
import OwnersProfileContainer from 'containers/OwnersProfile';
import BusinessContactsContainer from 'containers/BusinessContacts';
import ProfileSetupContainer from 'containers/ProfileSetup';
import GuarantorConsentContainer from 'containers/GuarantorConsent';
import ResetPasswordContainer from 'containers/ResetPassword';
import SelectAccountTypeContainer from 'containers/SelectAccountType';
import DocumentsSettingsContainer from 'containers/settings/Documents';
import AgreementsContainer from 'containers/settings/Agreements';
import PaymentsContainer from 'containers/settings/Payments';
import VerifyBankAccountEmailContainer from 'containers/settings/VerifyBankAccountEmail';
import CardsContainer from 'containers/creditCards/Cards';
import CardsContainerV2 from 'containers/creditCards/v2/Cards';
import { CardBalances, CardDetailsPage } from 'components/creditCards/components';
import CardsSplash from 'components/creditCards/mobile/CardsSplash';
import WaitingRoomContainer from 'containers/onboarding/WaitingRoom';
import IdentityVerification from 'components/onboarding/IdentityVerification';
import CardRepayment from 'components/payments/CardRepayment';
import CardAutopay from 'components/payments/CardAutopay';
import ActivateCardContainer from 'containers/creditCards/ActivateCard';
import CardsList from 'components/creditCards/components/CardsList';

import LineOfCreditContainer from 'containers/LineOfCredit';
import TeamRegistrationContainer from 'containers/TeamRegistration';

import PayorOnboarding from 'components/onboarding/PayorOnboarding';

import {
  NonKYCOnboardingHome,
  NonKYCOnboardingCards,
  NonKYCOnboardingAccounts,
  NonKYCOnboardingTransactions,
  NonKYCOnboardingRewards,
} from 'components/OnboardingDashboard';
import {
  NotificationSettings as NonKYCNotificationSettings,
  TeamSettings as NonKYCTeamSettings,
} from 'components/OnboardingDashboard/components/Settings/components';

import Home from 'components/home/Home';

import { Accounts } from 'components/Accounts';
import { Complete as SolidOwnershipDisclosureComplete } from 'components/wallets/AddUSBankAccount/components/OwnershipDisclosure/components';

import PersonalSettings from 'components/settings/PersonalSettings';
import AddBankAccountVX from 'components/settings/AddBankAccount';
import BankAccountSettings from 'components/settings/BankAccounts';
import Team from 'components/settings/Team';
import BusinessNotificationSettings from 'components/settings/businessNotifications';
import ExpenseManagement from 'components/settings/ExpenseManagement';
import BusinessVerification from 'components/onboarding/BusinessVerification';
import AccountingIntegrationServiceSettings from 'pages/settings/accountingIntegrationService';
import IntegrationsSettings from 'components/settings/Integrations';

import { Transactions, CardTransactionsPushLanding, WalletTransactionsPushLanding } from 'components/Transactions';
import { Transaction } from 'components/Transactions/components';
import { TransactionsSplash } from 'components/Transactions/components/mobile';

import Approvals from 'components/Approvals';

import SignIn from 'components/auth/SignIn';
import SignOut from 'SignOut';
import SignUp from 'components/auth/SignUp';
// TODO replace user SignUp https://getloop.atlassian.net/browse/LBP-2637
import SignUpV2 from 'components/auth/v2/components/SignUp';
import SignUpWithCode from 'components/auth/SignUpWithCode';
import NotFoundFallback from 'components/NotFoundFallback';
import EmailVerification from 'components/auth/EmailVerification';

import FXTable from 'components/fx';

import ForgotPassword from 'components/auth/ForgotPassword';
import Payments from 'components/payments';
import MoveMoney from 'components/payments/MoveMoney';
import AddMoney from 'components/payments/AddMoney';
import PaymentsSplash from 'components/payments/mobile/Splash';
import PayLocBalance from 'components/payments/PayLocBalance';
import Payees from 'containers/Payees';
import Invoices from 'components/Invoices';
import Subscriptions from 'components/Subscriptions/Subscriptions';
import Maintenance from 'components/Maintenance';
import CreditReportConsentTerms from 'components/CreditReportConsentTerms';
import InvitationPersonalInfo from 'components/team/InvitationPersonalInfo';
import TeamWaitingRoom from 'components/team/WaitingRoom';
import InactiveUser from 'components/team/InactiveUser';

import SupplierBankDetails from 'components/SupplierBankDetails';

import GuarantorVerification from 'components/consentForms/GuarantorVerification';
import GuarantorComplete from 'components/consentForms/GuarantorComplete';

// Old Credit Application Pages
import CreditApplicationContainer from 'containers/CreditApplication';
import CRAConnection from 'components/onboarding/creditApplication/CRAConnection';
import CompletedCreditApplication from 'components/onboarding/creditApplication/CompletedCreditApplication';

import 'amplitude';
import 'sentry';
import 'aws';
import 'fbPixel';

import { getToken } from 'utility/auth';
import history from './history';
import apolloClient from './apis/apollo';

import { MFAContextProvider } from 'context/MFA';
import { AuthContextProvider } from 'context/Auth';
import { ToasterContextProvider } from 'context/Toaster';
import { NotificationAlertContextProvider } from 'context/NotificationAlert';
import { SettingsContextProvider } from 'context/Settings';
import { TabIndexContextProvider } from 'context/TabIndex';
import { TeamContextProvider } from 'components/settings/Team/TeamContext';
import { ThemeContext } from 'context/ThemeContext';

import {
  RoutePrivate,
  RouteDashboard,
  RoutePublic,
  RedirectToCore,
  RouteTeamInvitation,
  RouteScoped,
  RoutePayorPortal,
  RouteOnboardingDashboard,
  RouteOnboardingApplication,
  RouteSubscription,
} from 'components/Navigation';

import { SCOPE } from 'constants/index';

import { AccountingIntegrations } from 'pages/settings/accountingIntegrations';
import { RewardsAndOffer } from 'pages/rewards';
import { UnmatchedReceipts } from 'components/receipts/unmatched';
import { SubscriptionType } from 'components/Subscriptions/Subscriptions.types';

import { PaymentRequests } from 'components/PayorPortal';
import { PersonalSettings as PayorPersonalSettings } from 'components/PayorPortal/Settings';

import { RegistrationType } from 'components/auth/v2/components/SignUp/SignUp.types';

import DocuSign from 'components/DocuSign';

import { SessionExpirationModal } from 'components/home/components';

// Axios middlewares
axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) history.push('/signout');
    return Promise.reject(error);
  }
);
axios.interceptors.request.use(async (config) => {
  config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  const token = await getToken();

  if (token && !config.headers.Authorization) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

const Routes = () => {
  const { themeMode } = useContext(ThemeContext);

  return (
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <MFAContextProvider>
          <ToasterContextProvider>
            <NotificationAlertContextProvider>
              <SettingsContextProvider>
                <TabIndexContextProvider>
                  <TeamContextProvider>
                    <ToastContainer theme={themeMode} />
                    <Router history={history}>
                      <Switch>
                        <RoutePublic exact path="/signin" component={SignIn} />
                        <RoutePublic exact path="/register" component={SignUp} />
                        <Route exact path="/email_verification" component={EmailVerification} />
                        {/* TODO replace user SignUp https://getloop.atlassian.net/browse/LBP-2637 */}
                        {/* <RoutePublic exact path="/register">
                          <SignUpV2 type={RegistrationType.USER} />
                        </RoutePublic> */}
                        <RoutePublic exact path="/(en)?/apply" component={SignUpWithCode} />
                        <RoutePublic exact path="/register/invite" component={ProfileSetupContainer} />
                        <RoutePublic exact path="/team/signup" component={TeamRegistrationContainer} />
                        <RoutePublic exact path="/impersonate" component={Impersonate} />
                        <RouteDashboard exact path="/(en)?/" component={AccountSummaryContainer} />
                        <RouteDashboard exact path="/(en)?/dashboard" component={AccountSummaryContainer} />
                        <RouteDashboard
                          exact
                          path="/(en)?/dashboard/account-summary"
                          component={AccountSummaryContainer}
                        />
                        <RoutePrivate exact path="/fx-compare" component={FXTable} />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/business-details"
                          component={BusinessDetailsContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/business-address"
                          component={BusinessAddressContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/owners-profile"
                          component={OwnersProfileContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/waiting-room"
                          component={WaitingRoomContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/business-verification"
                          component={BusinessVerification}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/identity-verification"
                          component={IdentityVerification}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/additional-details"
                          component={AdditionalDetailsContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/business-contacts"
                          component={BusinessContactsContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/eligibility/start"
                          component={SelectAccountTypeContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/contact-details"
                          component={ContactDetailsContainer}
                        />
                        <Redirect from="/onboarding/pre-approval/start" to="/onboarding/eligibility/start" />
                        {/* Onboarding Dashboard */}
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/home"
                          component={NonKYCOnboardingHome}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/cards"
                          component={NonKYCOnboardingCards}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/accounts"
                          component={NonKYCOnboardingAccounts}
                        />
                        <RouteOnboardingDashboard exact path="/onboarding/dashboard/payments/payees">
                          <Payees isOnboardingPreview />
                        </RouteOnboardingDashboard>
                        <RouteOnboardingDashboard exact path="/onboarding/dashboard/invoices">
                          <Invoices isOnboardingPreview />
                        </RouteOnboardingDashboard>
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/transactions"
                          component={NonKYCOnboardingTransactions}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/rewards"
                          component={NonKYCOnboardingRewards}
                        />
                        {/* Onboarding Dashboard Settings */}
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/personal"
                          component={PersonalSettings}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/notifications"
                          component={NonKYCNotificationSettings}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/integrations"
                          component={IntegrationsSettings}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/accounting-integrations"
                          component={AccountingIntegrations}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/bank-accounts-settings"
                          component={BankAccountSettings}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/bank-accounts-settings/add"
                          component={AddBankAccountVX}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/documents"
                          component={DocumentsSettingsContainer}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/team"
                          component={NonKYCTeamSettings}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/agreements"
                          component={AgreementsContainer}
                        />
                        <RouteOnboardingDashboard
                          exact
                          path="/onboarding/dashboard/settings/payments"
                          component={PaymentsContainer}
                        />
                        <RoutePrivate exact path="/bank-connections/callback" component={FlinksConnection} />
                        <RoutePayorPortal
                          exact
                          path="/payor/bank-connections/callback"
                          component={PayorFlinksConnection}
                        />
                        <RoutePrivate
                          exact
                          path="/integration/callback/:from?/:service"
                          component={CallbackContainer}
                        />
                        <RouteDashboard exact path="/dashboard/transactions" component={Transactions} />
                        <RouteDashboard
                          exact
                          path="/dashboard/transactions/cardTransactionsPush"
                          component={CardTransactionsPushLanding}
                        />
                        <RouteDashboard
                          exact
                          path="/dashboard/transactions/accountsTransactionsPush"
                          component={WalletTransactionsPushLanding}
                        />
                        <RouteDashboard exact path="/dashboard/transactions/splash" component={TransactionsSplash} />
                        <RouteDashboard exact path="/dashboard/transactions/:id" component={Transaction} />
                        <RouteDashboard exact path="/dashboard/receipts/unmatched" component={UnmatchedReceipts} />
                        <RouteDashboard exact path="/dashboard/approvals" component={Approvals} />
                        <RoutePrivate exact path="/bank-accounts/verify" component={VerifyBankAccountEmailContainer} />
                        <RouteDashboard exact path="/dashboard/settings/mfa" component={PersonalSettings} />
                        <RouteDashboard exact path="/dashboard/settings/personal" component={PersonalSettings} />
                        <RouteDashboard
                          exact
                          path="/dashboard/settings/integrations"
                          component={IntegrationsSettings}
                        />
                        <RouteDashboard
                          exact
                          path="/dashboard/settings/accounting-integrations"
                          component={AccountingIntegrations}
                        />
                        <RouteDashboard
                          exact
                          path="/dashboard/settings/bank-accounts-settings"
                          component={BankAccountSettings}
                        />
                        <RouteDashboard
                          exact
                          path="/dashboard/settings/bank-accounts-settings/add"
                          component={AddBankAccountVX}
                        />
                        <RouteSubscription
                          exact
                          path="/dashboard/settings/expense-management"
                          component={ExpenseManagement}
                        />
                        <RouteDashboard
                          exact
                          path="/dashboard/settings/documents"
                          component={DocumentsSettingsContainer}
                        />
                        <RouteDashboard exact path="/dashboard/settings/team" component={Team} />
                        <RouteDashboard
                          exact
                          path="/dashboard/settings/notifications"
                          component={BusinessNotificationSettings}
                        />
                        <Redirect
                          from="/dashboard/settings/card-notifications"
                          to="/dashboard/settings/notifications"
                        />
                        <RouteDashboard exact path="/dashboard/settings/agreements" component={AgreementsContainer} />
                        <RouteDashboard exact path="/dashboard/settings/payments" component={PaymentsContainer} />
                        <RouteDashboard
                          exact
                          path="/dashboard/settings/accountingIntegration"
                          component={AccountingIntegrationServiceSettings}
                        />
                        <RouteScoped
                          exact
                          path="/dashboard/payments"
                          component={Payments}
                          scope={SCOPE.managePayments}
                        />
                        <RouteDashboard exact path="/dashboard/payments/splash" component={PaymentsSplash} />
                        <RouteScoped
                          exact
                          path="/dashboard/payments/add-money"
                          component={AddMoney}
                          scope={SCOPE.managePayments}
                        />
                        <RouteScoped
                          exact
                          path="/dashboard/payments/move-money"
                          component={MoveMoney}
                          scope={SCOPE.managePayments}
                        />
                        <Redirect from="/dashboard/payments/send-money" to="/dashboard/payments/move-money" />
                        <RouteScoped
                          exact
                          path="/dashboard/payments/pay-balance"
                          component={CardRepayment}
                          scope={SCOPE.managePayments}
                        />
                        <RouteScoped
                          exact
                          path="/dashboard/payments/pay-balance/configure-autopay"
                          component={CardAutopay}
                          scope={SCOPE.managePayments}
                        />
                        <RouteScoped
                          exact
                          path="/dashboard/payments/pay-loc-balance"
                          component={PayLocBalance}
                          scope={SCOPE.managePayments}
                        />
                        <RouteDashboard exact path="/dashboard/payments/payees" component={Payees} />
                        <RouteDashboard exact path="/dashboard/invoices" component={Invoices} />
                        <RouteDashboard exact path="/dashboard/subscriptions">
                          <Subscriptions type={SubscriptionType.upgrade} />
                        </RouteDashboard>
                        <RouteDashboard exact path="/dashboard/subscriptions/active">
                          <Subscriptions type={SubscriptionType.active} />
                        </RouteDashboard>
                        <RouteDashboard exact path="/dashboard/subscriptions/plans">
                          <Subscriptions type={SubscriptionType.plans} />
                        </RouteDashboard>
                        <RouteDashboard exact path="/dashboard/rewards" component={RewardsAndOffer} />
                        <RouteDashboard exact path="/dashboard/home" component={Home} />

                        {/* TODO: remove this component and route after https://getloop.atlassian.net/browse/LBP-4380 */}
                        <RouteDashboard exact path="/dashboard/cards" component={CardsContainer} />

                        <RouteDashboard exact path="/dashboard/cards/splash" component={CardsSplash} />
                        <RouteDashboard exact path="/dashboard/cards/list" component={CardsContainerV2} />
                        <RouteDashboard exact path="/dashboard/cards/list-new" component={CardsList} />
                        <RouteDashboard exact path="/dashboard/cards/list/:id" component={CardDetailsPage} />
                        <RouteDashboard exact path="/dashboard/cards/balances" component={CardBalances} />
                        <RouteDashboard exact path="/dashboard/cards/activate" component={ActivateCardContainer} />
                        <RouteDashboard exact path="/activate" component={ActivateCardContainer} />

                        <Route exact path="/redirect/docusign" component={DocuSign} />

                        <Redirect from="/dashboard/wallets" to="/dashboard/accounts" />
                        <RouteDashboard exact path="/dashboard/accounts" component={Accounts} />
                        <Route exact path="/solid/ownership-disclosure" component={SolidOwnershipDisclosureComplete} />
                        <RouteDashboard exact path="/dashboard/line-of-credit" component={LineOfCreditContainer} />
                        {/* Payor registration */}
                        <RoutePublic exact path="/payor/register">
                          <SignUpV2 type={RegistrationType.PAYOR} />
                        </RoutePublic>
                        {/* Payor onboarding */}
                        <RoutePrivate exact path="/payor/onboarding/connect-bank">
                          <PayorOnboarding key="connect-bank" initialStep={1} />
                        </RoutePrivate>
                        <RoutePrivate exact path="/payor/onboarding/confirm-payment">
                          <PayorOnboarding key="confirm-payment" initialStep={2} />
                        </RoutePrivate>
                        {/* Payor portal */}
                        <RoutePayorPortal exact path="/payor/portal" component={PaymentRequests} />
                        {/* Payor Settings */}
                        <RoutePayorPortal
                          exact
                          path="/payor/portal/settings/personal"
                          component={PayorPersonalSettings}
                        />
                        <RoutePrivate path="/signout" component={SignOut} />
                        <RoutePrivate path="/credit-report-consent-terms" component={CreditReportConsentTerms} />
                        <Route exact path="/guarantee_consent" component={GuarantorConsentContainer} />
                        <Route
                          exact
                          path="/guarantee_consent/identity_verification"
                          component={GuarantorVerification}
                        />
                        <Route exact path="/guarantee_consent/complete" component={GuarantorComplete} />
                        <Route exact path="/:locale?/reset_password/new" component={RedirectToCore} />
                        <Route exact path="/forgot_password" component={ForgotPassword} />
                        <Route exact path="/reset_password" component={ResetPasswordContainer} />
                        <Route exact path="/supplier/bank-details" component={SupplierBankDetails} />
                        {/*  Old Credit Application pages */}
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/credit-application"
                          component={CreditApplicationContainer}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/credit-application/cra"
                          component={CRAConnection}
                        />
                        <RouteOnboardingApplication
                          exact
                          path="/onboarding/credit-application/completed"
                          component={CompletedCreditApplication}
                        />
                        <RoutePrivate exact path="/maintenance" component={Maintenance} />
                        <RouteTeamInvitation exact path="/team/personal-info" component={InvitationPersonalInfo} />
                        <RouteTeamInvitation exact path="/team/waiting-room" component={TeamWaitingRoom} />
                        <RoutePrivate exact path="/suspended" component={InactiveUser} />
                        <Route>
                          <NotFoundFallback />
                        </Route>
                      </Switch>
                    </Router>
                    <SessionExpirationModal />
                  </TeamContextProvider>
                </TabIndexContextProvider>
              </SettingsContextProvider>
            </NotificationAlertContextProvider>
          </ToasterContextProvider>
        </MFAContextProvider>
      </AuthContextProvider>
    </ApolloProvider>
  );
};

export default Routes;
