import { useMemo, useContext, useCallback } from 'react';
import { get } from 'lodash';
import { useApolloClient, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';

import { AuthContext } from 'context/Auth';
import { BANK_ACCOUNT_PAD_FRAGMENT, GET_BANK_ACCOUNTS_DETAILS } from 'graphql/bankAccounts';
import { BankAccount, PADAgreementCAVersion } from 'types/bankAccount';
import { User } from 'types/user';
import { PayPayeeContext } from 'components/payments/PayPayee/PayPayeeContext';
import { PADAgreementProps } from '../PADAgreement.types';

const usePADAgreement = ({ onNextStep }: Pick<PADAgreementProps, 'onNextStep'>) => {
  const { me } = useContext(AuthContext) as unknown as { me: User };
  const { paymentInfo } = useContext(PayPayeeContext);

  const client = useApolloClient();

  const {
    data: bankAccountsData,
    loading: isBankAccountsDataLoading,
    error: bankAccountsError,
  } = useQuery<{
    me: {
      account: {
        bankAccounts: Pick<
          BankAccount,
          'id' | 'currency' | 'country' | 'accountNumber' | 'institutionNumber' | 'transitNumber' | 'institutionName'
        >[];
      };
    };
  }>(GET_BANK_ACCOUNTS_DETAILS, {
    onError: (err) => {
      console.error(err);
      toast.error('Error preparing PAD agreement - please try again');
    },
  });

  const padAgreementVersion = get(me, 'account.hasCadCoreIntegration') ? PADAgreementCAVersion.core : undefined;
  const fromAccount = get(paymentInfo, 'fromAccount');

  const selectedBankAccount = useMemo(() => {
    const bankAccount =
      fromAccount?.__typename === 'BankAccount' && !(fromAccount as BankAccount).preDepositAuthorization
        ? (fromAccount as BankAccount)
        : undefined;

    if (!bankAccount) return;

    // fill bank account object with details
    const bankAccountDetails = get(bankAccountsData, 'me.account.bankAccounts')?.find(
      ({ id }) => id === bankAccount.id
    );

    return bankAccountDetails ? { ...bankAccount, ...bankAccountDetails } : bankAccount;
  }, [fromAccount, bankAccountsData]);

  const updateBankAccountPADCache = useCallback(() => {
    if (!selectedBankAccount) return;

    const data = {
      id: selectedBankAccount.id,
      preDepositAuthorization: true,
      __typename: selectedBankAccount.__typename,
    };

    const cacheId = client.cache.identify(selectedBankAccount);

    client.writeFragment({
      id: cacheId,
      fragment: BANK_ACCOUNT_PAD_FRAGMENT,
      data,
    });
  }, [client, selectedBankAccount]);

  const handleSubmit = useCallback(() => {
    updateBankAccountPADCache();
    onNextStep();
  }, [updateBankAccountPADCache, onNextStep]);

  return {
    selectedBankAccount,
    handleSubmit,
    padAgreementVersion,
    isLoading: isBankAccountsDataLoading,
    isError: !!bankAccountsError,
  };
};

export default usePADAgreement;
