import React from 'react';
import cx from 'classnames';
import { CardVerificationCodeElement } from '@basis-theory/basis-theory-react';

import { bsCvcElementStyle } from './constants';
import { BasisTheoryCvcInputFieldProps } from './BasisTheoryCvcInputField.types';
import styles from './BasisTheoryCvcInputField.module.scss';

const BasisTheoryCvcInputFields = ({ securityCodeRef, bt }: BasisTheoryCvcInputFieldProps) => (
  <div className={`tw-justify-center tw-flex tw-flex-row tw-gap-8 tw-font-bold`} aria-label="cvc">
    <div className={cx(styles.inputContainer, 'tw-mb-4 tw-w-20')}>
      <CardVerificationCodeElement
        id="cvc"
        bt={bt}
        style={bsCvcElementStyle}
        placeholder="***"
        ref={securityCodeRef}
        cardBrand="visa"
      />
    </div>
  </div>
);
export default BasisTheoryCvcInputFields;
