export enum AgreementDocumentType {
  'tax_residence_declaration_agreement' = 'tax_residence_declaration_agreement',
  'us_owner_tax_reporting_agreement' = 'us_owner_tax_reporting_agreement',
}

export enum AgreementStatus {
  'draft' = 'draft',
  'pending' = 'pending',
  'sent' = 'sent',
  'signed' = 'signed',
  'declined' = 'declined',
}

export type CoreExternalAccountAgreement = {
  id: string;
  documentType: AgreementDocumentType;
  name: string;
  status: AgreementStatus | null;
  envelopeId: string | null;
  url: string | null;
};
