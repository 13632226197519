import React from 'react';

import { TextField, Select } from 'components/FormFields/v2';
import { TAX_RESIDENCE_COUNTRIES } from '../../constants';
import { OwnerInformationSectionProps } from './OwnerInformationSection.types';
import { useOwnerInformationSection } from './hooks';

const OwnerInformationSection = ({ index, owner }: OwnerInformationSectionProps) => {
  const { register, isRequiredDocumentNumber } = useOwnerInformationSection({ index });

  const { id: globalId, name, firstName, lastName } = owner;
  const id = globalId.split('/').pop();

  return (
    <div className="tw-flex tw-gap-2">
      <input
        name={`members[${index}].salesforceContactId`}
        value={id}
        type="hidden"
        ref={register({ required: true })}
      />
      <div className="tw-w-1/3 tw-pt-2 tw-break-words">{name || `${firstName} ${lastName}`}</div>
      <Select
        rootClass="tw-w-1/3"
        name={`members[${index}].taxResidenceCountry`}
        options={TAX_RESIDENCE_COUNTRIES}
        placeholder="Select country"
        ref={register({ required: true })}
        required
      />
      <TextField
        rootClass="tw-w-1/3"
        name={`members[${index}].taxDocumentNumber`}
        placeholder={isRequiredDocumentNumber ? '123456789' : ''}
        maxLength="9"
        ref={register({
          required: isRequiredDocumentNumber,
          pattern: { value: /\d{9}/, message: 'Must contain exactly 9 digits' },
        })}
        disabled={!isRequiredDocumentNumber}
      />
    </div>
  );
};

export default OwnerInformationSection;
