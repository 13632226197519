import React, { useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { IoSearchOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';

import { SCOPE } from 'constants/index';
import { Loaders } from 'components/cards/Loader';
import Button from 'components/Button';
import PermissionChecker from 'components/PermissionChecker';
import usePayeesFilters from 'components/payments/usePayeesFilters';
import PayeesList from 'components/payments/desktop/payees/PayeesList';
import AddPayeeModal from 'components/payments/AddPayeeModal';

const Payees = ({ payees, creditCard, loading, wallets, lineOfCredit, bankAccounts }) => {
  const history = useHistory();
  const { onChangeSearch, visiblePayees } = usePayeesFilters(payees);
  const [showAddPayeeModal, setShowAddPayeeModal] = useState(false);
  const onShowAddPayeeModal = () => setShowAddPayeeModal(true);
  const onCloseAddPayeeModal = () => setShowAddPayeeModal(false);

  const onNavigateBack = () => history.goBack();

  const payeeCount = payees?.length || 0;

  return (
    <div className="tw-px-4">
      <div className="tw-flex tw-items-center tw-justify-between tw-px-0">
        <BiArrowBack onClick={onNavigateBack} size={24} className="tw-cursor-pointer" />
        <PermissionChecker scope={SCOPE.managePayments}>
          <Button primary onClick={onShowAddPayeeModal}>
            <IoIosAddCircleOutline className="tw-text-neutral-light tw-mr-2" />
            <p className="tw-text-neutral-light">Add</p>
          </Button>
        </PermissionChecker>
      </div>
      <h2 className="tw-mt-2 tw-mb-2">Manage & Pay Payees</h2>
      <div className="tw-mb-8">
        <PermissionChecker scope={SCOPE.managePayments}>
          <small className="tw-text-neutral-grey-1">Add new payees and send money to existing ones.</small>
        </PermissionChecker>
      </div>
      {!!payeeCount && (
        <div className="tw-relative">
          <div className="tw-absolute tw-py-2.5 tw-pl-3 tw-flex tw-items-center">
            <IoSearchOutline size={24} className="tw-mr-2" />
          </div>
          <input
            className="tw-block tw-px-3 tw-py-2 tw-pl-12 tw-rounded-md tw-w-full tw-bg-neutral-light tw-border-2 tw-border-neutral-grey-3 tw-placeholder-neutral-grey-2 focus:tw-placeholder-neutral-grey-2 focus:tw-border-primary-dark-green focus:tw-outline-none focus:tw-ring-0 focus:tw-shadow-input"
            onChange={onChangeSearch}
            placeholder="Search for payee"
          />
        </div>
      )}
      <div className="tw-my-6">
        {loading ? (
          <Loaders.Light />
        ) : (
          <PayeesList
            payees={visiblePayees}
            payeeCount={payeeCount}
            creditCard={creditCard}
            onAddPayee={onShowAddPayeeModal}
            wallets={wallets}
            bankAccounts={bankAccounts}
            lineOfCredit={lineOfCredit}
          />
        )}
      </div>
      <AddPayeeModal show={showAddPayeeModal} onClose={onCloseAddPayeeModal} />
    </div>
  );
};

export default Payees;
