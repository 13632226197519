import React, { useEffect } from 'react';

import Button from 'components/Button';
import DocuSignIframe from 'components/DocuSign/Content';
import { AgreementDocumentType } from 'types/coreExternalAccount';
import { USTaxDeclarationProps } from './USTaxDeclaration.types';
import { useTaxDeclaration } from '../CATaxDeclaration/hooks';

const USTaxDeclaration = ({ onNextStep, enableFullScreen, disableFullScreen }: USTaxDeclarationProps) => {
  const { signingUrl, isError, isLoading, isCompleted, skipUSTaxDeclaration, setSkipUSTaxDeclaration } =
    useTaxDeclaration({
      enableFullScreen,
      disableFullScreen,
      documentType: AgreementDocumentType.us_owner_tax_reporting_agreement,
    });

  const handleSkipTaxDeclaration = () => {
    setSkipUSTaxDeclaration(!skipUSTaxDeclaration);
  };

  useEffect(() => {
    if (isCompleted || skipUSTaxDeclaration) {
      onNextStep();
    }
  }, [isCompleted, skipUSTaxDeclaration, onNextStep]);

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-8 tw-p-8">
        <div className="tw-font-bold tw-mb-2">
          W-8BEN-E Certificate of Status of Beneficial Owner for United States Tax Withholding and Reporting
        </div>
        <div className="tw-border-t tw-border-neutral-grey-4 tw-px-8 tw-pt-4 tw-flex tw-justify-center">
          <div className="tw-text-sm">
            Please complete and sign the document below required for account opening. If any of the information appears
            incorrect, click on the Skip button below and a member of our team will reach out to you to finalize your
            account opening.
          </div>
        </div>

        <DocuSignIframe iframeUrl={signingUrl} isLoading={isLoading} isError={isError} isCompleted={isCompleted} />
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex lg:tw-justify-end">
        <Button className="tw-w-full lg:tw-w-max" onClick={handleSkipTaxDeclaration} primary>
          Skip
        </Button>
      </div>
    </div>
  );
};

export default USTaxDeclaration;
