import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import config from 'config';
import { AuthContext } from 'context/Auth';
import { formatDateTime, isWithinPastSixtyDays } from 'utility/date';

const DisputeTransactionRow = ({ transaction }) => {
  const { me } = useContext(AuthContext);
  const isCardTransaction = transaction.cardTransaction === true;

  if (!transaction || !me) return null;
  if (!isWithinPastSixtyDays(new Date(transaction.processingDate))) return null;
  if (!isCardTransaction) return null;

  const { id } = me || {};

  const formattedProcessingDate = formatDateTime(transaction.processingDate, 'dd/MM/yyyy');

  const disputeTransactionUrl = `${config.disputeTransactionBaseUrl}&account_number=${id}&transaction_id=${transaction.transactionId}&transaction_date=${formattedProcessingDate}&transaction_currency=${transaction.amount.currency}&transaction_amount=${transaction.amount.amount}&merchant_descriptor=${transaction.to}`;

  return (
    <div className="tw-border-t tw-border-neutral-grey-4 tw-my-8 lg:tw-my-0 lg:tw-p-0">
      <div className="tw-rounded-md tw-bg-neutral-light tw-p-2 tw-flex tw-items-center tw-justify-left lg:tw-pt-4 lg:tw-justify-end">
        <Link
          className="tw-text-sm tw-font-semibold tw-text-semantic-error hover:tw-text-semantic-error"
          to={{ pathname: disputeTransactionUrl }}
          target="_blank"
        >
          Dispute
        </Link>
      </div>
    </div>
  );
};

export default DisputeTransactionRow;
