import React from 'react';

import Button from 'components/Button';
import config from 'config';

const PatriotAct = ({ onPreviousStep, onNextStep }: { onPreviousStep: () => void; onNextStep: () => void }) => {
  return (
    <div className="tw-max-h-screen tw-overflow-y-scroll">
      <div className="tw-px-8">
        <h6 className="tw-my-4 tw-font-bold">USA PATRIOT Act Notice</h6>
        <p className="tw-my-4">
          <small className="tw-text-neutral-grey-2">
            To help the government fight the funding of terrorism and money laundering activities, Section 326 of the
            USA PATRIOT Act requires all financial institutions to obtain, verify, and record information identifying
            each person who opens an account.
          </small>
        </p>
        <p className="tw-my-4">
          <small className="tw-text-neutral-grey-2">
            What this means to you: when you open an account or change an existing account, we will ask for information
            that will allow us to identify you, such as full legal name, physical address, date of birth, and other
            personally identifiable information that will be used to verify your identity. We may also ask to see a
            valid government issued photo ID (such as a Passport) or other identifying documents as well.
          </small>
        </p>

        <p className="tw-my-4">
          <small className="tw-text-neutral-grey-2">
            Please review our full{' '}
            <span className="tw-text-neutral-grey-1 tw-underline tw-font-semibold">
              <a href={config.patriotActUrl} target="_blank" rel="noopener noreferrer">
                USA PATRIOT Act Notice.
              </a>
            </span>
          </small>
        </p>
      </div>
      <div className="tw-border-t tw-border-neutral-grey-3 tw-px-8 tw-pt-4 tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between">
        <Button
          onClick={onPreviousStep}
          className="tw-bg-neutral-light tw-border tw-border-neutral-grey-3 tw-py-2 tw-px-4 tw-rounded-md tw-text-center tw-mb-4 lg:tw-mb-0 tw-w-full lg:tw-w-32"
        >
          Back
        </Button>
        <Button primary className="tw-w-full lg:tw-w-32" onClick={onNextStep}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default PatriotAct;
